<!--<div class="legend-activity-filter" *ngIf="!!appliedFilter">
    <div>
        <span class="activity-name">{{ appliedFilter.label }}</span>&nbsp;<span>activity filter applied</span>
    </div>
    <button mat-icon-button
            class="configure-button"
            (click)="clearFilter()"
    >
        <mat-icon>close</mat-icon>
    </button>
</div>-->

<div class="legend">
    <div class="legend-header">
        <div>
            GPS Track
        </div>
        <button mat-icon-button
                class="configure-button"
                (click)="openConfiguration()"
                matTooltip="Configure GPS Tracks"
        >
            <mat-icon>settings</mat-icon>
        </button>
    </div>

    <div class="legend-subtitle">
        <span>Current GPS tracks of active shifts</span>
    </div>

    <div class="legend-line">
        <div class="legend-item__wrapper">
            <app-line-dash-array-view
                [dashArray]="undefined"
                [color]="MapStyles.LIVE_COLOR"
            ></app-line-dash-array-view>

            <div class="legend-item__name">
                Active
            </div>
        </div>
        <div class="legend-item__wrapper">
            <app-line-dash-array-view
                    [dashArray]="undefined"
                    [color]="MapStyles.INACTIVE_COLOR"
            ></app-line-dash-array-view>

            <div class="legend-item__name">
                Recent
            </div>
        </div>
    </div>
</div>
