<div class="select-box-wrapper"
     [ngStyle]="{ 'width.px': width }"
>
    <button class="select-box"
        [matMenuTriggerFor]="menu"
        (menuClosed)="onMenuClosed()"
        (menuOpened)="onMenuOpened()"
         [ngClass]="{ 'active': isMenuOpened,
                      'not-empty': !useCircleIcon || !selectedItem.flagEmpty
         }"
    >
        <div class="select-box-content">
            <div class="select-box-icon"
                 *ngIf="!useCircleIcon"
                 [ngClass]="{ mirrored: selectedItem.iconMirrored }"
            >
                <mat-icon>{{ selectedItem.icon }}</mat-icon>
            </div>
            <div class="select-box-circle"
                 *ngIf="useCircleIcon"
                 [ngClass]="{ 'grey': selectedItem.flagEmpty,
                              'green': !selectedItem.flagEmpty
                  }"
            >
            </div>
            <div class="select-box-title"
                 [ngClass]="{ 'grey': useCircleIcon && selectedItem.flagEmpty }"
            >
                <span *ngIf="!selectedItem.flagEmpty || !selectedItem.emptyLabel">{{ selectedItem.label }}</span>
                <span *ngIf="selectedItem.flagEmpty">{{ selectedItem.emptyLabel }}</span>
            </div>
        </div>

        <div class="select-box-dropdown-icon"
             [ngClass]="{ 'grey': useCircleIcon && selectedItem.flagEmpty }"
             [attr.aria-label]="isMenuOpened? 'Close Menu' : 'Open Menu'"
            
        >
            <mat-icon *ngIf="!isMenuOpened">expand_more</mat-icon>
            <mat-icon *ngIf="isMenuOpened">expand_less</mat-icon>
        </div>
    </button>

    <mat-menu #menu="matMenu" class="select-box-menu">
        <ng-container *ngFor="let item of allItems">
            <button mat-menu-item
                    [ngStyle]="{ 'width.px': width }"
                    (click)="onSelect(item)"
                    class="select-box-menu-item"
            >
                <mat-icon [ngClass]="{ mirrored: selectedItem.iconMirrored }"
                >{{ item.icon }}</mat-icon>
                {{ item.label }}
            </button>
        </ng-container>
    </mat-menu>
</div>
