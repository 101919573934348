import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CartegraphMainSetupComponent} from './cartegraph-main-setup/cartegraph-main-setup.component';
import {CartegraphTasksComponent} from './cartegraph-tasks/cartegraph-tasks.component';
import {CartegraphTasksImportComponent} from './cartegraph-tasks-import/cartegraph-tasks-import.component';
import {CartegraphNotificationsComponent} from './cartegraph-notifications/cartegraph-settings-notifications.component';
import {CartegraphSettingsFeatures} from './cartegraph-features/cartegraph-settings-features.component';
import {
  CartegraphSettingsImportFilters
} from './cartegraph-import-filters/cartegraph-settings-import-filters.component';

const routes: Routes = [
  {
    path: '',
    component: CartegraphTasksComponent,
  },
  {
    path: 'settings',
    component: CartegraphMainSetupComponent,
  },
  {
    path: 'settings/notifications',
    component: CartegraphNotificationsComponent,
  },
  {
    path: 'settings/features',
    component: CartegraphSettingsFeatures,
  },
  {
    path: 'settings/import',
    component: CartegraphSettingsImportFilters,
  },
  {
    path: 'imports',
    component: CartegraphTasksImportComponent,
  },
  {
    path: 'submissions',
    component: CartegraphTasksComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule],
})
export class CartegraphManagementRoutingModule {
}
