<div class="warning-dialog-header">
    <div class="header__wrapper">
        <div class="warning-title">
            Warning Information<span *ngIf="!!alert"> - {{ alert.properties.event }}</span>
        </div>
        <div class="actions">
            <button mat-icon-button (click)="showNext()" *ngIf="!!data && data.length > 1">
                <mat-icon>east</mat-icon>
            </button>
            <button mat-icon-button mat-dialog-close cdkfocusinitial
                aria-label="close">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

</div>

<div mat-dialog-content>
    <ng-container *ngIf="!!alert">
        <h2>{{ alert.properties.headline}}</h2>
        <pre>{{ alert.properties.description}}</pre>
    </ng-container>

    <ng-container *ngIf="!!alert?.properties?.instruction">
        <h2>Instruction</h2>
        <pre>{{ alert.properties.instruction}}</pre>
    </ng-container>
</div>
