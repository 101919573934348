@if (isLoading) {
    <div *appCardContent>
        <div class="mb-spacing">
            <div class="indent">
                <mat-spinner mode="indeterminate" diameter="30" class="mx-a my-15"></mat-spinner>
            </div>
        </div>
    </div>
} @else {
    @if (!!form) {
        <div class="settings-header-title">
            <p>Shift task defaults</p>
        </div>
        <form [formGroup]="form">

            <div class="field-row">
                <div class="field-row-title">
                    Priority
                </div>
                <div class="field-row-element">
                    <app-row-with-select [list]="datasetPriority" listId="label" listLabel="label"
                                         formControlName="priority"></app-row-with-select>
                    @if (form.controls['priority'].hasError('required')) {
                        <mat-error> Priority Type is required.</mat-error>
                    }
                </div>
            </div>

            <div class="field-row">
                <div class="field-row-title">
                    Status
                </div>
                <div class="field-row-element">
                    <app-row-with-select [list]="datasetStatus" listId="label" listLabel="label"
                                         formControlName="status"></app-row-with-select>
                    @if (form.controls['status'].hasError('required')) {
                        <mat-error> Priority Type is required.</mat-error>
                    }
                </div>
            </div>


            <div class="field-row">
                <div class="field-row-title">
                    Activity
                </div>
                <div class="field-row-element">
                    <app-row-with-select [list]="datasetActivity" listId="label" listLabel="label"
                                         formControlName="activity"></app-row-with-select>
                    @if (form.controls['priority'].hasError('required')) {
                        <mat-error> Priority Type is required.</mat-error>
                    }
                </div>
            </div>

            <div class="field-row">
                <div class="field-row-title">
                    Work Completed For
                </div>
                <div class="field-row-element">
                    <app-row-with-select [list]="datasetWorkCompletedFor" listId="label" listLabel="label"
                                         formControlName="workCompletedFor"></app-row-with-select>
                    @if (form.controls['priority'].hasError('required')) {
                        <mat-error> Priority Type is required.</mat-error>
                    }
                </div>
            </div>


            <div class="field-row">
                <div class="field-row-title">
                    Work Order
                </div>
                <div class="field-row-element">
                    <app-row-with-select [list]="datasetWorkOrder" listId="label" listLabel="label"
                                         formControlName="workOrder"></app-row-with-select>
                    <!--                {{ option.label }} {{ option.description }}-->
                </div>
            </div>

            <div class="field-row">
                <div class="field-row-title">
                    Max driver hours fallback
                </div>
                <div class="field-row-element">
                    <mat-form-field appearance="outline" color="accent" class="form-input"
                                    subscriptSizing="dynamic">
                        <input matInput
                               autocomplete="off"
                               type="number"
                               formControlName="maxHoursFallback"/>
                    </mat-form-field>
                </div>
            </div>

            <div class="field-row">
                <div class="field-row-title">
                    Default department
                </div>
                <div class="field-row-element">
                    <app-row-with-select [list]="datasetDepartment" listId="label" listLabel="label"
                                         formControlName="department"></app-row-with-select>
                </div>
            </div>
        </form>

    }
}
