import {Component, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router, RouterLink} from '@angular/router';
import {InspectionFormsService} from '../../../../../data/inspection-forms/inspection-forms.service';
import {DvirSystemForm} from '../model/DvirSystemForm';
import {SharedDataService} from '../../../../../shared/services/shared-data.service';
import {CardComponent} from '../../../../../shared/components/card/card.component';
import {
  CardContentDirective,
  CardContentRawDirective
} from '../../../../../shared/components/card/card-content.directive';
import {MatButton} from '@angular/material/button';
import {NgClass, NgForOf} from '@angular/common';
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import {MatIcon} from '@angular/material/icon';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {MatDivider} from '@angular/material/divider';
import {MatListItem, MatNavList} from '@angular/material/list';
import {DvirFormDto} from '../model/dto/DvirFormDto';
import { RootRoute, SettingsRoute } from 'src/app/shared/models/angular-routing';

@Component({
  standalone: true,
  selector: 'app-inspection-form-list',
  templateUrl: './inspection-form-list.component.html',
  imports: [
    CardComponent,
    CardContentDirective,
    MatButton,
    MatMenuTrigger,
    MatIcon,
    MatMenu,
    MatMenuItem,
    NgClass,
    NgForOf,
    MatProgressSpinner,
    MatDivider,
    MatListItem,
    MatNavList,
    CardContentRawDirective,
    RouterLink
  ],
  styleUrls: ['./inspection-form-list.component.scss', '../../../../settings/settings-common.scss']
})
export class InspectionFormListComponent implements OnInit {

  loading = false;
  loadError: string | undefined;

  inspectionForms: DvirFormDto[] = [];
  // system defined forms, loaded from server resources
  systemForms: DvirSystemForm[] = [];
  RootRoute = RootRoute;
  SettingsRoute = SettingsRoute;

  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly inspectionFormsService: InspectionFormsService,
    private readonly sharedDataService: SharedDataService
  ) {}

  ngOnInit() {
    this.loading = true;
    Promise.all([
      this.inspectionFormsService.getForms(),
      this.inspectionFormsService.getSystemForms()])
      .then(results => {
        const [forms, systemForms] = results;
        this.inspectionForms = forms;
        this.systemForms = systemForms;
      })
      .catch(error => {
        this.handleLoadError('Error while loading inspection forms', error);
        this.inspectionForms = null;
        this.systemForms = null;
        this.inspectionForms = [];
      }).finally(() => {
        this.loading = false;
    });
  }

  buttonClickedNew() {
    this.router.navigate([`/${RootRoute.SETTINGS}`, SettingsRoute.INSPECTION_FORMS, 'new'])
      .then();
  }

  buttonClickAddFromTemplate(form: DvirSystemForm) {
    this.sharedDataService.put('dvir-form-template', form.form);
    this.router.navigate([`/${RootRoute.SETTINGS}`, SettingsRoute.INSPECTION_FORMS, 'from-template'])
      .then();
  }

  private showSnackBar(message: string) {
    this.snackBar.open(message, null, { duration: 2000 });
  }

  private handleLoadError(msg: string, error: any) {
    const msgError = `${msg} :: ${error}`;

    console.error(msgError);
    this.showSnackBar(msg);

    this.loadError = msgError;
  }
}
