import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CartegraphManagementService} from '../../../../../data/cartegraph/cartegraph-management.service';
import {CartegraphSettingsDefaults} from './cartegraph-settings-defaults.component';
import {CartegraphTaskDefault} from '../../../../../shared/models/cartegraph.model';


@Component({
  selector: 'app-cartegraph-settings-defaults-observation',
  templateUrl: './cartegraph-settings-defaults-observation.component.html',
  styleUrls: ['../../../settings-fields.scss',
    '../../../settings-common.scss'],
})
export class CartegraphSettingsDefaultsObservation extends CartegraphSettingsDefaults implements OnInit {


  @Output()
  onDefaultsChange = new EventEmitter<CartegraphTaskDefault>(false);

  @Input()
  taskDefaults: CartegraphTaskDefault;

  isLoading = true;
  uiError: string;

  form: UntypedFormGroup;

  constructor(
    protected cartegraphManagementService: CartegraphManagementService,
    private fb: FormBuilder) {
    super(cartegraphManagementService)
  }

  ngOnInit(): void {
    super.ngOnInit()
  }

  canBeSaved() {
    return (this.form.valid ?? true);
  }

  protected initForm() {
    // init form
    this.form = this.fb.group({
      priority: this.fb.control(this.taskDefaults?.priority, [Validators.required]),
      status: this.fb.control(this.taskDefaults?.status, [Validators.required]),
      activity: this.fb.control(this.taskDefaults?.activity),
      workCompletedFor: this.fb.control(this.taskDefaults?.workCompletedFor)
    });

    // notify changes
    this.form.valueChanges.subscribe(() => {
      this.taskDefaults.priority = this.form.controls['priority'].value;
      this.taskDefaults.status = this.form.controls['status'].value;
      this.taskDefaults.activity = this.form.controls['activity'].value;
      this.taskDefaults.workCompletedFor = this.form.controls['workCompletedFor'].value;

      // only notify if object is valid and can be saved
      if (this.canBeSaved()) {
        this.onDefaultsChange.emit(this.taskDefaults);
      }
    });
  }


}
