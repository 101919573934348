<button
  (click)="toggleMenu()"
  mat-icon-button
  class="menu__toggle__btn"
  [matTooltip]="sidenavOpen ? 'Close Menu' : 'Open Menu'"
  matTooltipPosition="below"
>
  <mat-icon
    class="arrow__icon"
    [ngClass]="{ collapse: !sidenavOpen }"
    >menu</mat-icon
  >
</button>
