import {Component, Inject, OnInit} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {DOCUMENT} from '@angular/common';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import {environment} from '../environments/environment';
import {AuthenticatorService} from '@aws-amplify/ui-angular';
import { SettingsService } from './configuration/settings.service';
import { ConfigurationService } from './configuration/configuration.service';
import { Subscription } from 'rxjs';
import { SecurityService } from './security/security.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  public formFields = {
    signIn: {
      username: {
        placeholder: 'Enter Your Email Here',
        isRequired: true,
        label: '',
        labelHidden: true,
      },
      password: {
        placeholder: 'Enter Your Password Here',
        isRequired: true,
        label: '',
        labelHidden: true,
        autocomplete: 'current-password',
      },
    },
  };

  configurationSubscription: Subscription;

  constructor(
    private router: Router,
    private gtmService: GoogleTagManagerService,
    public authenticator: AuthenticatorService,
    private settingsService: SettingsService,
    private securityService: SecurityService,
    private configurationService: ConfigurationService,
    @Inject(DOCUMENT) private document: HTMLDocument,
  ) {
    if (environment.name !== 'local') {
      this.router.events.subscribe((val) => {
        // report page view events to Google Tag Manager
        if (val instanceof NavigationEnd) {
          const gtmTag = {
            event: 'page',
            pageName: val.url
          };
          this.gtmService.pushTag(gtmTag);
        }
      });
    }
  }

  ngOnInit(): void {
    if (environment.name !== 'prod') {
      this.updateAppIcon(environment.name);
    }

    this.authenticator.subscribe(authenticator => {
      if (authenticator.authStatus === 'authenticated') {
        this.loadGeneralConfiguration();
        this.securityService.getUserEmail().then(email => {
          window.document.title = `PlowOps - ${email}`;
        });
      } else if (authenticator.authStatus === 'unauthenticated') {
        this.configurationSubscription?.unsubscribe();
        this.configurationSubscription = undefined;
        window.document.title = 'PlowOps Dashboard';
      }
    });
  }

  private updateAppIcon(env: string) {
    const iconLink = this.document.getElementById('appIcon');
    iconLink.setAttribute('type', 'image/png');
    iconLink.setAttribute('href', `assets/plowops_logo_${env}_round.png`);
  }

  getLoginLogoUrl() {
    return `${environment.publicBaseUrl}plowops_logo_small.png`;
  }

  goTo(url: string) {
    window.open(url, '_blank');
  }

  private loadGeneralConfiguration() {
    this.configurationService.refreshConfiguration();
    this.configurationService.refreshTrackStyles();
    this.configurationSubscription = this.configurationService.sharedConfigurationModel.subscribe(model => {
      if (model) {
        this.settingsService.initSettings(model);
        this.configurationService.initUserTrackStyles();
      }
    });
  }
}
