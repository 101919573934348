import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UserModel} from '../../../../../shared/models/User';
import {UserManagementService} from '../../../../../data/users/user-management.service';
import {ConfirmDeletionDialogComponent} from '../../confirm-deletion-dialog/confirm-deletion-dialog.component';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-dialog-confirm-delete-user',
  templateUrl: 'dialog-confirm-delete-user.component.html',
  imports: [
    ConfirmDeletionDialogComponent
  ],
  standalone: true
})
export class DialogConfirmDeleteUserComponent {

  dialogBody: string = null;
  getDeletionCallback: (email: string) => Promise<object>;

  constructor(
    public dialogRef: MatDialogRef<DialogConfirmDeleteUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserModel,
    private userManagementService: UserManagementService,
  ) {
    this.populateDialogBody();

    this.getDeletionCallback = ((that: DialogConfirmDeleteUserComponent) => {
      return (email: string) => that.deletionCallback(email);
    })(this);
  }

  private deletionCallback(email: string): Promise<any> {
    return firstValueFrom(this.userManagementService.deleteUser(email));
  }

  private populateDialogBody() {
    this.dialogBody = `
              <p>
                Are you sure you want to delete user
                <b>${this.data.givenName} ${this.data.familyName} (${this.data.email})</b>?
              </p>
            `;
  }
}

