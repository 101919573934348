@if (isLoading || !configuration) {
    <mat-spinner mode="indeterminate" diameter="30" class="mx-a"></mat-spinner>
} @else {

    <form [formGroup]="updateForm">
        <div class="field-row">
            <div class="field-row-title">
                Processing type
            </div>
            <div class="field-row-element">
                <app-row-with-select [list]="processingTypes" listId="type" listLabel="note"
                                     formControlName="processingType"
                ></app-row-with-select>
                @if (updateForm.controls['processingType'].hasError('required')) {
                    <mat-error> Processing Type is required.</mat-error>
                }
            </div>
        </div>

        <div class="field-row">
            <div class="field-row-title">
                Base URL
            </div>
            <div class="field-row-element">
                <mat-form-field appearance="outline" color="accent" class="form-input" subscriptSizing="dynamic">
                    <input matInput
                           autocomplete="off"
                           type="text"
                           placeholder="Base URL"
                           formControlName="baseUrl"/>
                    @if (updateForm.controls['baseUrl'].hasError('required')) {
                        <mat-error> Base URL is required.</mat-error>
                    }
                </mat-form-field>
            </div>
        </div>

        <div class="field-row">
            <div class="field-row-title">
                Username
            </div>
            <div class="field-row-element">
                <mat-form-field appearance="outline" color="accent" class="form-input" subscriptSizing="dynamic">
                    <input matInput
                           autocomplete="off"
                           type="text"
                           placeholder="Username"
                           formControlName="username"/>
                    @if (updateForm.controls['username'].hasError('required')) {
                        <mat-error> Username is required.</mat-error>
                    }
                </mat-form-field>
            </div>
        </div>

        <div class="field-row">
            <div class="field-row-title">
                Password
            </div>
            <div class="field-row-element">
                <mat-form-field class="form-input" appearance="outline" color="accent">
                    <mat-label>Password</mat-label>
                    <input type="password" matInput
                           placeholder="Password"
                           autocomplete="password"
                           formControlName="password">
                    @if (updateForm.controls['password'].hasError('required')) {
                        <mat-error>
                            Password is required.
                        </mat-error>
                    }
                </mat-form-field>
            </div>
        </div>

        <div class="form-row">
            <button
                    mat-stroked-button
                    color="accent"
                    type="button"
                    (click)="verifyConnection()"
                    [disabled]="!(updateForm.controls['baseUrl'].valid && updateForm.controls['username'].valid)
                                || isTesting || isFetching">
                <mat-icon>published_with_changes</mat-icon>
                Verify connection
            </button>
            <div class="form-row">
                @if (isTesting) {
                    <mat-spinner diameter="20" color="accent" class="spinner"></mat-spinner>
                    Connecting...
                }
                @if (isFetching) {
                    <mat-spinner diameter="20" color="accent" class="spinner"
                                 style="display: inline-flex;"></mat-spinner>
                    Pre-fetching data...
                }
            </div>
        </div>

        @if (!isLoading) {
            <div class="form-row">
                @if (connectionTested?.data) {
                    <div class="success">Connection successful.</div>
                }
                @if (connectionTested?.error) {
                    <div class="fail">Connection failed. {{ connectionTested.error }}</div>
                }
                @if (fetched) {
                    <div class="fail">Data pre-fetched successfully.</div>
                }
            </div>
        }

    </form>

    @if (!isLoading && uiError && uiError.length > 0) {
        <div class="content mb-25">
            <span class="ui-error">{{ uiError }}</span>
        </div>
    }

}