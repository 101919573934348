import {Component, OnInit} from '@angular/core';
import {CartegraphConfiguration, CartegraphProcessingNotification} from '../../../../../shared/models/cartegraph.model';
import {CartegraphManagementService} from '../../../../../data/cartegraph/cartegraph-management.service';
import {Router} from '@angular/router';
import {CartegraphNotificationsAddDialogComponent} from './cartegraph-settings-notifications-add.component';
import {MatDialog} from '@angular/material/dialog';
import {firstValueFrom} from 'rxjs';


@Component({
  selector: 'app-cartegraph-settings-notifications',
  templateUrl: './cartegraph-settings-notifications.component.html',
  styleUrls: ['../../../settings-fields.scss',
    '../../../settings-common.scss'
  ],
})
export class CartegraphNotificationsComponent implements OnInit {

  configuration: CartegraphConfiguration;

  emails: string[] = [];
  excludeFilter: string[] = [];

  uiError: string;
  isLoading = true;
  isSaving = true;

  constructor(private cartegraphManagementService: CartegraphManagementService,
              private readonly router: Router,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.cartegraphManagementService.getConfiguration().then(response => {
      if (response.error) {
        this.uiError = response.error;
      } else {
        this.configuration = response.data ? response.data : new CartegraphConfiguration();
      }
      this.init();
    }).catch((error) => {
      console.log(error);
      this.uiError = error;
    }).finally(() => {
      this.isLoading = false;
    });
  }

  init() {
    this.emails = this.configuration.settings.processing.notification.notificationEmails;
    this.excludeFilter = this.configuration.settings.processing.notification.excludeFilter;
  }

  private saveChanges() {
    this.uiError = null;
    if (!this.canBeSaved()) {
      console.warn('cannot be saved');
      return;
    }

    const notifications = this.configuration.settings.processing.notification ?? new CartegraphProcessingNotification()
    notifications.notificationEmails = this.emails;
    notifications.excludeFilter = this.excludeFilter;

    this.isSaving = true;
    this.cartegraphManagementService.updateNotifications(notifications).then(response => {
      if (!!response.error) {
        this.uiError = response.error;
      } else {
        this.configuration = response.data;
        this.init();
      }
    }).catch(error => {
      console.log(error);
      this.uiError = error;
    }).finally(() => {
      this.isSaving = false;
    });
  }

  private canBeSaved(): boolean {
    return !this.isLoading;
  }

  removeEmail(index: number) {
    if (!!this.emails) {
      this.emails.splice(index, 1);
    }
    this.saveChanges();
  }

  editEmail(index?: number) {
    const dialog = this.dialog.open(
      CartegraphNotificationsAddDialogComponent,
      {
        data: {
          description: 'Emails added here will get notifications on submit or import error',
          itemType: 'email',
          value: this.emails[index]
        }
      }
    );

    firstValueFrom(dialog.afterClosed()).then((result: string) => {
      if (result) {
        if (index >= 0) {
          this.emails.splice(index, 1, result);
        } else {
          this.emails.push(result);
        }
        this.saveChanges();
      }
    });
  }

  removeFilter(index: number) {
    if (!!this.excludeFilter) {
      this.excludeFilter.splice(index, 1);
    }
    this.saveChanges();
  }

  editFilter(index?: number) {
    const dialog = this.dialog.open(
      CartegraphNotificationsAddDialogComponent,
      {
        data: {
          description: 'Notifications containing words or sentences added here will exclude sending the notification',
          itemType: 'filter',
          value: this.excludeFilter[index]
        }
      }
    );
    firstValueFrom(dialog.afterClosed()).then(result => {
      if (result) {
        if (index >= 0) {
          this.excludeFilter.splice(index, 1, result);
        } else {
          this.excludeFilter.push(result);
        }
        this.saveChanges();
      }
    });
  }

  navigateBack() {
    this.router.navigate(['settings', 'cartegraph-settings', 'settings']).then();
  }

}
