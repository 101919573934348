<h2 mat-dialog-title>{{ actionType }} {{ data.itemType }}</h2>
<form [formGroup]="form">
    <mat-dialog-content style="position:relative;">

        <div class="field-row">
            <div class="field-row-title">
                {{ actionType }} {{ data.itemType }}
            </div>
            <div class="field-row-element">
                <mat-form-field appearance="outline" color="accent" class="form-input">
                    <input matInput
                           autocomplete="off"
                           [attr.type]="data.itemType==='email'?'email':'text'"
                           [placeholder]="data.itemType==='email'?'abc@email.com':'lmu unit only'"
                           formControlName="field"/>
                    @if (!!form.controls['field'].errors?.required) {
                        <mat-error>Value is required</mat-error>
                    }
                    @if (!!form.controls['field'].errors?.pattern) {
                        <mat-error>Invalid email format</mat-error>
                    }
                </mat-form-field>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-stroked-button mat-dialog-close> Cancel</button>
        <button mat-stroked-button class="ml-10"
                (click)="addField()"
                [disabled]="!!form.controls['field'].invalid"
        > {{ actionType }}
        </button>
    </mat-dialog-actions>
</form>



