<app-card preCardTitle="Vehicles In Group"
		  icon="arrow_back"
		  (iconClicked)="navigateBack()">
	<div *appCardContent class="settings-header-container mb-15 align-items-center">
		<div class="settings-header-title">
			<p>Vehicles</p>
		</div>
		<div class="settings-header-actions">
			<button mat-stroked-button
					(click)="addVehicle()">
				Add Vehicle
			</button>
		</div>
	</div>
	<div *appCardContentRaw>
		@if(isLoading) {
			<mat-spinner mode="indeterminate" diameter="30" class="mx-a my-10"></mat-spinner>
		}

		<mat-nav-list class="py-0">
			@for (vehicle of vehicles | async; track vehicle.id) {
				<a mat-list-item
				   [routerLink]="['/' + RootRoute.SETTINGS, SettingsRoute.MANAGE_VEHICLES, 'vehicle', vehicle.id]">
					<div matListItemTitle>{{vehicle.name}}</div>
					<div matListItemMeta>
						<mat-icon>arrow_right</mat-icon>
					</div>
				</a>
			}
		</mat-nav-list>
	</div>
</app-card>

