import {AfterViewInit, Component, ElementRef, Input, OnDestroy, output, QueryList, ViewChildren} from '@angular/core';
import {MatCardActions} from '@angular/material/card';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {MatFormField} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {MatTooltip} from '@angular/material/tooltip';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {NgClass} from '@angular/common';

@Component({
  selector: 'app-card-editable-heading',
  standalone: true,
  imports: [
    MatCardActions,
    MatIcon,
    MatIconButton,
    MatFormField,
    MatInput,
    MatTooltip,
    NgClass
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CardEditableHeadingComponent
    }
  ],
  templateUrl: './card-editable-heading.component.html',
  styleUrls: ['./card-editable-heading.component.scss', '../card/card.component.scss']
})
export class CardEditableHeadingComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {

  @Input()
  icon: string

  @Input()
  disabled = false

  @Input()
  set editable(value: boolean) {
    this.disabled = !value;
  }

  @Input()
  iconDisabled = false

  iconClicked = output<void>()
  editedValue: string
  //valueChanged = output<string>()

  valueChangeReported = false
  editing = false;
  value: string

  @ViewChildren('name', {read: ElementRef}) textInputs: QueryList<ElementRef>;

  subscriptions: Subscription[] = []

  onChangeListener = _ => {
  }

  onTouchedListener = () => {
  }

  ngAfterViewInit() {
    this.subscriptions.push(
      this.textInputs.changes
        .pipe(debounceTime(0))
        .subscribe((list: QueryList<ElementRef>) => {
          if (list.length) {
            list.first.nativeElement.focus();
          }
        })
    );
  }

  startEdit() {
    if (this.editable && !this.disabled) {
      this.editing = true;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  writeValue(obj: any) {
    this.value = obj;
    this.editing = false;
    this.valueChangeReported = false;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  registerOnTouched(fn: any) {
    this.onTouchedListener = fn;
  }

  registerOnChange(fn: any) {
    this.onChangeListener = fn;
  }

  valueChangeConfirmed() {
    this.onChangeListener(this.editedValue);
    this.valueChangeReported = true;
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.editedValue = (event.target as HTMLInputElement).value;
      this.valueChangeConfirmed();
    } else if (event.key === 'Escape') {
      this.editing = false;
    }
  }
}
