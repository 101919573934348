import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions, MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import { UserManagement, UserModelView, UserRequestModel, UserType } from '../../../../../../shared/models/User';
import { MatButton } from '@angular/material/button';
import { UserManagementService } from '../../../../../../data/users/user-management.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { NgForOf, NgIf } from '@angular/common';
import { MatError, MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatOption, MatSelect } from '@angular/material/select';
import {
  FormControl,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ConfigurationService } from '../../../../../../configuration/configuration.service';
import { ConfigurationModel } from '../../../../../../shared/models/configuration.model';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-promote-user',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogTitle,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    NgIf,
    MatFormField,
    MatSelect,
    MatOption,
    NgForOf,
    MatInput,
    ReactiveFormsModule,
    MatSuffix,
    MatLabel,
    MatError,
    MatProgressSpinner,
  ],
  templateUrl: './promote-user.component.html',
  styleUrl: './promote-user.component.scss'
})
export class PromoteUserComponent implements OnInit, OnDestroy {

  uiError = '';

  isLoading = true;
  configuration: ConfigurationModel;
  userType: UserType;
  userForm: UntypedFormGroup;
  isSaving = false;

  private userTypeSupervisor = {
    name: UserManagement.ROLE_PORTAL_USER,
    label: UserManagement.ROLE_PORTAL_USER_LABEL
  } as UserType;
  private userTypeAdmin = {
    name: UserManagement.ROLE_PORTAL_ADMIN,
    label: UserManagement.ROLE_PORTAL_ADMIN_LABEL
  } as UserType;
  userTypes: UserType[] = [
    this.userTypeSupervisor,
    this.userTypeAdmin,
  ];

  private readonly openSubscriptions = Array<Subscription>();

  constructor(
    public dialogRef: MatDialogRef<PromoteUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { email: string, user: UserModelView },
    protected fb: UntypedFormBuilder,
    private configurationService: ConfigurationService,
    private userManagementService: UserManagementService,
  ) {
  }

  get ngUserType(): FormControl {
    return this.userForm.get('userType') as FormControl;
  }

  get ngEmailPrefix(): FormControl {
    return this.userForm.get('emailPrefix') as FormControl;
  }

  ngOnInit() {
    this.initForm();
    this.loadGeneralConfiguration();

    this.ngUserType.setValue(this.userTypeSupervisor);
  }

  ngOnDestroy() {
    this.openSubscriptions.forEach(sub => sub?.unsubscribe());
  }

  private loadGeneralConfiguration() {
    const configSubscription = this.configurationService.sharedConfigurationModel.subscribe(model => {
      this.configuration = model;
      this.isLoading = false;
    });
    this.openSubscriptions.push(configSubscription);
  }

  private buildEmailControl(email: string): UntypedFormControl {
    let emailPrefix = '';
    const indexOfAt = email?.indexOf('@');
    if (indexOfAt) {
      emailPrefix = email.substring(0, indexOfAt);
    }
    return this.fb.control(emailPrefix, {
      validators: [
        Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+$')
      ]
    });
  }

  private initForm() {
    // init form
    this.userForm = this.fb.group({
      email: this.fb.control(this.data.user.email),
      userType: this.fb.control(this.userType),
      emailPrefix: this.buildEmailControl(this.data.user.email),
    });
    this.userForm.controls['email'].disable();

    // on change listeners, update the data back to the model
    this.userForm.valueChanges.subscribe(change => {
      this.data.user.email = change['emailPrefix'] + this.configuration.driverSuffix;
    });

    this.userForm.get('userType').valueChanges.subscribe(change => {
      this.userTypeChange(change);
    });
  }

  userTypeChange(userType: UserType) {
    switch (userType.name) {
      case UserManagement.ROLE_PORTAL_USER:
        this.data.user.roles = [
          UserManagement.ROLE_DRIVER,
          UserManagement.ROLE_PORTAL_USER
        ];
        break;
      case UserManagement.ROLE_PORTAL_ADMIN:
        this.data.user.roles = [
          UserManagement.ROLE_DRIVER,
          UserManagement.ROLE_PORTAL_ADMIN];
        break;
      default:
        this.data.user.roles = [UserManagement.ROLE_DRIVER];
    }
  }

  performSave() {
    this.isSaving = true;
    firstValueFrom(
      this.data.email !== this.data.user.email
        ? this.userManagementService.promoteUser(this.data.email, this.data.user)
        : this.userManagementService.updateUser(
          UserRequestModel.fromUserModel(this.data.user, true)
        )
    ).then(model => {
      this.dialogRef.close(model.data);
    }).catch((error) => {
      this.isSaving = false;
      this.uiError = error;
    });
  }
}
