<div class="flex-column gap-32">

	<app-card preCardTitle="Group Management">
		<div *appCardContent class="settings-header-container">
			<div class="settings-header-title">
				<p>Add Observation Group</p>
				<div class="settings-header-subtitle">Observation groups can be assigned to your vehicle groups</div>
			</div>
			<div class="settings-header-actions">
				<button (click)="addObservationTypeGroupDialog()"
						mat-stroked-button>
					Add Observation Group
				</button>
			</div>
		</div>
	</app-card>

	@if (isLoading) {
		<mat-spinner class="mx-a mt-15" diameter="30"></mat-spinner>
	}

	@for (observationTypeGroup of observationTypeGroups; track observationTypeGroup.id) {
		<app-card [preCardTitle]="'Observation Group \'' + observationTypeGroup.name + '\''">
			<div *appCardContent class="settings-header-container mb-spacing">
				<div class="settings-header-title">
					<p>Group settings</p>
					<div class="settings-header-subtitle">Modify settings for all observations in this group</div>
				</div>
				<div class="settings-header-actions">
					<button mat-stroked-button
							(click)="editObservationTypeGroup(observationTypeGroup)">
						Settings
					</button>
				</div>
			</div>

			<div *appCardContent class="settings-header-container my-spacing">
				<div class="settings-header-title">
					<p>Add Observation Type</p>
					<div class="settings-header-subtitle">Create new observation type and adds it to this group</div>
				</div>
				<div class="settings-header-actions">
					<button mat-stroked-button
							(click)="addObservationTypeDialog(observationTypeGroup)">
						Add Observation Type
					</button>
				</div>
			</div>

			<div *appCardContent>
				<h2 class="mb-0">Observation types in a group</h2>
				@if (observationTypeGroup.observationTypes?.length) {
					<table mat-table [dataSource]="observationTypeGroup.observationTypes" class="mb-8">
						<ng-container matColumnDef="title">
							<th mat-header-cell *matHeaderCellDef>Observation Name</th>
							<td mat-cell *matCellDef="let type">{{ type.title }}</td>
						</ng-container>

						<ng-container matColumnDef="abbreviation">
							<th mat-header-cell *matHeaderCellDef>Alias</th>
							<td mat-cell *matCellDef="let type">{{ type.abbreviation }}</td>
						</ng-container>

						<ng-container matColumnDef="operations">
							<th mat-header-cell *matHeaderCellDef></th>
							<td mat-cell *matCellDef="let type" class="column-actions">
								<button mat-icon-button [matMenuTriggerFor]="menu">
									<mat-icon>more_vert</mat-icon>
								</button>
								<mat-menu #menu="matMenu">
									<button mat-menu-item
											(click)="editObservationType(type, observationTypeGroup)">
										<mat-icon>edit</mat-icon>
										<span>Update Group Information</span>
									</button>
									<button mat-menu-item disabled>
										<mat-icon>move_up</mat-icon>
										<span>Move up</span>
									</button>
									<button mat-menu-item disabled>
										<mat-icon>move_down</mat-icon>
										<span>Move down</span>
									</button>
									<button mat-menu-item
											(click)="deleteObservationType(type, observationTypeGroup)">
										<mat-icon>delete</mat-icon>
										<span>Delete</span>
									</button>
								</mat-menu>
							</td>
						</ng-container>

						@if (observationTypeGroup.observationTypes.length > 0) {
							<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
						}
						<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
					</table>
				} @else {
					<div class="mt-16 text-muted">
						No observation types defined for the group yet.
					</div>
				}
			</div>
		</app-card>
	}
</div>
