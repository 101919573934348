import {Component, ElementRef, EventEmitter, Output, ViewChild} from '@angular/core';
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import {
  LowerCasePipe,
  NgForOf,
  NgIf,
  NgPlural,
  NgPluralCase,
  NgSwitch,
  NgSwitchCase,
  NgTemplateOutlet
} from '@angular/common';
import {MatButton} from '@angular/material/button';
import {MatCard, MatCardActions, MatCardContent, MatCardHeader, MatCardTitle} from '@angular/material/card';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable
} from '@angular/material/table';
import {MatCheckbox} from '@angular/material/checkbox';
import {MatIcon} from '@angular/material/icon';
import {MatProgressBar} from '@angular/material/progress-bar';
import {MatStep, MatStepper, MatStepperNext, MatStepperPrevious} from '@angular/material/stepper';
import {CsvUserImportRecord} from '../../../../../../shared/models/User';
import {UserManagementService} from '../../../../../../data/users/user-management.service';
import {JsonApiResponse} from '../../../../../../shared/models/JsonApiResponse';
import {FormsModule} from '@angular/forms';
import {CsvDataLoaderAction} from '../../../../../../shared/models/csv-data-loader-action';

@Component({
  selector: 'app-import-users-spreadsheet-dialog',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogTitle,
    LowerCasePipe,
    MatButton,
    MatCard,
    MatCardActions,
    MatCardContent,
    MatCardHeader,
    MatCardTitle,
    MatCell,
    MatCellDef,
    MatCheckbox,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatIcon,
    MatProgressBar,
    MatRow,
    MatRowDef,
    MatStep,
    MatStepper,
    MatStepperNext,
    MatTable,
    NgForOf,
    NgIf,
    NgPluralCase,
    NgSwitchCase,
    NgTemplateOutlet,
    NgPlural,
    FormsModule,
    NgSwitch,
    MatDialogActions,
    MatDialogClose,
    MatHeaderCellDef,
    MatStepperPrevious
  ],
  templateUrl: './import-users-spreadsheet-dialog.component.html',
  styleUrl: './import-users-spreadsheet-dialog.component.scss'
})
export class ImportUsersSpreadsheetDialogComponent {
  @Output() changePageEvent = new EventEmitter<string>();
  @ViewChild('stepper') stepper: MatStepper;

  uiError: string;

  selectedFile: File;
  validData: CsvUserImportRecord[];
  invalidData: CsvUserImportRecord[];

  displayedColumnsValid = [
    'selected', //
    'line',
    'role',
    'givenName',
    'familyName',
    'email',
    'phoneNumber',
    'action', //
  ];

  displayedColumnsInvalid = [
    'line',
    'errors', //
    'role',
    'givenName',
    'familyName',
    'email',
    'phoneNumber',
  ];

  ImportUsersStateEnum = ImportUsersStateEnum; // allow access from template via variable
  currentState = ImportUsersStateEnum.WAITING_FOR_FILE;
  showCsvHelp = false;

  @ViewChild('fileInput')
  fileInput: ElementRef;

  CsvDataLoaderAction = CsvDataLoaderAction;

  numberOfImportedUsers = 0;

  constructor(public dialog: MatDialog,
              public dialogRef: MatDialogRef<ImportUsersSpreadsheetDialogComponent>,
              private userService: UserManagementService) {
  }

  handleFileInput(event: Event) {
    this.selectedFile = (event.target['files'] as FileList)[0];
    this.uploadSpreadsheetFile();
  }

  canBeSaved() {
    return this.hasSomeValidRowsToImport();
  }

  doImport(): Promise<JsonApiResponse<number>> {
    this.stepper.next();
    this.currentState = ImportUsersStateEnum.IMPORTING;
    const selectedData = this.rowsToImport();
    const apiPromise = this.userService.uploadAndSaveCsvUserImport(selectedData);
    return apiPromise
      .then((response) => {
        this.currentState = ImportUsersStateEnum.IMPORT_SUCCEEDED;
        this.numberOfImportedUsers = response.data;
        return response.data;
      }).catch(error => {
        this.uiError = error;
        this.currentState = ImportUsersStateEnum.IMPORT_FAILED;
      });
  }

  onCancel() {
    this.dialogRef.close(this.currentState === ImportUsersStateEnum.IMPORT_SUCCEEDED);
  }

  hasSomeValidRowsToImport(): boolean {
    const rowsToImport = this.rowsToImport();
    return rowsToImport && rowsToImport.length > 0;
  }

  rowsToImport(): CsvUserImportRecord[] {
    return this.validData?.filter(row => row.selected === true);
  }

  private uploadSpreadsheetFile() {
    this.currentState = ImportUsersStateEnum.UPLOADING_FILE;
    const formData: FormData = new FormData();
    formData.append('file', this.selectedFile, this.selectedFile.name);
    this.userService.uploadAndVerifyCsvUserImport(this.selectedFile).then((o) => {
      this.validData = o.data.filter(row => row.action !== CsvDataLoaderAction.NONE && !row.errors?.length);
      this.invalidData = o.data.filter(row => row.action === CsvDataLoaderAction.NONE || row.errors?.length > 0);
      // make the valid rows all selected
      this.validData.forEach(row => row.selected = true);
      this.stepper.next();
    }).catch(error => {
      this.uiError = error;
      this.currentState = ImportUsersStateEnum.UPLOAD_FAILED;
    });
  }
}

enum ImportUsersStateEnum {
  WAITING_FOR_FILE,
  UPLOADING_FILE,
  UPLOAD_FAILED,
  IMPORTING,
  IMPORT_FAILED,
  IMPORT_SUCCEEDED
}

