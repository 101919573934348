@if (isLoading) {
    <div *appCardContent>
        <div class="mb-spacing">
            <div class="indent">
                <mat-spinner mode="indeterminate" diameter="30" class="mx-a my-15"></mat-spinner>
            </div>
        </div>
    </div>
} @else {
    @if (!!form) {
        <div class="settings-header-title">
            <p>Observation</p>
        </div>
        <div class="mb-spacing">
            <div class="indent">
                <form [formGroup]="form">

                    <div class="field-row">
                        <div class="field-row-title">
                            Priority
                        </div>
                        <div class="field-row-element">
                            <app-row-with-select [list]="datasetPriority" listId="label" listLabel="label"
                                                 formControlName="priority"></app-row-with-select>
                            @if (form.controls['priority'].hasError('required')) {
                                <mat-error> Priority Type is required.</mat-error>
                            }
                        </div>
                    </div>

                    <div class="field-row">
                        <div class="field-row-title">
                            Status
                        </div>
                        <div class="field-row-element">
                            <app-row-with-select [list]="datasetStatus" listId="label" listLabel="label"
                                                 formControlName="status"></app-row-with-select>
                            @if (form.controls['status'].hasError('required')) {
                                <mat-error> Priority Type is required.</mat-error>
                            }
                        </div>
                    </div>


                    <div class="field-row">
                        <div class="field-row-title">
                            Activity
                        </div>
                        <div class="field-row-element">
                            <app-row-with-select [list]="datasetActivity" listId="label" listLabel="label"
                                                 formControlName="activity"></app-row-with-select>
                            @if (form.controls['priority'].hasError('required')) {
                                <mat-error> Priority Type is required.</mat-error>
                            }
                        </div>
                    </div>

                    <div class="field-row">
                        <div class="field-row-title">
                            Work Completed For
                        </div>
                        <div class="field-row-element">
                            <app-row-with-select [list]="datasetWorkCompletedFor" listId="label" listLabel="label"
                                                 formControlName="workCompletedFor"></app-row-with-select>
                            @if (form.controls['priority'].hasError('required')) {
                                <mat-error> Priority Type is required.</mat-error>
                            }
                        </div>
                    </div>

                </form>
            </div>
        </div>

    }
}
