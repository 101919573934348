import {Pipe, PipeTransform} from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'duration2'
})
export class Duration2Pipe implements PipeTransform {

  /**
   * Gets 2 dates and returns duration in human-readable format
   * @param value default string to be returned when duration cannot be calculated
   * @param args arg0 start Date, arg1 end Date
   */
  transform(value: string, ...args: any[]) {
    const [start, end] = args;
    if (!start || !end) {
      return value;
    }

    const duration = moment.duration(moment(end).seconds() - moment(start).seconds(), 'seconds');

    const hours = duration.asHours();
    const hoursFormatted = hours && hours > 1 ? ` ${Math.floor(hours)} h` : '';

    const minutes = duration.minutes();
    const minutesFormatted = minutes ? ` ${minutes} m` : '';

    const seconds = duration.seconds();
    const secondsFormatted = ` ${seconds} s`;

    return `${hoursFormatted}${minutesFormatted}${secondsFormatted}`;
  }

}
