<div class="shift-map-container" *ngIf="!isLoading">

  <div class="map-preview-container" #mapContainer>
    <app-map-preview
      *ngIf="!!mapContent"
      [nextContent]="mapContent"
      [mapTitle]="'Shift Map'"
    ></app-map-preview>
  </div>

  <div
    class="shift__map__left__content"
  >
    <app-shift-map-panel
      [configuration]="configuration"
      [shift]="shift"
      [images]="images"
      [observations]="observations"
      class="left__panel"
    ></app-shift-map-panel>

    <div class="left__map__overlay">
      <div class="overlay-bottom-left-content">
        <div class="time__filter__wrapper" *ngIf="!!mapContent">
          <app-time-filter-bar [shiftLength]="getShiftLength()" [shiftMapContent]="mapContent"></app-time-filter-bar>
        </div>
      </div>

      <div class="map__loading__content"
           *ngIf="isLoading">
        <mat-spinner></mat-spinner>
      </div>
    </div>
  </div>
</div>
