<div class="shift-report-container">

  <div class="report-menu">
    <div class="report-name-and-desc">
      <div class="report-name">
        Shift Report
      </div>
      <div class="report-description">
        Retrieve shift based stats for your fleet
      </div>
    </div>

    <div class="report-menu__actions">
      <button
              mat-icon-button
              [matMenuTriggerFor]="downloadMenu"
              class=""
              [matTooltip]="'Download'"
      >
        <mat-icon>download_for_offline</mat-icon>
      </button>
      <mat-menu #downloadMenu="matMenu">
        <button
                mat-menu-item
                (click)="loadCSVExportFile()"
        >
          CSV
        </button>
        <button
                mat-menu-item
                (click)="loadPDFExportFile()"
        >
          PDF
        </button>
      </mat-menu>

      <button mat-icon-button
              (click)="showSettingsDialog()"
              class="caption text-primary"
              matTooltip="Configure visible columns"
      >
        <mat-icon>settings</mat-icon>
      </button>

      <app-filter-button [appliedFiltersCount]="appliedFiltersCount"
                         [active]="showingFilterBar"
                         (toggled)="toggleFilter()"
      ></app-filter-button>

      <button
              mat-icon-button
              class="back__btn"
              (click)="closeInsight()"
              [matTooltip]="'Close'"
      >
        <mat-icon class="action-icon">close</mat-icon>
      </button>
    </div>
  </div>

  <app-filter-bar
    class="report-menu__filters"
    [origin]="'shiftReport'"
    [showingFilterBar]="showingFilterBar"
    [useDateFilter]="true"
    [useVehicleGroupFilter]="true"
    (dateFilterChanged)="dateFilterChanged($event)"
    (vehicleGroupFilterChanged)="vehicleGroupFilterChanged($event)"
    (appliedFiltersChanged)="appliedFiltersCount = $event"
  >
  </app-filter-bar>

  <div class="report__table__wrapper"
       [ngClass]="{'report-table-with-filter-bar': showingFilterBar}"
  >
    <app-shift-report-table
      [configuration]="configuration"
      [columns]="columns"
      [dataSource]="dataSource"
      [reportTotal]="reportTotal"
      (sortChangedEvent)="loadReport()"
      (scrolledToBottomEvent)="extendReport()"
    >
    </app-shift-report-table>
  </div>
</div>
