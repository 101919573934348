<div class="wide__page__container">
    <div class="top-bar">
        <button
                class="back__btn"
                (click)="close()"
                mat-icon-button
                aria-label="close"
        >
            <mat-icon class="action-icon">close</mat-icon>
        </button>
    </div>

    <div class="about-container"
        role="contentinfo">

        <markdown ngPreserveWhitespaces>
            # Release Information
            ## Version 2.6.2 (2024-06-07)
            - #30764 Location service | Support liquid units
            - #31455 Route Configuration | Store "Add to Live Map" checkbox value in the configuration
            - #31454 Custom Route Layer | Fixed hidden routes
            - #31076 Observations | Use custom color on label
            - #31190 Cartegraph | Allow Standard Inspection Form Access
            - #31419 Cartegraph | Change integration to work with existing users/vehicles

            ## Version 2.6.1 (2024-05-17)
            - #31290 Cartegraph | Support Material Usage Submission
            - #31286 Cartegraph | Submit data after post-processing (statistics)
            - #31191, #31267, #31266 Form fill duration
            - #31204 Route Configuration | Add to Custom Map Layer
            - #31167 Routes | Enhanced Line Support

            ## Version 2.6.0 (2024-04-18)
            - #31153 Digital Spreader | Support Force America ELA Module
            - #31193 Cartegraph | Notification Items
            - #31200 Shift Accumulated Material Usage | Shift post processing logging

            ## Version 2.5.2 (2024-04-08)
            - #31182 Route Import: Improved performance when storing route geometries

            ## Version 2.5.1 (2024-04-02)
            - #31163 Set new credentials for sending emails

            ## Version 2.5.0 (2024-03-21)
            - #30766 Road Status | Enhanced Line Type Support
            - #30804 Fixed displaying degrees on Weather News on MS Windows
            - #30840 Shift Report | Added Material Used columns into PDF export
            - #30852 Added Custom Map Layers endpoint for Mobile
            - #30123 Inspection Forms | Allow for N/A Column
            - #30913 Route Import: Asynchronous approach for configuration and geometries with optimizations

            ## Version 2.4.10 (2024-02-28)
            - #30877 Cartegraph | Tasks are not being tied to equipment
            - Dashboard: enabled to save empty phone number for shift device info report

            ## Version 2.4.9 (2024-02-22)
            - #30534 Dashboard | Redirect Issue - mitigated loop redirects
            - #30878 Cartegraph | Fixed material import to update usage units

            ## Version 2.4.8
            - #30690 Adjusted Database tasks unlocking - threshold set to 30 min

            ## Version 2.4.7
            - #30858 Cartegraph | Windsor | Report Labour + equipment log on same date (shift end)
            - #30851 Sentry | Stop reporting 401 errors
            - #30850 Sentry | Error handler fails to serialize http error: Expected varint not more than 10 bytes

            ## Version 2.4.6
            - #30823 Bugfix to better handle http calls after token refresh (HTTP 401)

            ## Version 2.4.5 (2024-02-05)
            - #30771 CalAmp service bugfixes
            - LMU not reporting correctly vehicle when Digital Spreader is connected
            - Updated serialization/deserialization and creation of CalAmp from hex
            - Logging hex message string and json date format in readable GMT format
            - Additional fix for lmuId usage when compared to cached data

            ## Version 2.4.4
            - #30783 Cartegraph | Inspection questions ordering resets on refresh or import
            - #30784 Cartegraph | Sending observations reports often - add more logging
            - #30780 Dashboard | fix undefined error on my-account component

            ## Version 2.4.3
            - Upgraded versioning

            ## Version 2.4.2
            - #30777 Cartegraph | Sync tasks view is missing "reprocess" button
            - #30775 Cartegraph | Don't process equipment and material log if disabled in tenant feature flags
            - #30768 Cartegraph | Don't use hardcoded tenant Id

            ## Version 2.4.1
            - Fixes for VT generator - latest GDAL docker image, more logging, and app folder fix
            - CF configuration: log retention adjustment
            - #30759 Public Portal | Separate configuration for Page Title and Messages, Configurable colors for header

            ## Version 2.4.0 (2024-01-24)
            - #30646 Insights | Device info report
            - #30698 Updated Firebase Admin SDK
            - #30725 Cartegraph | Rosemount custom integration
            - Reverse Proxy: Parameters Adjustments
            - CalAmp: Added missing indices
            - Dashboard: Added last passed date tooltip on Road Status popup
            - #26755 Auth Service: Updated ToS and Privacy links
            - #30690 Updated automatic unlock threshold to 4 hours
            - #30756 Public Portal | Increased Refresh Rate to 2 Minutes
            - #30755 Shift Look-up | Apply # of Shifts changes when filtering
            - #30754 Inspection Forms | Fixed when deleted, removed from the list
            - #30753 Route Assignments | Show as In Progress even if it's been serviced recently
            - #30757 Public Portal | Added Route Status Map Layer
            - #30758 Digital spreader | Support Blast rate

            ## Version 2.3.0 (2023-12-18)
            - #30673 Fixed shifts duplicated on shift report
            - #28421 Public Portal | Added Time Delay
            - #30690 Fixed automatic database tasks unlocking
            - #30684 Allow Supervisor role to End Shift
            - #30687 Live Map | Selected Vehicle | Remove Phone Number
            - #30688 Shift Report | Add Total Shift Count
            - #30686 Currency Legend | Updated to 10+
            - #30689 Route Status Map | Extended to have 36, 48 and 72 hour options
            - #30683 Store device IMEI and Serial Number
            - #30694 MapLibre update to version 3.6.2

            ## Version 2.2.4 (2023-12-11)
            - #30654 Updated ESRI API key for address lookup and geocoding

            ## Version 2.2.3 (2023-12-04)
            - #30641 Dashboard | Insights | Shift Report | Shift Detail | Show device info phone number
            - #30646 Dashboard | Insights | Shift Report | Shift Detail | Add phone number to shift report
            - #30642 Cartegraph | provide filtering for material import

            ## Version 2.2.2 (2023-11-30)
            - #30636 Cartegraph | handle server error in Dashboard UI
            - #30632 Cartegraph | Equipment and material import must remove orphaned data
            - #30628 Cartegraph | Equipment log for Miles reporting vehicles should provide also hours
            - #30629 Cartegraph | Possibility to reorder questions to be before imported or be mixed
            - #30591 Cartegraph | don't process sync tasks for LMU only (missing driver and vehicle record in CG)
            - #30627 Cartegraph | Add support for activity filtering
            - #30626 Cartegraph | Shift end time should be same as on apk
            - #30630 Cartegraph | Import job clashes between different tenants
            - locking imports per tenant
            - unlocking import tasks automatically after 2 hours
            - #30602 User Management | Hide neotreks_support user
            - #30604 Currency/Coverage | Apply styles right on the change saved
            - #30603 Live Map Hardware Type filter | Updated button text

            ## Version 2.2.1 (2023-11-17)
            - #30589 Breadcrumb Search | Fixed Open Shift Detail button
            - #30597 Exposed trackStyles endpoint for Mobile app to retrieve currency colours
            - #30600 Cartegraph | Recognise vehicle meter type during import
            - #30592 Digital Spreader | Fixed Material calculation

            ## Version 2.2.0
            - #30552 Dashboard | Hide deactivated drivers/users
            - #30528 Dashboard | Handle session event error 500
            - #30531 Dashboard | Granular spreader bit decoding fails on reading 'bitNumber'
            - #30534 Dashboard | Redirect Issue - fix loop on login error
            - #30557 Cartegraph | Add form fill duration to shift duration
            - #30514 Cartegraph | Refactoring
            - #30556 Shift Auto-End | Ignore very short shifts when checking for auto-end
            - #30511 Vehicle Groups | Allow Self-Assignment option
            - #30481 Current Weather | Showing N/A on too old observations
            - #30500 Address Look-up | Adjust Search Bar Look based on Live Map Search control
            - #30430 Administration | Restyling and update to Angular 16
            - #30049 Digital Spreader | Updated vehicle configuration and displaying status
            - #30483 Dashboard | Route Status Mode | Toggle label changed to "Legacy Mode"
            - #30575 Manage Routes | Auto-select the first configuration
            - #30582 Vehicle Management | Action menu triggered on row click and other UI adjustments
            - #30577 Live Map Multiselect Filters | Fully checked as default

            ## Version 2.1.3 (2023-10-29)
            - #30549 Dashboard | Enable updating user password for Cartegraph integration

            ## Version 2.1.2 (2023-10-25)
            - #30534 Dashboard | Redirect Issue

            ## Version 2.1.1 (2023-10-19)
            - #30497: Dashboard | Set-up | Users | Enable to update imported users

            ## Version 2.1.0 (2023-10-18)
            - #30421 Email notifications on DVIR submissions
            - #30425 Added validation on route assignment
            - #30333, #30335, #30337 Digital Spreader Integration | Dashboard | Display Variable Rates
            - #30428 Manage Vehicles | Support Moving Vehicles from Group to Group
            - #30435 Fixed Active Assignments count, so it depends on left menu selection
            - #30437 Do not load stationary observations on active observations load
            - #30434 Address Look-up | New Design
            - #30448 Weather Forecast | Hide coordinates if city is available
            - #30443 Live Map | Messages | Fixed Stacking Issue
            - #30449 Increased width of Route Assignments Stats Right Panel
            - #30449 Completed Assignments | Smaller menu items
            - #30457, #30454 Shift Details | Minor DVIR to Inspection Form Changes
            - #30455 Fixed customer name display when it is too long
            - #30456 Fixed back link on Shift Detail
            - #30460 DVIR Report | Missing items
            - #30482 Re-enabled allowed route configs on Vehicle Group configuration
            - #30516 Fixed color picker issues
            - #30499 Address Look-up | Loading visits defaults to 90 Days

            ## Version 2.0.3 (2023-10-05)
            - #30440 Feature Proxy Service | Fixed parsing properties when field type is Int16

            ## Version 2.0.2
            - bugfix: CalAmp service sending location history properly handles error
            - #30420 Allow Route Assignments only to vehicles with tablet

            ## Version 2.0.1
            - bugfix: CalAmp service parsing vehicle configuration

            ## Version 2.0.0 (2023-09-26)
            - #29423 Live Vehicle Video Streaming (AWS Kinesis Video Streams)
            - #30117 Support DashCam Images
            - #30010 Route Assignment support
            - #29584 Dashboard/Administration | Updated to Angular 15
            - #29512 Dashboard | Deprecated FlexLayout library removal
            - #29615 Dashboard | New layout - top navigation panel, right panel
            - #29525 Support ESRI Authentication for route configurations and custom map layers

            ## Version 1.20.18 (2023-07-20)
            - #30160 | Windsor | Cartegraph | Include Sector Area into End Shift Task

            ## Version 1.20.17 (2023-07-17)
            - #30182 | Cartegraph | Add Status configuration option for different tasks

            ## Version 1.20.16 (2023-07-13)
            - #30161 | Cartegraph | Add "Sector Area" question

            ## Version 1.20.15 (2023-07-11)
            - #30152 | Carrtegraph | Add support to see all submitted sub-tasks with links to Cartegraph

            ## Version 1.20.14 (2023-07-07)
            - #30032 | Cartegraph | Fix DVIR required flag for cartegraph feature in UI

            ## Version 1.20.13 (2023-07-04)
            - #29638 | Cartegraph | CDOT Road Analysis - fixed CDOT routes download URL

            ## Version 1.20.12 (2023-06-23)
            - #30081 | Cartegraph | Add "Work Order" filtering as a configuration option

            ## Version 1.20.11 (2023-06-12)
            - Cartegraph
            - bugfix: cartegraph, stop processing end shift if force-ended

            ## Version 1.20.10 (2023-06-12)
            - Cartegraph
            - bugfix: cartegraph, stop processing CDOT analysis when shift has no geometry

            ## Version 1.20.9 (2023-06-09)
            - Cartegraph
            - bugfix: cartegraph, updated cdot routes format

            ## Version 1.20.8 (2023-06-06)
            - Cartegraph
            - bugfix: validate CDOT geojson and cache it only 1 hour locally
            - bugfix: process all events for per/post inspection

            ## Version 1.20.7 (2023-05-23)
            - #29638 CDOT Road Analysis - added automatic route download

            ## Version 1.20.6 (2023-05-10)
            - #29762 New Inspection Forms | Questions not Available on Tablet
            - #29638 CDOT Road Analysis
            - Cartegraph | Various fixes
            - Build and release system changes

            ## Version 1.20.0
            - #29122 | Cartegraph | Material filtering should depend on selected equipment not only the selected vehicle
            - #29121 | Cartegraph | Use "default usage quantity" units instead of just units for material
            - #29125 | Cartegraph | Add link to shift from sync tasks
            - #29118 | Cartegraph | Missing miles driven in shift detail equipment log
            - Cartegraph | observation sending as generic "Investigate PlowOps Observation" activity task
            - Cartegraph | changed default status for shift end sending
            - Cartegraph | not sending mileage for equipment
            - #29131 Shift Detail: Indicate Direction of Travel
            - #29129 Shift Detail: Show markers for start/end
            - #28444 Fix for Unable to Select "Settings" at Certain Times
            - #29185 Fixed Group Route Display Not Respecting Turned Off Layers
            - #29198 Shift Details | Playback of Time Filtered Shifts
            - #29186 Route Management UI refactoring
            - #29111 Public Portal Settings: Added Embed Code
            - #29184 Admin Statistics | UI and performance optimization
            - #29229 Fixed shift thumbnail geometry for smaller areas
            - #29138 Fixed Searching breadcrumbs for the latest part of the track
            - #29112 Public Portal | Hidable Header
            - #29341 Routes | Relative Thickness Upon Zoom
            - #29105 Joined Shift for LMU/Tablet
            - #29347 Map Layers Config | Added Tooltip for URL
            - #29353 Vehicle Configuration | Added LMU 3641 to List
            - #29348 Vehicle Configuration | Support Non-Consistent Sensor Values - added Plow (2-wire) type and UI refactoring
            - #29373 Fixed Initial Live Map UI Load Bug
            - #29386 Vehicle and Observation Icon changes
            - #29222 User managed Inspection Form
            - #29385 Support for Pause Shift on end shift dialog button
            - #29361 New DVIR Server side support: DVIR contains chronometer or odometer
            - #28120 Shift Report Formatting - wider container, no wrapping, minimal padding
            - #29527 Added Support for shift started retrospectively

            ## Version 1.19.9 (2023-04-17)
            - Cartegraph
            - data fetching properly counts used threads

            ## Version 1.19.8 (2023-04-13)
            - Cartegraph
            - Updated fetching and import to always import material and disabled import of cgTaskClass objects
            - Updated importing material tags, and vehicle tags for filtering
            - #29543 Add support for Snow Removal Location field

            ## Version 1.19.7 (2023-03-30)
            - Cartegraph
            - Observation sending
            - estimated and actual end time not set
            - as generic "Investigate PlowOps Observation" activity task
            - Data fetching lock/synchronized, in few threads to speed up operation to avoid ui timeout
            - Data fetching
            - fetching data in threads
            - ui, checks regularly if background job is running
            - check if background job is still running before executing
            - added authentication timeout
            - Changed default status for shift end sending
            - Disabled sending mileage for equipment
            - Add link to shift from sync tasks - fixed for different environments
            - Material filtering should depend on selected equipment not only the selected vehicle

            ## Version 1.19.6 (2023-03-13)
            - #29308 Feature Proxy Svc: Added orderByFields parameter to URL to ensure there are no duplicates in returned features

            ## Version 1.19.5 (2023-03-02)
            - #29239 Public Portal Settings: On additional layers checkbox deselection just hide the layer config instead of deleting

            ## Version 1.19.4
            - #29239 Manage Public Portal | Delete properly map layers if unselected
            - #29238 Additional Option to Toggle On Public Map Layers
            - #28979 Fixed Tenant Creation

            ## Version 1.19.3
            - #29228 Shift Detail Time Filter: Do not filter at all if filter is set to maximum range

            ## Version 1.19.2
            - #29197 Shift Playback | Fixed issues while changing speed
            - #29189 Fixed error occurred sometimes on Driver role removal

            ## Version 1.19.1
            - #29133 Shift Detail: Fixed Time Filter
            - #29134 Shift Playback timer always updating
            - #29136 Shift Detail: Adjustable Playback Speed

            ## Version 1.19.0
            - #28894 Cartegraph | Enable to reprocess sync task from Dashboard UI
            - #28884 Cartegraph | Admin | Allow cartegraph data to be deleted on Tenant Deactivate function
            - #28922 Cartegraph | Submit Labor log with Inspection task
            - #28874 Dashboard | Use ArcGIS Geocoding service instead of Google Search
            - #28925 PlowOps Service | Integration Tests infrastructure
            - #28687 Public Portal | Changed URL parameters, added bounds as default, added Public Map to Admin
            - #28978 Do not create 'admin&#64;' accounts and do not allow to change password or delete users 'neotreks_support&#64;'
            - #28976 Multiple Saved Route Configurations | Vehicle Category Selection
            - #28633 Cartegraph | Shift Details Submission - fixed sending of CgShape with same points
            - #28633 Cartegraph | Shift Details Submission - fixed sending duplicate field in request body
            - #28922 Cartegraph | Submit Labor log with Inspection task | rounding duration to the nearest upper minute
            - #28836 Cartegraph | better logging, detailed on error, otherwise mitigated log flooding
            - #28256 Cartegraph | Shift Details Submission | Support "Equipment (trucks and accessory pieces)" - fixed hours calculation
            - #29079 Manage Drivers | Promote to Supervisor
            - #29087 Public Portal | Removed Auto Applied Opacity
            - #29090 Public Portal | EditablePortal Title
            - #29088 Dashboard | Added Logo Management
            - #29089 Public Portal | Clickable logo
            - #29086 Public Portal | Added max bounds set to configured area + 50% on each side
            - #28694 Dashboard | Support for Disabling Guest Driver
            - #29097 Increased Address Lookup search radius to 30 meters
            - #29091 Public Portal | Fixed order of custom map layers - draw them between base map and vehicle tracks
            - #29098 Vehicle Category dialogs UI update
            - #29103 Settings | Manage Public Portal - UI refactoring
            - #29135 Live Map: Pull distance driven on vehicle marker click not only on shift card click
            - #29138 Breadcrumb Search: Popup shows on incorrect place because of incorrect rounding on Geobuf
            - #29137 Live Map | Fixed showing tracks for zoom levels > 18
            - #29130, #29132 Fixed formatting on Time Filter

            ## Version 1.18.4 (2023-02-08)
            - #29034 CloudWatch | Added monitoring of database locks
            - #29044 Admin: Added possibility to edit shift auto end value

            ## Version 1.18.3 (2023-02-02)
            - #28963 Fixed showing Customer ID in Admin Portal
            - #28945 Fixed handling 'Driver' role on user mgmt on Admin Portal
            - #28958 Do not create admin users for tenant with duplicate domain

            ## Version 1.18.2
            - #28922 Cartegraph | Submit Labor log with Inspection task
            - #27789 Import Vehicles - Import Equipment - updated for using additional equipment filter

            ## Version 1.18.1
            - #28835 Public Portal Settings | Support multiple headlines
            - #28879 Added Map Layers Preview
            - #28880 Public Portal - Changed Default Zoom Level for address search
            - #28878 Public Portal - Headline Scrolling Speed Adjustment

            ## Version 1.18.0
            - #28634 Cartegraph | Observation Sending - sending only "Non-Asset" observations
            - #28691 Cartegraph | fixed import to mark datasets in_use correctly
            - updated vehicle, vehicle group and equipment imports
            - #28633 Cartegraph | Shift Details Submission
            - material + equipment + labor log submitting
            - transactional sending: when any call fails PlowOps tries to delete created records in Cartegraph
            - added more detailed logging
            - #28531 Core | Material core feature added
            - and added indexes and foreign keys
            - #28530 Core | Equipment core feature added
            - and added indexes and foreign keys
            - #28836 Cartegraph | don't resend data if HTTP 500 returned
            - #28750 Added support for multiple route configurations
            - Data types optimization and location service refactoring
            - #28796 Fixed disappearing of tracks on switching Base Maps
            - #28686 Manage Routes | Add possibility to hide routes from the config
            - #28697 Public Portal - Added Address Search
            - #28806 Handle deleted observation type when showing observations
            - #28821 Currency Layer color change - 4 to 12 hours
            - #28767 Feature Proxy: Added explicit deletes for GDAL objects
            - #28871 Core | Equipment + Material | pdf report

            ## Version 1.17.1 (2023-01-19)
            - #28836 Cartegraph | don't resend data if HTTP 500 returned

            ## Version 1.17.0 (2023-01-04)
            - #28516/#25515 LMU Only Shifts | Adjust beginning and end of the shift according to actual location updates
            - #28590 Fixed Driver choose performance
            - #28514 Shift Playback | Added Actual Time
            - #28205 Upgraded Feature Proxy Service dependencies
            - #28596 Nested menus route selector
            - #28522 Toast messages moved above map controls
            - #28118 Date Filter refactoring and Vehicle Group Filter
            - #28646 Updated GPS track and map icon color
            - #28470 Cartegraph - fixed inspection report, showing n/a when odo/chrono-meter has value less than 0
            - #28470 Cartegraph - fixed question editing - deleting questions

            ## Version 1.16.1 (2022-12-14)
            - #28586 Unable to Add Drivers/Vehicles
            - #28600 When Cartegraph is disabled import drivers and vehicles shows import button

            ## Version 1.16.0
            - Location History endpoint refactoring
            - #28456 Removed Spatial Services
            - #28400 Added vehicle Out of Service option
            - #28422 Configurable Public Portal Basemap
            - #28435 Allow excluding vehicle groups on Public Portal
            - #28501 Send Stationary Alerts only to Admin and Portal Users
            - #28423 Added option to Delete Shifts
            - #28523 Route management: Added outSR=4326 parameter to get geometries with correct projection
            - #28429/#28430 Admin Statistics | Added footer rows for Active Shifts

            ## Version 1.15.3 (2022-12-08)
            - #28586 Unable to Add Drivers/Vehicles

            ## Version 1.15.2 (2022-12-01)
            - #28502 Fixed Address Lookup
            - #28503 Fixed creating Route Configuration

            ## Version 1.15.1 (2022-11-30)
            - #28473 Fixed serialization issue on Send Message

            ## Version 1.15.0
            - #25759 Fixed Plowing/Spreading filters on geojson layer
            - #27885 Cartegraph API Integration
            - #28160 Fixed missing last part of track after shift end
            - #28117 Stationary Alert - do not show observation marker on the live map
            - #28162 Switched from using plain GeoJson to GeoBuf to improve perf
            - #28198 Fixed Live Map Recent toggle value to be saved across user sessions
            - #28201, #28206, #28215 Upgraded services dependencies
            - Improved perf by non-blocking location submit
            - Improved perf by Map Matching Refactoring
            - #28149 PlowOps Service: Improve perf by enabling Jetty instead of Tomcat
            - #28149 Location Service: Improve perf by enabling Undertow instead of Tomcat
            - #28282 CalAmp Service: Resend failed location submissions later
            - Public Web: Host external libraries ourselves to be able to iframe it
            - #28231 Removed Map Center setting from tenant configuration
            - #28292 Removed support for Raster Routes
            - #28351 Removed deprecated API calls
            - #28403 Fixed Observations Count in Shift Report
            - #28443 Fixed wrong timestamps on location updates coming from LMU devices

            ## Version 1.14.3 (2022-11-15)
            - #28373 Updated Currency color scale and increased width of Currency/Coverage lines
            - #28413 Fixed listing Observations in Activity tab

            ## Version 1.14.2 (2022-11-11)
            - #28326 Live Map: Zoom to shift doesn't fit bounds entirely
            - #28165, #28221 Fixed incorrect linestring joining when flags used

            ## Version 1.14.1
            - #28134 Removed guest driver suffix
            - #28222 Public Portal: Added option for Scrollable Headline

            ## Version 1.14.0
            - #27169 Watches and Warnings Layer - Textual Pop-up
            - Improving track geometry endpoints generation and database cleanup
            - #27956 Fixed Zooming to shift extent right after the start of the shift
            - #28020 Fixed Road Status Popup
            - #27388, #27386 Added Shift Statistics - material used, separated liquid and granular miles driven
            - #27983 Metric units support
            - #28123 Tie Map Scale Bar to tenant metric config
            - #27985/#27984 Added Mowing/Sweeping categories
            - #28021 Common Flags Format: Fixed track filter on Currency/Coverage layers
            - #28134 Better error handling and validations on new Tenant form

            ## Version 1.13.1 (2022-09-19)
            - #27933 Shift Details Renders Incorrectly for Vehicle without Sensors
            - #27764 Dashboard | Recent Track Data Not Appearing When Basemap Switcher is Open
            - #27981 Public Web: Fixed serialization issue, Show only active shift vehicle markers
            - #27763 Change Stationary Alert Observation Map Label

            ## Version 1.13.0
            - #27533 Added 'auto' flag to Driver for automatically created records
            - #27646, #27647, #27648, #27649 Other Settings adjustments
            - Sensors to Flags refactoring
            - #27652 Added support for custom map layers on Public Portal
            - #27651 Map Layers Configuration manageable in Dashboard
            - Fixed Vehicle Import
            - #27639 Added PropagateTags: Service to ECS Service Definitions
            - #27544 Location History archive

            ## Version 1.12.2 (2022-08-01)
            - #27602 Fixed triggering Auto End Shift task on higher load
            - #27583 Administration: Added Customer ID

            ## Version 1.12.1 (2022-07-20)
            - #27531 Fixed Routes layer visibility
            - #27532 Default to "None" for Routes Displayed
            - #27537 Fixed null on shift event toast
            - #27535 Move down "None" Option for Tracks Legend
            - #27536 Weather Layers Toggle Icons
            - #27504 Feature Proxy Service: Added support for FeatureServer URL

            ## Version 1.12.0 (2022-07-18)
            - #27155 CalAmp Service: Parse sensor values based on vehicle config and send prox/spreader values to Location Service
            - #27170 Stationary Alert | Do Not Allow to Set Less Than 10 Minutes
            - #27180 Added No Longer Stationary observation
            - #27171 Live Map - Added Count for Active and Recent Vehicles
            - #27255 CalAmp Service: Add support for second accumulator - ADC-1
            - #27282 Refactoring on observations, fixed cache issue
            - #26823 Changed label for Input 5 for LMU 5530
            - #27316 Allow scrolling on adress detail visits, adjusted appearance
            - #27317 Address Look-up | Retain time filter
            - #27310 Vehicle Configuration | Ability to Set None as an Option for a Sensor
            - #27182 Observation Map Labels Optional
            - #24417 New Map Icon for Observations
            - #27380 Vehicle Configuration | Reversed Sensor Data defined per sensor
            - #27161, #27161 Weather Layers: Radar, Warnings

            ## Version 1.11.0
            - #24424 Added Info Bar
            - #26263 Shift detail page track style adjustment
            - #25595, #25597, #25598, #25604, #25605, #25606, #25608 Other UI adjustments on Live Map
            - #25504 Updated MapLibre library from 1.15 to 2.1
            - #26437 Vehicle and Driver Filtering
            - Added favicon for public portal
            - #26389 Auth Server: Added configurable favicon and window title
            - #22668 Auth Server: Lock account after 10 failed attempts to login
            - #26188 Vehicle Group: Added checkboxes for default states of buttons showing Other Vehicles/History/Observations
            - #26658 Vehicle: Added hasNoTablet, custom map color fields
            - #26634 Functional Info Button - Routes
            - #26731 Change Derived Layer Coverage Passes Number Distribution
            - #26707 Fixed Tile generation failure due to error during 'ls'
            - #26745, #26746, #26769, #26771, #26970, #26759 Updated Vehicle Configuration
            - #26755 Adjusted login screen
            - #26729 Removed plowops layer types from map layer switcher, adjusted labels and behavior without switching buttons
            - #26760 Remove all other Active Shift Vehicle Icons when a Specific Vehicle is Selected
            - #26594 Added CalAmp Service to support headless installations
            - #26791, #26792 Sensor Configuration - Live Map and Breadcrumb Info Changes
            - #26820 Added support for POI
            - #26599 Location Service: Fixed creating mbtiles data sources (multiple connection pools were created on parallel getConnection()
            - #24841 Stationary Vehicles: Marker changes its color
            - #27139 CalAmp Service: Added horizontal accuracy to accumulator's values

            ## Version 1.10.2 (2022-05-03)
            - #26688 Issue with Starting Shift when DVIR Form is Required - updated AWS client

            ## Version 1.10.1 (2022-04-26)
            - #25982 Tile cache generation monitoring

            ## Version 1.10.0
            - #26330 Scroll to selected activity
            - #26268 Case insensitive user login
            - #26039 All Observations deletable by Admin
            - #26436 Fixed vehicle list loading perf
            - #26396 Fixed missing tracks on base map switch
            - #26331 Shift Report: Default Latest Shift to Top
            - #25591 Automatically end shift
            - #25894 Admin Portal redesign
            - #25889 System Status page in Administration
            - #25890 Statistics page in Administration

            ## Version 1.9.0 (2022-04-06)
            - #25565 New Map Matching solution
            - #26246 Update Shift Stats and Thumbnails after cache update
            - #26040 Send Messages only to Active Shift Drivers
            - #23531 Added Popup on road status layers click
            - #25885 Added Spreader Status to shift card

            ## Version 1.8.0 (2022-03-23)
            - Added Route Management
            - Added new Auth Service
            - UI adjustments: #25332, #25330, #25328, #25409, #25324, #25325, #25327, #25335, #25336
            - #25333 Fixed special characters handling when creating new drivers
            - #25422 Fixed empty observation category when editing first Vehicle Group
            - #25331 Global stats cards: titles centered
            - #25408 Live Map Shift Info: show guest driver
            - #25476 Bulk Import | Vehicle import
            - #24037 Shift playback animation
            - #25512 Shift report as pdf: added total row
            - #25423 Show plow status on shift card
            - #22763 Enforce password policy
            - #25334 Fixed uniqueness validation on vehicles to be case insensitive
            - #25692 Fixed incorrect summaries on shift report for vehicle and driver counts
            - #25405 Removed clustering on vehicle and observation layers
            - #25789 Removed phone number as mandatory field
            - #25897 Fixed incorrect Max Speed reporting

            ## Version 1.7.4 (2022-02-03)
            - Added observation queries caching

            ## Version 1.7.3 (2022-01-19)
            - #25371, #25372, #25374 Add miles plowed and spread information to shift report and shift details

            ## Version 1.7.2
            - #25347 Health check alarms

            ## Version 1.7.1
            - #25249 Vehicle/Driver stats: load miles driven from shift data and fixed spinner

            ## Version 1.7.0
            - UI polishment and fixes: #25028, #25054, #25056, #25106, #25108, #25116
            - #25107 Fixed doubled recent shifts
            - #25098 Change map layer order: vehicle tracks should draw above derived layers
            - #25099 Make selected vehicle gps tracks visible when gps tracks/derived layers are turned off
            - #25110 On Recent Shift click zoom to the shift extent
            - #25055 Update miles driven on Active Shift card click
            - #25116 Observation Marker Window: Added link to view details on Activity tab
            - #25150 Do not show spinner on empty lists: drivers, vehicles, insights

            ## Version 1.6.3 (2022-01-18)
            - #25347 Health check alarms

            ## Version 1.6.2 (2021-11-24)
            - Added tooltips on activity, shift cards and time filter bar
            - Removed buggy gradual breadcrumbs update and used direct batch update
            - #25050 Changed ulimit nofile threshold
            - #25050 Location Service: updated dependencies

            ## Version 1.6.1 (2021-11-11)
            - #24940 Added time labels for Shift Detail page Time Filter
            - #24619 Added delete confirmation for Delete Message
            - #24960 Public Portal Message - use animation from right to left to display longer messages

            ## Version 1.6.0
            - #20478 Fixed public portal attribution and plowops logo
            - #20478 Public portal adjustments and server settings
            - #24960 Public Portal Message - added scrolling and tooltip
            - #24619 Changed Viewing Message Layout
            - #24675 New Password Reset feature
            - #24480 Added filter for Currency and Coverage tracks
            - #24940 Added Shift Detail page Time Filter
            - Fixed custom date filter on vehicles/drivers

            ## Version 1.5.1
            - Added Google Tag Manager
            - Fixed entitlement regression

            ## Version 1.5.0
            - Switched to Vector Tiles Generator scheduled task
            - #24481 Delayed gradual vehicle location update
            - #24421 Live Map controls adjustments
            - #24623 GPS Highlighted Tracks: style adjustments
            - #24670 Vehicle Selection Highlight
            - #24574 Adjusted GPS Track colour
            - #24458 Public Portal UI changes: currency layer, nice vehicle icons
            - #24774 Fixed GPS Track filtering
            - #24767 Assets: Show only active vehicle types chips
            - #24671 Changing Assets to Shifts

            ## Version 1.4.1 (2021-10-15)
            - Reverted "#24481 Delayed gradual vehicle location update"
            - #24703 Disabled Routes from map layer switcher

            ## Version 1.4.0 (2021-10-08)
            - Introduced the new dashboard UI
            - Introduced the new login screen
            - #24157 Added caching of shift overview maps to S3

            ## Version 1.3.0 (2021-08-23)
            - #20698 Automated Stress Testing
            - #20807 Switch from Google Maps to AccuTerra Map powered by MapLibre GL
            - #20367 Observation Types management
            - #20809 Link Vehicle Category to Observation Categories
            - #21000 Location History partitioning
            - #21001 TrackGeometryCache table partitioned
            - #20428 Public Portal Settings page
            - #20952 Added DVIR report as pageable table
            - #21027 Added Road Status layers: Coverage and Currency
            - #20819 Introduced Fleet Messaging

            ## Version 1.2.2 (2021-05-21)
            - #21059 Fixed gaps in Vehicle Tracks

            ## Version 1.2.1 (2021-04-16)
            - Changed gray color for history line
            - Fixed width for default line

            ## Version 1.2.0
            - #20125 Added Feature Flags support
            - #20163 Observation Types: Default set on tenant creation
            - Updated build and runtime docker images
            - #20087 Added support for Proximity and Spreader sensors
            - #20163 Observation Types: Default set on tenant creation
            - #20423 Added Stationary Alert Feature
            - #20731 Add PlowOps Tags to AWS

            ## Version 1.1.2 (2021-04-07)
            - #20681 Fixed table trackgeometryoverlaycachestatus grows too big

            ## Version 1.1.1
            - #20681 Fixed overlay cache generating for tiles older than 12 hours
            - #20688 Fixed breadcrumb search parameter
            - Fix shift report

            ## Version 1.1.0
            - #20426 Refactoring and fixes on Portal users management
            - #19478 Breadcrumbs (location history) endpoint for customers
            - #20540 Add User Phone Number
            - #20554 Increase performance of the shift report
            - #20555 Shift report date range picker improvements
            - #20557 Fixed Shift report "See Results" button alignment
            - #20559 Fixed DVIR report button misalignment and download report issue
            - #20566 Fix total values for the shift report
            - #20578 Configurable tracks layer delay, fixed location tenant config
            - #20593 Enabled brotli compression
            - #20605 Fixed Vehicle ID and Observation Not Showing when switching to Address Lookup and back
            - #20606 Fixed Driver Total Miles Driven not showing
            - #20607 Shift Report Table improvements
            - #20608 Shift Report Summary Row improvements
            - #20614 Added ability to set user role when creating a new user
            - #20617 Updated to latest JS libraries
            - #20618 Dashboard: Fixed Google Search to be bound by configuration properly

            ## Version 1.0.9 (2021-03-16)
            - #20108 Updated to latest Spring Boot, added Hazelcast as Shedlock backend, config cleanup
            - #20271 Events on one cluster node are pushed to other cluster nodes and their web sockets
            - #20109 Added first version of Public map
            - #20438 Add paging to shift card listing pages
            - #20110 Added caching for vector tiles
            - #20107 Identity Service: Added caching
            - #20536 Performance Improvement: Breadcrumb Cache

            ## Version 1.0.8 (2021-03-10)
            - #20085 LMU support for proximity sensor
            - #19806 Shift Report
            - #20121 Location Service: Improve unit test coverage
            - #20000 Initial version of API Documentation
            - #19387 Internal refactoring to plowops package

            ## Version 1.0.7 (2020-11-11)
            - #13039 Added User Account page with password change option
            - #13035 Added Password Reset page
            - #13038 Added User Management
            - #17350 Added Portal User role
            - #17351 Configurable DVIR accessibility
            - #17606 Added Dash Cam images report
            - #17632 Added images as POI into Live Map and Shift Detail page
            - Added configurable map layers
            - Fixed vehicle location update push for non-CR tenants

            ## Version 1.0.6 (2020-05-07)
            - #16378 Address Lookup: Restrict autocomplete google search to tenant region
            - #16377 Highlight icon of found address on mouse hover
            - #16379 Selecting Search result zooms the map
            - #16381 Address lookup detail should show passes, not shifts
            - #16396 European dates shown instead of US date format
            - #16388 Remove the 'detailed results/go to reports' card
            - #16389 UI adjustments on Shift card appearance
            - #16382 Address Lookup Detail: Hovering over the pass list will highlight the icon
            - #16380 Address Lookup: Adjusted 'Quick stats' circles
            - #16383 Address Lookup: Added Pass screen
            - #15175 Font color and size adjustments
            - #17312 Added map overlay controls to the driver and vehicle map views
            - #17313 Added GPS source to Shift detail screen

            ## Version 1.0.5 (2020-03-20)
            - #16791: Filter out inaccurate location records coming from mobile app

            ## Version 1.0.4 (2020-02-27)
            - #15099: Dashboard 'Address Lookup' Tab

            ## Version 1.0.3
            - #15137 View shift details of Vehicle Info page
            - #15132 Vehicle statistics panel
            - #15133 Shift Info Cards display
            - #15774 Add Plow information to DVIR header
            - #15791 Vehicle Mgmt: Fixed vehicle deletion - soft delete
            - #15912 Store gps source identifiers (lmu/android)
            - #15097 Drivers Tab: Added shift info pages
            - #16063 Driver Mgmt: Clear input when new driver is added

            ## Version 1.0.2 (2019-12-18)
            - #15098 Added Vehicles management
            - #15691 Fixed number picker on report page
            - Dashboard UI style cleanup and fixes
            - #15434 Tenant Management
            - #15669 Show clear message to user when no permissions to access Dashboard

            ## Version 1.0.1 (2019-12-02)
            - #15479 Adjusted PDF DVIR labels
            - #15455 Ability to close a shift from the portal
            - updated dashboard styles
            - #15632 Portal - gaps in breadcrumb track
            - #15650 - Show release notes on portal

            ## Version 1.0.0 (2019-07-11)
            Initial deploy
        </markdown>

    </div>

</div>
