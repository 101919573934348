<form [formGroup]="formGroup" class="w-100">
	<div class="flex-column gap-32">
		<app-card>
			<div *appCardContentRaw>
				<app-card-editable-heading
						formControlName="title"
						icon="arrow_back"
						[editable]="!loading"
						[iconDisabled]="saving || formGroup.invalid"
						(iconClicked)="buttonClickedCancel()">
				</app-card-editable-heading>

				@if (loading) {
					<mat-spinner class="my-16 mx-a" [diameter]="32"></mat-spinner>
				}
				@else if (loadError) {
					<div class="load-error">
						<mat-error>{{ loadError }}</mat-error>
					</div>
				}
				@else {
					<div class="m-16 mt-0">
						<h2>Basic Form Information</h2>
						<div class="indent">
							<app-row-with-select
									primaryText="Question format"
									secondaryText="Present one or multiple questions at a time"
									listLabel="label"
									[list]="DvirFormQuestionFormatOption.options"
									formControlName="questionFormat">
							</app-row-with-select>

							<div class="field-row align-items-center pt-16">
								<div class="field-row-title">
									<div>Modify form questions</div>
									<div class="whitespace-no-wrap">Add or remove inspection questions</div>
								</div>
								<div class="field-row-element">
									<button mat-stroked-button
											[disabled]="saving"
											(click)="editQuestions()">
										Edit Questions
									</button>
								</div>
							</div>

							<app-row-with-toggle primaryText="Include input box for additional remarks at the end of the form"
												 formControlName="hasRemarks">
							</app-row-with-toggle>

							<app-row-with-toggle primaryText="Signatures"
												 secondaryText="Decide who has to sign the inspection forms"
												 formControlName="requireSignatures">
							</app-row-with-toggle>

							@if(formGroup.value['requireSignatures']) {
								<div class="mt-8">
									<mat-radio-group
											class="form-radio-group"
											formControlName="reviewingSignatures">
										@for (option of DvirFormReviewingSignaturesOption.signatureOptions; track option.code) {
											<mat-radio-button class="form-radio-button" [value]="option">
												<div class="ml-25">
													<div>
														{{ option.label }}
													</div>
													<div class="secondary">
														{{ option.description }}
													</div>
												</div>
											</mat-radio-button>
										}
									</mat-radio-group>
								</div>
							}

							<div class="pt-16">
								<app-row-with-toggle primaryText="Notifications"
													 secondaryText="Get email notification for completed inspections forms"
													 formControlName="enableEmailNotifications">
								</app-row-with-toggle>
							</div>

							@if (formGroup.value['enableEmailNotifications']) {
								<div class="mt-8">
									<mat-radio-group
											class="form-radio-group mt-8"
											formControlName="emailNotificationType">
										<mat-radio-button class="form-radio-button" [value]="DvirFormEmailNotificationType.ON_OUT_OF_SERVICE">
											<div class="ml-25">
												<div>
													Level 1
												</div>
												<div class="secondary">
													If a vehicle is taken out of service
												</div>
											</div>
										</mat-radio-button>
										<mat-radio-button class="form-radio-button" [value]="DvirFormEmailNotificationType.ON_DEFECT">
											<div class="ml-25">
												<div>
													Level 2
												</div>
												<div class="secondary">
													If a defect is marked on an inspection or a vehicle is taken out of service
												</div>
											</div>
										</mat-radio-button>
										<mat-radio-button class="form-radio-button" [value]="DvirFormEmailNotificationType.ALL">
											<div class="ml-25">
												<div>
													Level 3
												</div>
												<div class="secondary">
													All completed inspection forms
												</div>
											</div>
										</mat-radio-button>
									</mat-radio-group>
								</div>

								@if (notificationUsers) {
									<div class="form-radio-button py-16">
										<div>
											Recipients:
										</div>
										@if (notificationUsers.length) {
											<div class="mt-8">
												@for (user of notificationUsers; track user.email) {
													<div>
														<mat-checkbox [checked]="user.selected"
																	  (change)="notificationUsersChanged(user, $event)">
															{{ user.email }}
														</mat-checkbox>
													</div>
												}
											</div>
										}
										@if (formGroup.hasError('missingRecipient')) {
											<mat-error>
												Choose at least one recipient.
											</mat-error>
										}
									</div>
								}
							}
						</div>

						<h2 class="pt-16">Inspection Form Actions</h2>
						<div class="flex-row">
							<div class="flex-grow-1"></div>
							<div class="flex-row gap-8">
								<button mat-stroked-button
										[disabled]="!inspectionForm?.id"
										(click)="duplicateForm()">
									Duplicate Inspection Form
								</button>
								<button mat-stroked-button
										[disabled]="!inspectionForm?.id"
										(click)="deleteForm()">
									Delete Inspection Form
								</button>
							</div>
						</div>
					</div>
				}
			</div>
		</app-card>
	</div>
</form>
