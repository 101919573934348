<div class="drawer-container" *ngIf="isDisplayed" role="complementary">
    <div class="drawer-header">
        <div class="drawer-header__left">
            <span>
                {{ selectedDrawer.label }}
            </span>
        </div>
        <div class="drawer-header__right">
            <button mat-icon-button (click)="hideDrawer()" aria-label="Hide right panel">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <div [ngSwitch]="drawer" class="drawer-body">
        <ng-container *ngSwitchCase="DrawerContent.ROUTE">
            <app-route-assignment></app-route-assignment>
        </ng-container>
        <ng-container *ngSwitchCase="DrawerContent.MESSAGES">
            <app-messages></app-messages>
        </ng-container>
        <ng-container *ngSwitchCase="DrawerContent.OBSERVATION">
            <app-observations></app-observations>
        </ng-container>
        <ng-container *ngSwitchCase="DrawerContent.LIVE_VIDEO">
          <app-live-video></app-live-video>
        </ng-container>
        <ng-container *ngSwitchCase="DrawerContent.ALERTS">
            <app-alerts></app-alerts>
        </ng-container>
        <ng-container *ngSwitchCase="DrawerContent.QUICK_INSIGHTS">
            <app-quick-insights></app-quick-insights>
        </ng-container>
    </div>
</div>

