<div class="mb-spacing">
    <div class="indent">
        <form>
            @if (observationTypeGroups && observationTypeGroups.length > 0) {
                @for (typeGroup of observationTypeGroups; track typeGroup.group.id) {
                    <ng-container *ngTemplateOutlet="groupTpl;context:{typeGroup: typeGroup}"></ng-container>
                }
            } @else {
                No observation groups defined yet.
            }

        </form>
    </div>
</div>

<ng-template #groupTpl let-typeGroup="typeGroup">
    <app-row-with-toggle
            [primaryText]="typeGroup.group.name"
            [(checked)]="typeGroup.excluded"
            (checkedChange)="updateObservations()"
    >
    </app-row-with-toggle>

    <mat-chip-set aria-label="Fish selection" class="ml-25">
        @for (type of typeGroup.group.observationTypes; track type.title) {
            <mat-chip>{{ type.title }}</mat-chip>
        }
    </mat-chip-set>

</ng-template>
