import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import * as Sentry from '@sentry/angular';
import {MaterialModule} from './shared/material/material.module';
import {AppRoutingModule} from './app-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppComponent} from './app.component';
import {StoreModule} from '@ngrx/store';
import {SharedModule} from './shared/shared.module';
import {DashboardLayoutComponent} from './layouts/dashboard-layout/dashboard-layout.component';
import {LiveMapModule} from './pages/live-map/live-map.module';
import {InsightsModule} from './pages/insights/insights.module';
import {SettingsModule} from './pages/settings/settings.module';
import {HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi} from '@angular/common/http';
import {liveMapReducer} from './pages/live-map/store/live-map.reducer';
import {Router, RouteReuseStrategy} from '@angular/router';
import {CustomRouteReuseStrategy} from './router-strategy';
import {environment} from '../environments/environment';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RightDrawerComponent} from './layouts/right-drawer/right-drawer.component';
import {ShiftMapModule} from './pages/shift-map/shift-map.module';
import {AmplifyAuthenticatorModule} from '@aws-amplify/ui-angular';
import {Amplify} from 'aws-amplify';
import {AuthInterceptor} from './security/auth.interceptor';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';

const initializeApp = () => {
  return async (): Promise<any> => {
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolEndpoint: !!environment.authentication.endpoint ? environment.authentication.endpoint : undefined,

          // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
          // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

          // REQUIRED - Amazon Cognito Region
          // region: 'us-east-1',

          // OPTIONAL - Amazon Cognito Federated Identity Pool Region
          // Required only if it's different from Amazon Cognito Region
          // identityPoolRegion: 'XX-XXXX-X',

          // OPTIONAL - Amazon Cognito User Pool ID
          userPoolId: environment.authentication.userPoolId,

          // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
          // userPoolWebClientId: environment.authentication.clientId,
          userPoolClientId: environment.authentication.clientId,

          // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
          // mandatorySignIn: false,

          // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
          // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
          signUpVerificationMethod: 'code', // 'code' | 'link'

          // // OPTIONAL - Configuration for cookie storage
          // // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
          // cookieStorage: {
          //   // REQUIRED - Cookie domain (only required if cookieStorage is provided)
          //   domain: '.yourdomain.com',
          //   // OPTIONAL - Cookie path
          //   path: '/',
          //   // OPTIONAL - Cookie expiration in days
          //   expires: 365,
          //   // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
          //   sameSite: 'strict' | 'lax',
          //   // OPTIONAL - Cookie secure flag
          //   // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
          //   secure: true,
          // },
          //
          // // OPTIONAL - customized storage object
          // storage: MyStorage,

          // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH', other option: 'USER_PASSWORD_AUTH'
          // authenticationFlowType: 'USER_SRP_AUTH',

          // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
          // clientMetadata: {myCustomKey: 'myCustomValue'},

          // // OPTIONAL - Hosted UI configuration
          // oauth: {
          //   domain: 'your_cognito_domain',
          //   scope: [
          //     'phone',
          //     'email',
          //     'profile',
          //     'openid',
          //     'aws.cognito.signin.user.admin',
          //   ],
          //   redirectSignIn: 'http://localhost:3000/',
          //   redirectSignOut: 'http://localhost:3000/',
          //   responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
          // },
        },
      },
    });
  };
};

@NgModule({
  declarations: [
    AppComponent,
    DashboardLayoutComponent,
    RightDrawerComponent,
  ],
  imports: [
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    LiveMapModule,
    SettingsModule,
    InsightsModule,
    ShiftMapModule,
    StoreModule.forRoot({ ui: liveMapReducer }),
    AmplifyAuthenticatorModule,
  ],
  providers: [
    provideHttpClient(
      withInterceptorsFromDi(),
      withFetch(),
    ),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [Sentry.TraceService],
      multi: true
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {appearance: 'outline'}
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
    {
      provide: 'googleTagManagerId',
      useValue: 'GTM-5SBHCNH'
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
