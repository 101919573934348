export enum RootRoute {
    MAIN = 'main',
    SETTINGS = 'settings'
}

export enum MainRoute {
    VEHICLE = 'vehicle',
    ROUTE = 'route',
    ADDRESS = 'address',
    INSIGHTS = 'insights',
    ABOUT = 'about',
}

export enum SettingsRoute {
    MANAGE_USERS = 'manage-users',
    MANAGE_VEHICLES = 'manage-vehicles',
    MANAGE_OBSERVATIONS = 'manage-observations',
    MANAGE_ROUTES = 'manage-routes',
    MANAGE_PUBLIC_PORTAL = 'manage-public-portal',
    VEHICLE_INSTALLATIONS = 'vehicle-installation',
    INSPECTION_FORMS = 'inspection-forms',
    CARTEGRAPH = 'cartegraph-settings',
    MY_ACCOUNT = 'my-account',
}

