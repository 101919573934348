<div class="row-with-toggle" (click)="handleChange(!checked)">
	@if (primaryText?.length) {
		<div class="field-row-title">
			{{ primaryText }}
			@if (secondaryText?.length) {
				<div class="field-row-subtitle">
					{{ secondaryText }}
				</div>
			}
		</div>
	}
    <div class="field-row-element">
        <mat-slide-toggle [hideIcon]="true" [checked]="checked" [disabled]="disabled">
        </mat-slide-toggle>
    </div>
</div>
@if(children?.length > 0) {
	<div class="indent children"
		 [@collapse]="checked">
		@for (child of children; track child.id) {
			<app-row-with-toggle [primaryText]="child.name" [checked]="selectedChildIds[child.id]" (checkedChange)="handleChildrenChange(child)"></app-row-with-toggle>
		}
	</div>
}
