import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import {MatButton} from '@angular/material/button';
import {MatError, MatFormField} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';


@Component({
  standalone: true,
  templateUrl: './cartegraph-settings-notifications-add.component.html',
  styleUrls: ['../../../settings-fields.scss',
    '../../../settings-common.scss'
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatDialogTitle,
    MatDialogContent,
    MatButton,
    MatDialogActions,
    MatDialogClose,
    MatFormField,
    MatError,
    MatInput
  ]
})
export class CartegraphNotificationsAddDialogComponent implements OnInit {

  form!: FormGroup;
  uiError: string;
  actionType: string;

  constructor(public dialogRef: MatDialogRef<CartegraphNotificationsAddDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {
                description: string,
                itemType: string,
                value: string
              },
              private readonly formBuilder: FormBuilder) {
  }

  ngOnInit(): void {

    this.actionType = (!!this.data.value) ? 'Edit' : 'Add';

    // todo: add validator for emails if emails entering
    const validators = [Validators.required];
    if (this.data.itemType === 'email') {
      validators.push(Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"));
    }
    const field = new FormControl(
      {
        value: this.data.value,
        disabled: false
      },
      validators);

    this.form = this.formBuilder.group({
      field: field,
    });
  }

  addField() {
    this.dialogRef.close(this.form.controls['field'].value);
  }
}


