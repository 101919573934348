<h2 mat-dialog-title>Import users</h2>
<mat-dialog-content>
	<mat-vertical-stepper linear #stepper>
		<mat-step label="Upload CSV File" optional="false" editable="false">
			<input hidden type="file"
				   id="file"
				   accept=".csv"
                   #fileInput
				   (change)="handleFileInput($event)">

			<div *ngIf="currentState === ImportUsersStateEnum.WAITING_FOR_FILE">
				<div *ngIf="!showCsvHelp" class="mt-8">
					<p>
						Choose a CSV file in a
						<span class="link" (click)="showCsvHelp=true">supported format</span>.
					</p>
					<button mat-stroked-button
							color="accent" (click)="fileInput.click()">
						Choose and upload CSV file
					</button>
				</div>

				<mat-card *ngIf="showCsvHelp">
					<mat-card-header>
						<mat-card-title>Sample CSV</mat-card-title>
					</mat-card-header>
					<mat-card-content>
						<p>
							Role is one of: admin or supervisor or driver.<br>
							email, phoneNumber are optional.
						</p>
						<div style="overflow: auto; font-family: monospace; white-space: nowrap">
							role, familyName, givenName, email, phoneNumber<br />
							admin, Doe, John, johndoe&#64;email.com, 000111222<br />
							…<br />
							…
						</div>
					</mat-card-content>
					<mat-card-actions align="end">
						<button mat-stroked-button (click)="showCsvHelp=false">Close</button>
					</mat-card-actions>
				</mat-card>
			</div>
			<div *ngIf="currentState === ImportUsersStateEnum.UPLOADING_FILE">
				<p>Pre-processing the uploaded file.</p>
				<mat-progress-bar mode="indeterminate"></mat-progress-bar>
			</div>
			<div *ngIf="currentState === ImportUsersStateEnum.UPLOAD_FAILED" class="ui-error">
				<p>{{ uiError }}</p>
			</div>
		</mat-step>

		<mat-step label="Review Data" editable="false">
			<p *ngIf="(!validData || validData.length === 0) && (!invalidData || invalidData.length === 0)">
				No data to review
			</p>

			<div *ngIf="validData && validData.length > 0" class="mt-15">
				<h2>Users To Import</h2>
				<div style="overflow-x: auto">
					<ng-container
							*ngTemplateOutlet="table;context:{data: validData,displayedColumns:displayedColumnsValid}">
					</ng-container>
				</div>
			</div>

			<div *ngIf="invalidData && invalidData.length > 0" class="mt-15">
				<h2>Not Imported Users</h2>
				<div style="overflow-x: auto">
					<ng-container
							*ngTemplateOutlet="table;context:{data: invalidData,displayedColumns:displayedColumnsInvalid}">
					</ng-container>
				</div>
			</div>

			@if (!hasSomeValidRowsToImport()) {
				<div class="mt-25">
					No users to import
				</div>
			}
		</mat-step>

		<mat-step label="Import Result">
			@switch (currentState) {
				@case (ImportUsersStateEnum.IMPORTING) {
					<mat-progress-bar mode="indeterminate"></mat-progress-bar>
				}
				@case (ImportUsersStateEnum.IMPORT_FAILED) {
					<div class="ui-error mt-15">
						<p>{{ uiError }}</p>
					</div>
				}
				@case (ImportUsersStateEnum.IMPORT_SUCCEEDED) {
					<div class="mt-15">
						<p>Imported {{numberOfImportedUsers}} users.</p>
					</div>
				}
			}
		</mat-step>
	</mat-vertical-stepper>

	<ng-template #table let-data="data" let-displayedColumns="displayedColumns">
		<table *ngIf="data && data.length > 0 && displayedColumns" mat-table
			   [dataSource]="data">

			<ng-container matColumnDef="selected">
				<th mat-header-cell *matHeaderCellDef></th>
				<td mat-cell *matCellDef="let element">
					<mat-checkbox [(ngModel)]="element.selected"></mat-checkbox>
				</td>
			</ng-container>

			<ng-container matColumnDef="line">
				<th mat-header-cell *matHeaderCellDef> Line</th>
				<td mat-cell *matCellDef="let element">{{element.index}}</td>
			</ng-container>

			<ng-container matColumnDef="email">
				<th mat-header-cell *matHeaderCellDef> Email</th>
				<td mat-cell *matCellDef="let element">{{element.row.email}} </td>
			</ng-container>

			<ng-container matColumnDef="givenName">
				<th mat-header-cell *matHeaderCellDef> Given name</th>
				<td mat-cell *matCellDef="let element">
					{{element.row?.givenName}}
					<del *ngIf="element.dbData.user?.givenName !== element.row?.givenName">{{element.dbData.user?.givenName}}</del>
				</td>
			</ng-container>

			<ng-container matColumnDef="familyName">
				<th mat-header-cell *matHeaderCellDef> Family name</th>
				<td mat-cell *matCellDef="let element">
					{{element.row?.familyName}}
					<del *ngIf="element.dbData.user?.familyName !== element.row?.familyName">{{element.dbData.user?.familyName}}</del>
				</td>
			</ng-container>

			<ng-container matColumnDef="phoneNumber">
				<th mat-header-cell *matHeaderCellDef> Phone number</th>
				<td mat-cell *matCellDef="let element">
					{{element.row?.phoneNumber}}
					<del *ngIf="element.dbData.user?.phoneNumber !== element.row?.phoneNumber">{{element.dbData.user?.phoneNumber}}</del>
				</td>
			</ng-container>

			<ng-container matColumnDef="role">
				<th mat-header-cell *matHeaderCellDef>Role</th>
				<td mat-cell *matCellDef="let element">
					{{element.row?.role | lowercase}}
				</td>
			</ng-container>

			<ng-container matColumnDef="action">
				<th mat-header-cell *matHeaderCellDef> Action</th>
				<td mat-cell *matCellDef="let element">
					<mat-icon color="accent" [title]="element.action">
						@switch (element.action) {
							@case (CsvDataLoaderAction.CREATE) {person_add}
							@case (CsvDataLoaderAction.UPDATE) {sync}
						}
					</mat-icon>
				</td>
			</ng-container>

			<ng-container matColumnDef="errors">
				<th mat-header-cell *matHeaderCellDef> Reason</th>
				<td mat-cell *matCellDef="let element"
					class="no-wrap">
					<div *ngFor="let error of element.errors">{{error}}</div>
					@if (element.action === CsvDataLoaderAction.NONE) {
						no changes
					}
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		</table>
	</ng-template>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-stroked-button
			[mat-dialog-close]="currentState === ImportUsersStateEnum.IMPORT_SUCCEEDED">
		@if (currentState === ImportUsersStateEnum.IMPORT_SUCCEEDED) {
			Close dialog
		} @else {
			Cancel
		}
	</button>
	@if (currentState !== ImportUsersStateEnum.IMPORT_SUCCEEDED) {
		<button mat-flat-button color="primary"
				[disabled]="!hasSomeValidRowsToImport()"
				(click)="doImport()">Import Selected Users</button>
	}
</mat-dialog-actions>

