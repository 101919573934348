import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {CartegraphConfiguration, CartegraphSyncTask} from '../../../../../shared/models/cartegraph.model';
import {CartegraphManagementService} from '../../../../../data/cartegraph/cartegraph-management.service';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort, Sort, SortDirection} from '@angular/material/sort';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ToastService} from '../../../../../shared/services/toast.service';
import {environment} from '../../../../../../environments/environment';
import {CgTaskStatusUpdateDialog} from './cartegraph-tasks-status.copmponent';
import {
  FilterBarSearchEvent
} from '../../../../../shared/components/filter-bar-composite/composite-filter-bar.component';
import {MainRoute, RootRoute} from '../../../../../shared/models/angular-routing';
import {InsightsRoute} from '../../../../insights/insights-routing.module';
import {firstValueFrom} from 'rxjs';

@Component({
  selector: 'app-manage-cartegraph-tasks',
  templateUrl: './cartegraph-tasks.component.html',
  styleUrls: ['./cartegraph-tasks.component.scss',
    '../../../settings-fields.scss',
    '../../../settings-common.scss'
  ],
})
export class CartegraphTasksComponent implements OnInit, AfterViewInit {

  configuration: CartegraphConfiguration;

  isLoading = false;
  uiError: string;

  displayedColumns: string[] = [
    'id', 'taskType', 'created', 'shift', 'cgIdField', 'action'];
  processingStatuses: { id: string, label: string }[] = [
    {id: 'WAITING', label: 'Wait'},
    {id: 'SUCCESS', label: 'Success'},
    {id: 'FAIL', label: 'Fail'},
    {id: 'NONE', label: 'None'}];
  processingStatus: string = this.processingStatusValue(); // default to all
  tasks: MatTableDataSource<CartegraphSyncTask> = new MatTableDataSource<CartegraphSyncTask>([]);

  sortActive = 'created';
  sortDirection: SortDirection = 'desc';

  // used in *ngIf, requires to be loaded dynamically
  @ViewChild(MatSort, {static: false}) set sorter(sort: MatSort) {
    if (sort) {
      this.tasks.sort = sort;
    }
  }

  // used out of *ngIf can be injected
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  itemCount = 0;
  pageSize = 20;
  pageIndex = 0;
  searchText: string;

  constructor(
    private cartegraphManagementService: CartegraphManagementService,
    private dialog: MatDialog,
    private toast: ToastService,
  ) {
    this.tasks.paginator = this.paginator;
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {
    this.cartegraphManagementService.getConfiguration().then(response => {
      if (response.error) {
        this.uiError = response.error;
      } else {
        this.configuration = response.data ? response.data : new CartegraphConfiguration();
      }
    }).catch((error) => {
      console.log(error);
      this.uiError = error;
      this.isLoading = false;
    }).finally(() => {
      this.isLoading = false;
      this.getTasks();
    });

  }

  getTasks(page: number = 0) {
    this.isLoading = true;
    this.uiError = null;
    const sortField = this.tasks?.sort?.active ? `${this.tasks?.sort?.active},${this.tasks?.sort?.direction}`
      : `${this.sortActive},${this.sortDirection}`;

    firstValueFrom(this.cartegraphManagementService
      .getTaskList(page, this.pageSize, this.processingStatus, this.searchText, sortField)
    ).then(response => {
      if (response.error) {
        this.uiError = response.error;
      } else {
        this.tasks.data = response.data.content;
        this.pageSize = response.data.size;
        this.pageIndex = response.data.number;
        this.itemCount = response.data.totalElements;
      }
    }).catch(error => {
      console.log(error);
      this.uiError = error;
    }).finally(() => {
      this.isLoading = false;
    });
  }

  reprocessSyncTask(syncTask: CartegraphSyncTask) {
    firstValueFrom(this.cartegraphManagementService.updateSyncTask(syncTask.id, 'reprocess')
    ).then(response => {
      this.getTasks();
      this.toast.long(response.data);
    })
      .catch(error => {
        console.log(error);
        this.uiError = error;
      });
  }

  onPage(pageEvent: PageEvent) {
    // decide to reset sorting here
    this.getTasks(pageEvent.pageIndex);
  }

  cgDetailLink(cgOid: string) {
    // this must be based on OID not cgField
    return `${this.configuration.baseUrl}/#FlexUI/Edit/cgTasksClass/${cgOid}`
  }

  shiftLink(shiftId: number): string[] {
    return [environment.base_href, RootRoute.MAIN, MainRoute.INSIGHTS, InsightsRoute.SHIFT_REPORT, InsightsRoute.SHIFT_DETAIL, `${shiftId}`];
  }

  doSearch(searchEvent: FilterBarSearchEvent[]) {
    if (!!searchEvent) {
      this.processingStatus = searchEvent.find(e => e.id === 'status')?.search;
      this.searchText = searchEvent.find(e => e.id === 'search')?.search;
    } else {
      this.searchText = undefined;
      this.processingStatus = this.processingStatusValue();
      this.pageIndex = 0;
    }
    this.getTasks(this.pageIndex);
  }

  sortChange(sort: Sort) {
    this.sortActive = sort.active;
    this.sortDirection = sort.direction;
    this.getTasks();
  }

  messageDetail(task: CartegraphSyncTask) {
    if (task.processMessage?.trim().length > 0) {
      this.dialog.open(CartegraphTasksDetailDialogComponent, {
        width: '800px',
        height: '800px',
        data: task,
      });
    }
  }

  updateStatus(task: CartegraphSyncTask) {
    const dialogRef = this.dialog.open(CgTaskStatusUpdateDialog, {
      data: Object.assign({}, task),
    });
    dialogRef.afterClosed().subscribe(result => {
      if (!!result) {
        firstValueFrom(this.cartegraphManagementService.updateSyncTask(result.id, 'update', result)
        ).then(response => {
          this.getTasks();
          this.toast.long(response.data);
        })
          .catch(error => {
            console.log(error);
            this.uiError = error;
          });
      }
    });
  }

  private processingStatusValue(): string {
    return this.processingStatuses.map(s => s.id).join(',');
  }

}

@Component({
  selector: 'app-manage-cartegraph-task-detail',
  template: `<h1 mat-dialog-title>Processing detail</h1>
  <mat-dialog-content class="dialog">
      <div>ID: {{ data.id }}</div>
      <div>Shift ID: {{ data.shiftId }}</div>
      <div>Created: {{ data.created | date: 'medium' }}</div>
      <div>Type: {{ data.taskType }}</div>
      <div>Retry attempt count: {{ data.retryCount }}</div>
      <h2>Stacktrace</h2>
      {{ data.processMessage }}
  </mat-dialog-content>
  <mat-dialog-actions>
      <button mat-button mat-dialog-close color="accent">Close</button>
  </mat-dialog-actions>`
})
export class CartegraphTasksDetailDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<CartegraphTasksDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CartegraphSyncTask,
  ) {
  }
}
