import {EventEmitter, Injectable, Input, OnInit, Output} from '@angular/core';
import {
  CartegraphShiftTaskDefault,
  CartegraphTaskDefault,
  CgDatasetEntry
} from '../../../../../shared/models/cartegraph.model';
import {CartegraphManagementService} from '../../../../../data/cartegraph/cartegraph-management.service';


@Injectable() // injectable to be able to inherit a component from it
export abstract class CartegraphSettingsDefaults implements OnInit {

  isLoading = true;
  uiError: string;

  datasetPriority: CgDatasetEntry[];
  datasetStatus: CgDatasetEntry[];
  datasetActivity: CgDatasetEntry[];
  datasetWorkCompletedFor: CgDatasetEntry[];
  datasetWorkOrder: CgDatasetEntry[];
  datasetDepartment: CgDatasetEntry[];

  protected constructor(
    protected cartegraphManagementService: CartegraphManagementService) {
  }

  protected abstract initForm();

  ngOnInit(): void {
    this.loadDatasets().then(() => {
      this.initForm();
    }).finally(() => {
      this.isLoading = false;
    });
  }

  private loadDatasets() {
    return new Promise<void>((resolve) => {
      this.cartegraphManagementService.getDatasetList([
        {dataset: 'cgStatusClass', field: 'StatusField'},
        {dataset: 'cgPrioritiesClass', field: 'PriorityField'},
        {dataset: 'cgActivitiesClass', field: 'ActivityField'},
        {dataset: 'WorkCompletedForClass', field: 'WorkCompletedForField'},
        {dataset: 'cgWorkOrdersClass#WORK_ORDER_FOR_SEASON', field: 'IDField', descriptionField: 'DescriptionField'},
        {dataset: 'cgDepartmentsClass', field: 'DepartmentField'}
      ]).then(response => {
        this.datasetPriority = response.data.find(datasetList => datasetList.name === 'cgPrioritiesClass')?.list;
        this.datasetStatus = response.data.find(datasetList => datasetList.name === 'cgStatusClass')?.list;
        this.datasetActivity = response.data.find(datasetList => datasetList.name === 'cgActivitiesClass')?.list;
        this.datasetWorkCompletedFor = response.data.find(datasetList => datasetList.name === 'WorkCompletedForClass')?.list;
        this.datasetWorkOrder = response.data.find(datasetList => datasetList.name === 'cgWorkOrdersClass#WORK_ORDER_FOR_SEASON')?.list?.map(i => i);
        this.datasetDepartment = response.data.find(datasetList => datasetList.name === 'cgDepartmentsClass')?.list;
      }).catch(e => {
        this.uiError = e.error;
      })
        .finally(() => {
          this.isLoading = false;
          resolve();
        });
    });
  }


}
