import {Injectable} from '@angular/core';
import {Subscription} from 'rxjs';
import {MapLayersManager} from '../map-layers-manager';
import {GeoJSONSourceSpecification} from 'maplibre-gl';
import {SettingsService, ActivityFilter} from '../../../../configuration/settings.service';
import {ServerEventService} from '../../../../data/server-events/server-event.service';
import { LocationApiService } from '../../../../data/location-api/location-api.service';

@Injectable({
  providedIn: 'root'
})
export class RoadStatusService {

  static readonly ROAD_SEGMENTS_SOURCE_ID = 'road-segments';
  private mapLayersManager: MapLayersManager;
  private isEnabled: boolean;
  layerFilter: ActivityFilter = ActivityFilter.NONE;
  readonly trackLayerFilterToFlagMask = new Map<ActivityFilter, number>([
      [ActivityFilter.NONE, null],
      [ActivityFilter.PLOWING, 31], // 2^0 + 2^1 + 2^2 + 2^3 + 2^4
      [ActivityFilter.LIQUID_SPREADING, 31744],
      [ActivityFilter.GRANULAR_SPREADING, 992],
      [ActivityFilter.SPREADING, 32736],
      [ActivityFilter.MOWING, 1015808],
      [ActivityFilter.SWEEPING, 32505856],
      [ActivityFilter.PLOWING_MOWING_SWEEPING, 65011743],
  ]);

  private readonly openSubscriptions = Array<Subscription>();

  constructor(private settingsService: SettingsService,
              private serverEventService: ServerEventService,
              private locationApi: LocationApiService,
  ) { }

  init(mapLayersManager: MapLayersManager, isEnabled: boolean) {
    if (!!this.mapLayersManager) {
      throw Error('The map layers manager has already been set.');
    }
    this.mapLayersManager = mapLayersManager;
    this.isEnabled = isEnabled;
    this.addSource();

    if (this.isEnabled) {
      this.connectToManager();
    }
  }

  release() {
    if (!this.mapLayersManager) {
      throw Error('The map has not been set!');
    }

    for (const subscription of this.openSubscriptions) {
      subscription.unsubscribe();
    }
    this.openSubscriptions.length = 0;
    this.mapLayersManager = null;
  }

  connectToManager() {
    const that = this;

    // handle refresh on derivedLayerCache update
    const cacheChangeSubscription = this.serverEventService.derivedLayerCacheUpdateObservable.subscribe({
      next(/*cacheUpdate*/) {
        that.handleSourceRefresh();
      }
    });
    this.openSubscriptions.push(cacheChangeSubscription);

    const changesSubscription = this.settingsService.settingsChangedObservable.subscribe({
      next(newSettings) {
        if (newSettings.key === SettingsService.ACTIVITY_FILTER) {
          that.layerFilter = newSettings.value;
          that.handleSourceRefresh();
        }
      }
    });
    this.openSubscriptions.push(changesSubscription);
  }

  getRoadSegmentFeatures() {
    const flagMask = this.trackLayerFilterToFlagMask.get(this.layerFilter);
    return this.locationApi.getRoadSegments(flagMask);
  }

  addSource() {
    this.mapLayersManager.addSource(RoadStatusService.ROAD_SEGMENTS_SOURCE_ID, this.getEmptySource());
    if (this.isEnabled) {
      this.updateSource();
    }
  }

  private getEmptySource(): GeoJSONSourceSpecification {
    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    } as GeoJSONSourceSpecification;
  }

  private updateSource() {
    this.getRoadSegmentFeatures().then(response => {
      this.mapLayersManager
        ?.getGeoJsonSource(RoadStatusService.ROAD_SEGMENTS_SOURCE_ID)
        ?.setData(response);
    });
  }

  private handleSourceRefresh() {
    this.updateSource();
  }
}
