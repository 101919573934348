<div class="legend">
    <div class="legend-header">
        <div>
            Route Status
        </div>
        <button mat-icon-button
                class="configure-button"
                (click)="openConfiguration()"
                matTooltip="Configure Route Status Map"
        >
            <mat-icon>settings</mat-icon>
        </button>
    </div>
    <div class="legend-subtitle">
        <span>Service status of routes</span>
    </div>
    <div class="legend-line">
        <div class="legend-item__wrapper">
            <!--<div class="legend-item__box"
                 [ngStyle]="{ background: MapStyles.ROUTE_STATUS_SERVICED }"
            >
            </div>-->
            <app-line-dash-array-view
                [dashArray]="undefined"
                [color]="MapStyles.ROUTE_STATUS_SERVICED"
            ></app-line-dash-array-view>

            <div class="legend-item__name">
                Serviced
            </div>
        </div>
        <div class="legend-item__wrapper">
            <!--<div class="legend-item__box"
                 [ngStyle]="{ background: MapStyles.ROUTE_STATUS_INPROGRESS }"
            >
            </div>-->
            <app-line-dash-array-view
                    [dashArray]="undefined"
                    [color]="MapStyles.ROUTE_STATUS_INPROGRESS"
            ></app-line-dash-array-view>

            <div class="legend-item__name">
                In Progress
            </div>
        </div>
        <div class="legend-item__wrapper">
            <!--<div class="legend-item__box"
                 [ngStyle]="{ background: MapStyles.ROUTE_STATUS_NOTSERVICED }"
            >
            </div>-->
            <app-line-dash-array-view
                    [dashArray]="undefined"
                    [color]="MapStyles.ROUTE_STATUS_NOTSERVICED"
            ></app-line-dash-array-view>

            <div class="legend-item__name">
                Not Serviced
            </div>
        </div>
    </div>
</div>
