import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  ConfigurationModel,
  ContentBlock,
  Headline,
  PublicPortalSettings,
  RouteStatusLayerConfig,
} from '../../../../shared/models/configuration.model';
import {Subscription} from 'rxjs';
import {ConfigurationService} from '../../../../configuration/configuration.service';
import {VehiclesService} from '../../../../data/vehicles/vehicles.service';
import {DomSanitizer} from '@angular/platform-browser';
import {ToastService} from '../../../../shared/services/toast.service';
import {MatDialog} from '@angular/material/dialog';
import {environment} from '../../../../../environments/environment';
import {BaseMapType} from '../../../../configuration/settings.service';
import {AdditionalMapLayerManager} from './additional-map-layer-manager';
import {CurrentEditing} from '../../../../shared/tools/CurrentEditing';
import {MapLayerConfiguration} from '../../../../shared/models/map-layer.model';
import {RoutesService} from '../../../../data/routes/routes.service';
import {NamedId} from '../../../../shared/models/NamedId';
import {
  DialogCreateContentBlockComponent,
  DialogUpdateContentBlockComponent
} from './dialogs-content-block/content-block-dialog-components';
import {
  ConfirmationDialogComponent
} from '../../../../shared/components/dialogs/confirmation-dialog/confirmation-dialog.component';

interface VehicleGroup {
  id: number;
  name: string;
  selected: boolean;
}

@Component({
  selector: 'app-manage-public-portal',
  templateUrl: './manage-public-portal.component.html',
  styleUrls: [
    './manage-public-portal.component.scss',
    '../../../settings/settings-fields.scss',
    '../../../settings/settings-common.scss',
  ]
})
export class ManagePublicPortalComponent extends AdditionalMapLayerManager implements OnInit, OnDestroy {

  configuration: ConfigurationModel;
  publicMapUrl: string;
  customerId: string;
  settings: PublicPortalSettings;
  headlines: Headline[];
  newHeadline: Headline = {text: ''};
  logo: string;
  vehicleGroups: VehicleGroup[];
  configSubscription: Subscription;
  routeConfigurations: NamedId[] = [{id: 0, name: 'None'}];
  servicedThresholdIndex: number;
  showAdditionalMapLayers: boolean;
  currentEditing: CurrentEditing = new CurrentEditing();
  currentPageTitle: string;
  currentImageUrl: string;
  currentLogo: string;
  previewTimestamp: string;
  protected publicLayers = true;
  protected readonly BaseMapType = BaseMapType;
  protected readonly RouteStatusLayerConfig = RouteStatusLayerConfig;

  constructor(protected configurationService: ConfigurationService,
              private vehicleService: VehiclesService,
              private routesService: RoutesService,
              private domSanitizer: DomSanitizer,
              protected toast: ToastService,
              public dialog: MatDialog,
  ) {
    super(configurationService, toast, dialog);
  }

  ngOnInit(): void {
    this.configSubscription = this.configurationService.sharedConfigurationModel.subscribe(model => {
      this.configuration = model;
      if (model) {
        this.logo = model.logoUrl;
        this.currentLogo = this.logo;
        this.currentEditing.loaded('logo');
      }
    });

    this.routesService.getRouteConfigurations().then(routeConfigurations => {
      this.routeConfigurations.push(...routeConfigurations.data.map(config => {
          return {
            id: config.id,
            name: config.name,
          };
        })
      );
    }).catch((message) => {
      this.toast.long('Failed to load Route Configurations!');
      console.error(message);
    });

    this.configurationService.getCustomerId().then(response => {
      this.customerId = response.data;
      this.updatePublicMapUrl();
    });

    this.configurationService.getPublicPortalSettings().then(response => {
      this.settings = response.data;
      this.headlines = this.settings.headline;
      this.servicedThresholdIndex = this.settings.routeStatusLayerConfig
        ? RouteStatusLayerConfig.THRESHOLD_VALUES.indexOf(this.settings.routeStatusLayerConfig.servicedThreshold) + 1
        : null;
      this.currentPageTitle = this.settings.title;
      this.currentEditing.loaded('title');
      this.currentImageUrl = this.settings.logoLink;
      this.currentEditing.loaded('image-url');
      this.setExcludedVehicleGroups();
    });

    this.loadMapLayerConfigurations(notEmpty => this.showAdditionalMapLayers = notEmpty);

    this.vehicleService.getVehicleCategories()
      .then((response) => {
        this.vehicleGroups = response.data.map(group => {
          return {
            id: group.id,
            name: group.title,
            selected: false,
          };
        });
        this.setExcludedVehicleGroups();
      })
      .catch((message) => {
        this.toast.long('Failed to load Vehicle Groups!');
        console.error(message);
      });

    this.updatePreview();
  }

  saveTitle() {
    this.settings.title = this.currentPageTitle;
    this.updatePublicPortalSettings(() => {
      this.currentEditing.saved('title');
    });
  }

  cancelTitle() {
    this.currentPageTitle = this.settings.title;
    this.currentEditing.cancel('title');
  }

  saveImageUrl() {
    this.settings.logoLink = this.currentImageUrl;
    this.updatePublicPortalSettings(() => {
      this.currentEditing.saved('image-url');
    });
  }

  cancelImageUrl() {
    this.currentImageUrl = this.settings.logoLink;
    this.currentEditing.cancel('image-url');
  }

  ngOnDestroy(): void {
    if (this.configSubscription) {
      this.configSubscription.unsubscribe();
    }
  }

  clickedAddLayer() {
    this.addMapLayer();
  }

  getBase64Image() {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(this.currentLogo);
  }

  fileInputChange(event: any) {
    this.currentEditing.changed('logo');

    const file = event.target.files[0];
    const reader = new FileReader();
    const that = this;
    reader.onloadend = () => {
      that.currentLogo = reader.result.toString();
    };
    reader.readAsDataURL(file);
  }

  saveLogo() {
    this.logo = this.currentLogo;
    this.configurationService.updateLogo(this.logo).then(() => {
      this.currentEditing.saved('logo');
      this.updatePreview();
      this.toast.short('Logo Updated!');
    }).catch(error => {
      this.toast.long('Logo Update Failed! ' + error);
    });
  }

  cancelLogo() {
    this.currentLogo = this.logo;
    this.currentEditing.cancel('logo');
  }

  saveHeadline() {
    const newHeadline = this.newHeadline;
    if (this.headlines.length === 0) {
      newHeadline['selected'] = true;
    }
    this.headlines.push(newHeadline);
    this.newHeadline = {text: ''};

    this.settings.headline = this.headlines;
    this.updatePublicPortalSettings(() => {
      this.currentEditing.saved('headline');
    });
  }

  cancelHeadline() {
    this.newHeadline = {text: ''};
    this.currentEditing.cancel('headline');
  }

  deleteHeadline(index: number) {
    this.headlines.splice(index, 1);
    // if selected was deleted, make selected the first from the array if it exists
    const selected = this.headlines.find(headline => headline.selected === true);
    if (!selected && this.headlines.length > 0) {
      this.headlines[0].selected = true;
    }

    this.settings.headline = this.headlines;
    this.updatePublicPortalSettings();
  }

  updateHeadline(index: number, headline: Headline) {
    this.headlines[index] = headline;
    this.settings.headline = this.headlines;
    this.updatePublicPortalSettings();
  }

  selectedHeadlineChanged(index: number, value: boolean) {
    this.headlines[index].selected = value;
    this.settings.headline = this.headlines;
    this.updatePublicPortalSettings();
  }

  updateExcludedVehicleGroups() {
    this.settings.excludeVehicleGroupIds = this.vehicleGroups.filter(group => !group.selected).map(group => group.id);
    this.updatePublicPortalSettings();
  }

  titleColorChanged(color: string) {
    this.settings.titleColor = color;
    this.updatePublicPortalSettings();
  }

  titleBackgroundColorChanged(color: string) {
    this.settings.titleBackgroundColor = color;
    this.updatePublicPortalSettings();
  }

  updatePublicPortalSettings(onSuccess: () => void = () => {
  }) {
    this.configurationService.updatePublicPortalSettings(this.settings).then(() => {
      const msg = 'Settings updated.';
      this.updatePreview();
      this.toast.short(msg);
      onSuccess();
    });
  }

  onVehicleHistoryChange() {
    if (this.settings.allowRouteStatusMap) {
      const servicedThreshold = 4;
      this.settings.routeStatusLayerConfig = {routeConfigurationId: 0, servicedThreshold};
      this.servicedThresholdIndex = RouteStatusLayerConfig.THRESHOLD_VALUES.indexOf(servicedThreshold) + 1;
    } else {
      this.settings.routeStatusLayerConfig = null;
    }
    this.updatePublicPortalSettings();
  }

  // we do not have access to component members here, so we pass it via parameter
  formatServicedThresholdLabel(values: number[]) {
    return (index: number): string => {
      return `${values[index - 1]}h`;
    };
  }

  onServicedThresholdChange(e) {
    this.settings.routeStatusLayerConfig.servicedThreshold = RouteStatusLayerConfig.THRESHOLD_VALUES[this.servicedThresholdIndex - 1];
    this.updatePublicPortalSettings();
  }

  toggleMessageBar() {
    this.settings.hideMessageBar = !this.settings.hideMessageBar;
    this.updatePublicPortalSettings();
  }

  toggleLeftPanel() {
    this.settings.hideLeftPanel = !this.settings.hideLeftPanel;
    this.updatePublicPortalSettings();
  }

  toggleAdditionalMapLayers() {
    this.toggleMapLayers(this.showAdditionalMapLayers);
    this.updatePreview();
  }

  onMapLayerDeleted(index: number) {
    this.deleteLayer(index);
    this.updatePreview();
  }

  onMapLayerMoveDown(index: number) {
    this.moveLayerDown(index, () => {
      this.updatePreview();
    });
  }

  onMapLayerToggled() {
    this.saveNonNewMapLayerConfigurations();
    this.updatePreview();
  }

  onMapLayerSaved(mapLayer: MapLayerConfiguration) {
    this.saveLayer(mapLayer, () => {
      this.updatePreview();
    });
  }

  onMapLayerCancelled(mapLayer: MapLayerConfiguration) {
    this.cancelLayer(mapLayer);
  }

  getIframeCode() {
    return '<iframe id="publicMap"\n' +
      '        title="PlowOps Public Map"\n' +
      '        width="600"\n' +
      '        height="600"\n' +
      '        src="' + this.publicMapUrl + '">\n' +
      '</iframe>';
  }

  createContentBlock() {
    const dialogRef = this.dialog.open(DialogCreateContentBlockComponent, {
      width: '800px',
      data: new ContentBlock(),
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        const contentBlock = dialogResult as ContentBlock;
        if (this.settings.contentBlocks.length === 0) {
          contentBlock.selected = true;
        }
        this.settings.contentBlocks.push(contentBlock);
        this.updatePublicPortalSettings();
      }
    });
  }

  updateContentBlock(index: number) {
    const contentBlock = Object.assign({}, this.settings.contentBlocks[index]); // shallow copy
    const dialogRef = this.dialog.open(DialogUpdateContentBlockComponent, {
      width: '800px',
      data: contentBlock,
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        const contentBlock = dialogResult as ContentBlock;
        this.settings.contentBlocks.splice(index, 1, contentBlock);
        this.updatePublicPortalSettings();
      }
    });
  }

  deleteContentBlock(index: number) {
    const contentBlock = this.settings.contentBlocks[index];
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: {
        text: `Are you sure you want to delete the Content Block "${contentBlock.title}"?`
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.settings.contentBlocks.splice(index, 1);
        this.updatePublicPortalSettings();
      }
    });
  }

  selectContentBlock(index: number) {
    this.settings.contentBlocks.forEach(it => {
      delete it.selected;
    });
    this.settings.contentBlocks[index].selected = true;
    this.updatePublicPortalSettings();
  }

  private updatePreview() {
    this.previewTimestamp = Date.now().toString();
  }

  private setExcludedVehicleGroups() {
    if (!!this.vehicleGroups && !!this.settings) {
      this.vehicleGroups.forEach(group => {
        group.selected = !this.settings.excludeVehicleGroupIds.includes(group.id);
      });
    }
  }

  private updatePublicMapUrl() {
    this.publicMapUrl = `${environment.publicBaseUrl}?id=${this.customerId}`;
  }
}
