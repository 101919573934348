<div class="row-with-select">
	@if (primaryText?.length) {
    	<div class="field-row-title">
			{{ primaryText }}
			@if (secondaryText?.length) {
				<div class="field-row-subtitle">
					{{ secondaryText }}
				</div>
			}
		</div>
	}
    <div class="field-row-element">
        <mat-form-field color="primary" appearance="outline" subscriptSizing="dynamic">
            <mat-select (selectionChange)="handleChange($event)"
                        [(ngModel)]="model" [compareWith]="modelComparison" [disabled]="disabled">
				@if (nullLabel) {
					<mat-option value="">{{ nullLabel }}</mat-option>
				}
                @for (optionValue of list; track $index) {
					<mat-option [value]="listId ? optionValue[listId] : optionValue">{{ optionValue[listLabel] }}</mat-option>
                }
            </mat-select>
        </mat-form-field>
    </div>

</div>
