<div class="flex-column gap-32">

    @if (isLoading) {
        <app-card preCardTitle="Cartegraph management">
            <div *appCardContent class="settings-header-container">
                <mat-spinner mode="indeterminate" diameter="30" class="mx-a my-15"></mat-spinner>
            </div>
        </app-card>
    } @else {

        <app-card preCardTitle="Cartegraph management" icon="arrow_back" (iconClicked)="navigateBack()">
            <div *appCardContent class="settings-header-container">
                <div class="settings-header-title">
                    <p>Manage Cartegraph notifications</p>
                    <div class="settings-header-subtitle">
                        Manage notifications for Cartegraph processing.
                        Add notification emails and edit filters.
                    </div>
                </div>
            </div>
        </app-card>


        <app-card preCardTitle="Notification emails">
            <div *appCardContent class="settings-header-container">
                Emails added here will get notifications on submit or import error
            </div>
            <div *appCardContent>
                <div class="field-row">
                    <div class="field-row-title">
                        Add email
                    </div>
                    <div class="field-row-element">
                        <button mat-stroked-button class="ml-10" (click)="editEmail()">Add</button>
                    </div>
                </div>

                <mat-nav-list>
                    @for (email of emails; track $index) {
                        <mat-list-item>
                            <div matListItemTitle>
                                {{ email }}
                            </div>
                            <div matListItemMeta>
                                <button mat-icon-button [matMenuTriggerFor]="menuEmail" aria-label="Menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menuEmail="matMenu">
                                    <button mat-menu-item (click)="editEmail($index)"><span>Edit</span></button>
                                    <button mat-menu-item (click)="removeEmail($index)"><span>Remove</span></button>
                                </mat-menu>
                            </div>
                        </mat-list-item>
                    } @empty {
                        No emails
                    }
                </mat-nav-list>

            </div>
        </app-card>

        <app-card preCardTitle="Notification filters">
            <div *appCardContent class="settings-header-container">
                Notifications containing words or sentences added here will exclude sending the notification
            </div>
            <div *appCardContent>
                <div class="field-row">
                    <div class="field-row-title">
                        Add filter
                    </div>

                    <div class="field-row-element">
                        <button mat-stroked-button class="ml-10" (click)="editFilter()">Add</button>
                    </div>
                </div>
                <mat-nav-list>
                    @for (filter of excludeFilter; track $index) {
                        <mat-list-item>
                            <div matListItemTitle>
                                {{ filter }}
                            </div>
                            <div matListItemMeta>
                                <button mat-icon-button [matMenuTriggerFor]="menuFilter" aria-label="Menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menuFilter="matMenu">
                                    <button mat-menu-item (click)="editFilter($index)"><span>Edit</span></button>
                                    <button mat-menu-item (click)="removeFilter($index)"><span>Remove</span></button>
                                </mat-menu>
                            </div>
                        </mat-list-item>
                    } @empty {
                        No filters
                    }
                </mat-nav-list>
            </div>
        </app-card>
    }
</div>