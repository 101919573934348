<div class="flex-column gap-32">

    @if (isLoading) {
        <app-card preCardTitle="Cartegraph management">
            <div *appCardContent class="settings-header-container">
                <mat-spinner mode="indeterminate" diameter="30" class="mx-a my-15"></mat-spinner>
            </div>
        </app-card>
    } @else {

        <app-manage-cartegraph-header page="SETTINGS"></app-manage-cartegraph-header>

        @if (datasetStatus == false) {
            <app-card>
                <div *appCardContent>
                    Please configure account anv verify connection to enable advanced Cartegraph configuration.
                </div>
            </app-card>

        } @else {


            <app-card preCardTitle="Configuration">
                <div *appCardContent>
                    <mat-nav-list>
                        <a mat-list-item
                           [routerLink]="['notifications']">
                            <div matListItemTitle>
                                Manage notifications for Cartegraph processing
                                <div class="text-subtitle">
                                    Add notification emails and edit filters
                                </div>
                            </div>
                            <div matListItemMeta>
                                <mat-icon>arrow_right</mat-icon>
                            </div>
                        </a>

                        <a mat-list-item class="mt-16"
                           [routerLink]="['features']">
                            <div matListItemTitle>
                                Manage Cartegraph features
                                <div class="text-subtitle">
                                    Enable or disable features and adjust them.
                                    Features enable or disable parts of the integration; import, export, processing.
                                </div>
                            </div>
                            <div matListItemMeta>
                                <mat-icon>arrow_right</mat-icon>
                            </div>
                        </a>

                        <a mat-list-item class="mt-16"
                           [routerLink]="['import']">
                            <div matListItemTitle>
                                Manage Cartegraph import filters
                                <div class="text-subtitle">
                                    Adjust driver, vehicle, material and other import filters.
                                </div>
                            </div>
                            <div matListItemMeta>
                                <mat-icon>arrow_right</mat-icon>
                            </div>
                        </a>

                    </mat-nav-list>
                </div>
            </app-card>
        }

        <app-card preCardTitle="Account">
            <div *appCardContent>
                <app-manage-cartegraph-account [configuration]="configuration"
                                               (onAccountVerified)="onAccountVerified()"
                ></app-manage-cartegraph-account>
            </div>
        </app-card>
    }
</div>
