import { Component, OnDestroy, OnInit } from '@angular/core';
import { Asset } from '../../../models/asset.class';
import { AssetsManagerService } from '../../../../../data/assets/assets-manager.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HardwareConfiguration, SensorType } from 'src/app/shared/models/vehicle.model';
import { ConfigurationModel, FeatureFlagEnum } from 'src/app/shared/models/configuration.model';
import { ShiftsManagerService } from '../../../../../data/shifts/shifts-manager.service';
import { ConfigurationService } from '../../../../../configuration/configuration.service';
import { Subscription } from 'rxjs';
import { MapControlService } from '../../../../../shared/components/map-viewer/services/map-control.service';
import { ShiftsService } from '../../../../../data/shifts/shifts.service';
import moment from 'moment';
import { DrawerContent, DrawerType } from 'src/app/layouts/right-drawer/right-drawer.component';
import { LiveMapDataService } from '../../../services/live-map-data.service';
import { ShiftState } from 'src/app/shared/models/shift.model';
import { RouteAssignment, RouteAssignmentStatus } from '../../../../../shared/models/route-assignment';
import { ArcgisApiService } from '../../../../../data/address-search/arcgis-api.service';
import { LocationSocketService } from '../../../../../data/websocket/location-socket.service';
import { MovementAndLocationDetail } from './MovementAndLocationDetail';
import { RouteAssignmentService } from '../../../../../data/routes/route-assignment.service';
import { RouteAssignmentManagerService } from '../../../../../data/routes/route-assignment-manager.service';
import { SecurityService } from '../../../../../security/security.service';
import { InsightsRoute } from '../../../../insights/insights-routing.module';
import { ToastService } from '../../../../../shared/services/toast.service';
import { MainRoute, RootRoute, SettingsRoute } from '../../../../../shared/models/angular-routing';
import { LocationEventData, MessageSource, WebSocketEvent } from '../../../../../data/websocket/model/message.event';
import { RightPanel } from '../../right-panel/right-panel.class';
import { LocationApiService } from '../../../../../data/location-api/location-api.service';
import { MetricType } from '../../../../../shared/models/session-metric.model';


@Component({
  selector: 'app-asset',
  templateUrl: './asset.component.html',
  styleUrls: ['./asset.component.scss']
})
export class AssetComponent implements OnInit, OnDestroy {

  isAdmin: boolean;
  configuration: ConfigurationModel;
  assets: Asset[];
  asset: Asset;
  assetMovementAndLocationDescription = '';
  activeDrawer: DrawerContent;
  completeRouteAssignments: RouteAssignment[];
  avgDistancePerAssignment: number;
  avgTimePerAssignment: number;

  ShiftState = ShiftState;
  HardwareConfiguration = HardwareConfiguration;
  SensorType = SensorType;
  FeatureFlagEnum = FeatureFlagEnum;
  RouteAssignmentStatus = RouteAssignmentStatus;
  InsightsRoute = InsightsRoute;
  MainRoute = MainRoute;
  RootRoute = RootRoute;
  SettingsRoute = SettingsRoute;
  DrawerType = DrawerType;

  shiftDurationIntervalId: any; // NodeJS.Timer

  private readonly openSubscriptions = Array<Subscription>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private securityService: SecurityService,
    private assetsManager: AssetsManagerService,
    private shiftManager: ShiftsManagerService,
    private shiftService: ShiftsService,
    private configurationService: ConfigurationService,
    private liveMapDataService: LiveMapDataService,
    private mapControlService: MapControlService,
    private locationSocketService: LocationSocketService,
    private arcGisApiService: ArcgisApiService,
    private routeAssignmentService: RouteAssignmentService,
    private routeAssignmentManager: RouteAssignmentManagerService,
    private locationApi: LocationApiService,
    private toast: ToastService,
  ) {
  }

  ngOnInit() {
    this.isAdmin = this.securityService.isAdminSync();

    const assetsSubscription = this.assetsManager.assets$.subscribe(assets => {
      this.assets = assets;
      const assetId = +this.activatedRoute.snapshot.params.id;
      if (!!assetId && !!assets && assetId !== this.asset?.id) {
        this.changeAsset(assetId);
      } else { // asset has not changed - only update M&L of currently selected asset
        this.updateMovementAndLocationDescription();
      }
    });
    this.openSubscriptions.push(assetsSubscription);

    const paramsSubscription = this.activatedRoute.params.subscribe(params => {
      const assetId = +params['id'];
      if (!!assetId && !!this.assets && assetId !== this.asset?.id) {
        this.changeAsset(assetId);
      }
    });
    this.openSubscriptions.push(paramsSubscription);

    this.activatedRoute.queryParams.subscribe((params) => {
      this.activeDrawer = params.drawer;
    });

    const configSubscription = this.configurationService.sharedConfigurationModel.subscribe(model => {
      if (model) {
        this.configuration = model;
      }
    });
    this.openSubscriptions.push(configSubscription);

    const websocketSubscription = this.locationSocketService
      .onMessage(MessageSource.TRACK_GEOMETRY_CACHE)
      .subscribe((e: WebSocketEvent<string, LocationEventData>) => {
        this.updateDistanceDriven();
      });
    this.openSubscriptions.push(websocketSubscription);

    const assignmentsSubscription = this.routeAssignmentManager.recentRouteAssignments$.subscribe(assignmentsUpdate => {
      if (assignmentsUpdate.updated.length > 0) {
        assignmentsUpdate.updated.forEach(assignment => {
          // if updated assignment belongs to this vehicle
          if (assignment.vehicleId === this.asset?.id
            && !!assignment.completed
            && !!this.completeRouteAssignments
            && !this.completeRouteAssignments.find(a => a.id === assignment.id)
            && this.asset.shiftId === assignment.shiftId
          ) {
            this.completeRouteAssignments.push(assignment);
            this.updateAssignmentMetrics();
          }
        });
      }
    });
    this.openSubscriptions.push(assignmentsSubscription);
  }

  ngOnDestroy() {
    this.openSubscriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
    this.assetsManager.filterByVehicleIds(null);
    if (!!this.shiftDurationIntervalId) {
      clearInterval(this.shiftDurationIntervalId);
    }
  }

  changeAsset(assetId: number) {
    this.asset = this.assets.find(asset => asset.id === assetId);
    this.assetsManager.filterByVehicleIds([assetId]);

    if (this.asset.shiftStatus !== ShiftState.ENDED) {
      this.mapControlService.zoomToShiftExtent(this.asset.shiftId);

      // update duration every second
      if (!!this.shiftDurationIntervalId) {
        clearInterval(this.shiftDurationIntervalId);
      }
      this.shiftDurationIntervalId = setInterval(() => {
        this.asset.shiftDuration = moment().diff(moment(this.asset.shiftStartTime), 'seconds');
      }, 1000);

      // update distance driven whenever the geometry cache is updated
      this.updateDistanceDriven();
      this.updateDigitalSpreaderStatus();
      this.updateMovementAndLocationDescription();
      this.loadCompletedAssignmentsMetrics();
    } else {
      this.mapControlService.zoomToCoordinates(this.asset.location);
    }
  }

  updateMovementAndLocationDescription() {
    if (this.asset == null || this.asset.location == null) {
      this.assetMovementAndLocationDescription = new MovementAndLocationDetail(
        this.asset?.speed, this.asset?.currentHeading, null
      ).toDescription();
    } else {
      // console.log(`rGeo id = '${this.asset.id}', [lat, lng] = [${this.asset.location.lat}, ${this.asset.location.lng}]`);
      this.arcGisApiService.findAddressOfLocation(this.asset.location)
        .then(reverseGeocodeInfo => {
          this.assetMovementAndLocationDescription = new MovementAndLocationDetail(
            this.asset.speed, this.asset.currentHeading, reverseGeocodeInfo
          ).toDescription();
        });
    }
  }

  forceEndShift(shiftId: number) {
    this.shiftManager.forceEndShiftForVehicle(shiftId);
  }

  hasFeatureFlag(featureFlag: string): boolean {
    return this.configuration.featureFlags.find(value => value.isEnabled && value.name === featureFlag) !== undefined;
  }

  getRightPanelQueryParams(drawerType: DrawerType) {
    const queryParams = {drawer: drawerType.content};
    queryParams[RightPanel.TRIGGER_VEHICLE_ACTION_PARAM_NAME] = true;
    return queryParams;
  }

  close() {
    this.router.navigate(
      [`/${RootRoute.MAIN}`],
      {
        queryParamsHandling: 'merge',
      }
    );
  }

  private updateDistanceDriven() {
    const assetId = this.asset?.id;
    if (!!this.asset && this.asset.shiftStatus !== ShiftState.ENDED) {
      this.shiftService.getShiftDistanceDriven(this.asset.shiftId).toPromise().then(response => {
        if (this.asset.id === assetId) {
          this.asset.distanceDriven = response.data.distance;
        }
      }).catch(error => {
        console.log(error);
      });
    }
  }

  private updateDigitalSpreaderStatus() {
    this.asset.digitalGranularStatus = null;
    this.asset.digitalLiquidStatus = null;
    const hwConfig = this.asset.vehicleHardwareConfiguration;
    if (!!hwConfig && !!hwConfig.digitalSpreader) {
      if (hwConfig.digitalSpreader.settings.readGranular) {
        this.locationApi.closestSessionMetricEvent(
            this.asset.shiftId,
            this.asset.id,
            MetricType.GRANULAR_RATE,
        ).then(metric => {
          this.asset.digitalGranularStatus = {
            controllerCurrentRate: metric.data.metricValue,
            controllerMaxRate: hwConfig.digitalSpreader.settings.granularMaxRate,
          };
        }).catch(error => {
          console.error(error);
          this.toast.short('Failed to load Digital Spreader granular values!');
        });
      }

      if (hwConfig.digitalSpreader.settings.readLiquid) {
        this.locationApi.closestSessionMetricEvent(
            this.asset.shiftId,
            this.asset.id,
            MetricType.LIQUID_RATE,
        ).then(metric => {
          this.asset.digitalLiquidStatus = {
            controllerCurrentRate: metric.data.metricValue,
            controllerMaxRate: hwConfig.digitalSpreader.settings.granularMaxRate,
          };
        }).catch(error => {
          console.error(error);
          this.toast.short('Failed to load Digital Spreader liquid values!');
        });
      }
    }
  }

  private loadCompletedAssignmentsMetrics() {
    if (!!this.asset && this.asset.shiftStatus !== ShiftState.ENDED) {
      this.routeAssignmentService.getRouteAssignments(
        null,
        this.asset.id,
        this.asset.shiftId,
        null,
        null,
        true,
        false
      ).then(response => {
        this.completeRouteAssignments = response.data.filter(assignment => !!assignment.completed);
        this.updateAssignmentMetrics();
      });
    }
  }

  private updateAssignmentMetrics() {
    if (!!this.completeRouteAssignments) {
      let totalDistance = 0;
      let totalTime = 0;
      this.avgDistancePerAssignment = 0;
      this.avgTimePerAssignment = 0;
      for (const assignment of this.completeRouteAssignments) {
        totalDistance += (assignment.distanceDriven || 0);
        totalTime += (assignment.timeDriven || 0);
      }
      const assignmentCount = this.completeRouteAssignments.length;
      if (assignmentCount > 0) {
        this.avgDistancePerAssignment = totalDistance / assignmentCount;
        this.avgTimePerAssignment = totalTime / assignmentCount;
      }
    }
  }
}
