import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../../../../shared/material/material.module';
import {SharedModule} from '../../../../shared/shared.module';
import {ManageObservationsComponent} from './manage-observations.component';
import {
  CreateObservationTypeDialogComponent
} from './observation-group/dialogs/create-observation-type-dialog/create-observation-type-dialog.component';
import {
  UpdateObservationTypeDialogComponent
} from './observation-group/dialogs/update-observation-type-dialog/update-observation-type-dialog.component';
import {
  CreateObservationGroupDialogComponent
} from './dialogs/create-observation-group-dialog/create-observation-group-dialog.component';
import {
  DeleteObservationGroupDialogComponent
} from './dialogs/delete-observation-group-dialog/delete-observation-group-dialog.component';
import {
  UpdateObservationGroupDialogComponent
} from './dialogs/update-observation-group-dialog/update-observation-group-dialog.component';
import {CardComponent} from '../../../../shared/components/card/card.component';
import {MatCard} from '@angular/material/card';
import {CardContentDirective, CardContentRawDirective} from '../../../../shared/components/card/card-content.directive';

@NgModule({
    declarations: [
        ManageObservationsComponent,
        CreateObservationTypeDialogComponent,
        UpdateObservationTypeDialogComponent,
        CreateObservationGroupDialogComponent,
        DeleteObservationGroupDialogComponent,
        UpdateObservationGroupDialogComponent,
    ],
    exports: [
        ManageObservationsComponent,
    ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedModule,
    CardComponent,
    MatCard,
    CardContentDirective,
    CardContentRawDirective,
  ]
})
export class ObservationManagementModule { }
