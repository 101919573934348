import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MyAccountComponent} from './components/my-account/my-account.component';
import {ManageObservationsComponent} from './components/manage-observations/manage-observations.component';
import {adminGuard, defaultSettingsRoute} from '../../security/admin-guard.service';
import {ManageUsersMainComponent} from './components/manage-users/manage-users-main.component';
import {
  RouteConfigurationListComponent
} from './components/manage-routes/route-configuration-list/route-configuration-list.component';
import {ManagePublicPortalComponent} from './components/manage-public-portal/manage-public-portal.component';
import {
  InspectionFormListComponent
} from './components/manage-inspection-forms/form-list/inspection-form-list.component';
import {InspectionFormComponent} from './components/manage-inspection-forms/form/inspection-form.component';
import {
  ManageVehicleInstallationComponent
} from './components/manage-vehicle-installation/manage-vehicle-installation.component';
import {ManageVehiclesComponent} from './components/manage-vehicles/manage-vehicles.component';
import {VehicleGroupListComponent} from './components/manage-vehicles/vehicle-group-list/vehicle-group-list.component';
import {VehicleGroupEditComponent} from './components/manage-vehicles/vehicle-group-edit/vehicle-group-edit.component';
import {VehicleEditComponent} from './components/manage-vehicles/vehicle-edit/vehicle-edit.component';
import {SettingsComponent} from './components/settings.component';
import {
  RouteConfigurationEditorComponent
} from './components/manage-routes/route-configuration-editor/route-configuration-editor.component';
import {
  InspectionFormQuestionsComponent
} from './components/manage-inspection-forms/questions/inspection-form-questions.component';
import {VehicleListComponent} from './components/manage-vehicles/vehicle-list/vehicle-list.component';
import {SettingsRoute} from '../../shared/models/angular-routing';
import {CartegraphManagementModule} from './components/manage-cartegraph/cartegraph-management.module';

const routes: Routes = [
  {
    path: `${SettingsRoute.MANAGE_ROUTES}/:configurationId`,
    component: RouteConfigurationEditorComponent,
    canActivate: [adminGuard],
  },
  {
    path: '',
    component: SettingsComponent,
    children: [
      {
        // the default redirect to the users management sub-route
        path: '',
        component: MyAccountComponent,
        canActivate: [defaultSettingsRoute], // redirect based on privileges
      },
      {
        path: SettingsRoute.MANAGE_USERS,
        component: ManageUsersMainComponent,
        canActivate: [adminGuard],
      },
      {
        path: SettingsRoute.MANAGE_VEHICLES,
        component: ManageVehiclesComponent,
        canActivate: [adminGuard],
        children: [
          {
            path: '',
            component: VehicleGroupListComponent,
          },
          {
            path: 'group/:id',
            component: VehicleGroupEditComponent,
          },
          {
            path: 'group/:id/vehicles',
            component: VehicleListComponent,
          },
          {
            path: 'vehicle/:id',
            component: VehicleEditComponent,
          }
        ]
      },
      {
        path: SettingsRoute.MANAGE_OBSERVATIONS,
        component: ManageObservationsComponent,
        canActivate: [adminGuard],
      },
      {
        path: SettingsRoute.MANAGE_ROUTES,
        component: RouteConfigurationListComponent,
        canActivate: [adminGuard],
      },
      {
        path: SettingsRoute.MANAGE_PUBLIC_PORTAL,
        component: ManagePublicPortalComponent,
        canActivate: [adminGuard],
      },
      {
        path: SettingsRoute.INSPECTION_FORMS,
        component: InspectionFormListComponent,
        canActivate: [adminGuard],
      },
      {
        path: `${SettingsRoute.INSPECTION_FORMS}/:inspectionFormId`,
        component: InspectionFormComponent,
        canActivate: [adminGuard],
      },
      {
        path: `${SettingsRoute.INSPECTION_FORMS}/:inspectionFormId/questions`,
        component: InspectionFormQuestionsComponent,
        canActivate: [adminGuard],
      },
      {
        path: `${SettingsRoute.INSPECTION_FORMS}/:inspectionFormId/copy`,
        component: InspectionFormComponent,
        canActivate: [adminGuard],
      },
      {
        path: `${SettingsRoute.CARTEGRAPH}`,
        canActivate: [adminGuard],
        loadChildren: () => CartegraphManagementModule,
      },
      {
        path: SettingsRoute.VEHICLE_INSTALLATIONS,
        component: ManageVehicleInstallationComponent,
        canActivate: [adminGuard],
      },
      {
        path: SettingsRoute.MY_ACCOUNT,
        component: MyAccountComponent
      },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule],
})
export class SettingsRoutingModule {
}
