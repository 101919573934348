<div class="narrow-panel-container" *ngIf="!!asset">
    <div class="narrow-panel-header">
        <div class="content-title-1"
             [matTooltip]="asset.name"
             [ngClass]="{'visible-settings-button': isAdmin}"
        >
            {{ asset.name }}
        </div>
        <div>
          <button
            *ngIf="isAdmin"
            [routerLink]="['/' + RootRoute.SETTINGS, SettingsRoute.MANAGE_VEHICLES, 'vehicle', asset.id]"
            mat-icon-button
            aria-label="close"
          >
            <mat-icon class="action-icon">settings</mat-icon>
          </button>
          <button
            (click)="close()"
            mat-icon-button
            aria-label="close"
          >
            <mat-icon class="action-icon">close</mat-icon>
          </button>
        </div>
    </div>

    <div class="narrow-panel-section" >
        <div class="content-title-2">
          @if (asset.shiftStatus !== ShiftState.ENDED) {
            {{ asset.driverName }}<span *ngIf="asset.driverIsGuest"> - Guest</span>
          } @else {
            Offline
          }
        </div>

        <div class="content-title-3">
          @if (asset.shiftStatus !== ShiftState.ENDED) {
            {{ assetMovementAndLocationDescription }}
          }
        </div>
    </div>

    <div class="narrow-panel-section" *ngIf="asset.shiftStatus === ShiftState.ENDED">
      <div class="content-paragraph">
        <button
          mat-button
          color="accent"
          type="submit"
          [routerLink]="['/' + RootRoute.MAIN, MainRoute.INSIGHTS, InsightsRoute.SHIFT_LOOKUP]"
          [queryParams]="{'category': 'vehicles', 'id': asset.id}"
        >
          View past shifts for this vehicle
        </button>
      </div>
    </div>

    <div class="narrow-panel-section space-between" *ngIf="asset.shiftStatus !== ShiftState.ENDED && !asset.hasNoTablet">
        <button
                mat-icon-button
                color="accent"
                [routerLink]="[]"
                [queryParams]="getRightPanelQueryParams(DrawerType.ROUTE)"
                [matTooltip]="'Assign route for the vehicle'"
        >
            <mat-icon>{{ DrawerType.ROUTE.icon }}</mat-icon>
        </button>
        <button
                mat-icon-button
                color="accent"
                [routerLink]="[]"
                [queryParams]="getRightPanelQueryParams(DrawerType.MESSAGES)"
                [matTooltip]="'Send message to driver'"
                *ngIf="hasFeatureFlag(FeatureFlagEnum.FleetMessaging)"
        >
            <mat-icon>{{ DrawerType.MESSAGES.icon }}</mat-icon>
        </button>
        <button
                mat-icon-button
                color="accent"
                [routerLink]="[]"
                [queryParams]="getRightPanelQueryParams(DrawerType.OBSERVATION)"
                [matTooltip]="'View vehicle observations'"
        >
            <mat-icon>{{ DrawerType.OBSERVATION.icon }}</mat-icon>
        </button>
        <button
                mat-icon-button
                color="accent"
                [routerLink]="[]"
                [queryParams]="getRightPanelQueryParams(DrawerType.LIVE_VIDEO)"
                [matTooltip]="'View live stream for the vehicle'"
                [disabled]="!asset.cameraConfiguration"
                *ngIf="hasFeatureFlag(FeatureFlagEnum.DashCam)"
        >
            <mat-icon>{{ DrawerType.LIVE_VIDEO.icon }}</mat-icon>
        </button>
        <button
                mat-icon-button
                color="accent"
                [routerLink]="[]"
                [queryParams]="getRightPanelQueryParams(DrawerType.ALERTS)"
                [matTooltip]="'Filter events for the vehicle'"
        >
            <mat-icon>{{ DrawerType.ALERTS.icon }}</mat-icon>
        </button>
        <button
                mat-icon-button
                color="accent"
                [routerLink]="[]"
                [queryParams]="getRightPanelQueryParams(DrawerType.QUICK_INSIGHTS)"
                [matTooltip]="'Insights for the vehicle'"
        >
            <mat-icon>{{ DrawerType.QUICK_INSIGHTS.icon }}</mat-icon>
        </button>
    </div>

    <div class="narrow-panel-divider" *ngIf="asset.shiftStatus !== ShiftState.ENDED">
    </div>

    <div class="narrow-panel-section" *ngIf="asset.shiftStatus !== ShiftState.ENDED">
        <div class="content-paragraph">
            <div class="content-row">
                <div class="info-name">
                    <mat-icon>timer</mat-icon>
                </div>
                <div class="info-value ops-link" >
                    {{ asset.shiftDuration | duration }} | started at <span [matTooltip]="asset.shiftStartTime | date: 'shortDate'">{{ asset.shiftStartTime | date: 'shortTime' }}</span>
                </div>
            </div>
            <div class="content-row">
                <div class="info-name">
                    <mat-icon>pin</mat-icon>
                </div>
                <div class="info-value ops-link" *ngIf="!!configuration">
                    {{ (asset.distanceDriven | distance:configuration.useMetricSystem) || 'N/A' }}<span> driven</span>
                </div>
            </div>
            <div class="content-row" *ngIf="asset.vehicleLmuId">
                <div class="info-name">
                    <mat-icon>vibration</mat-icon>
                </div>
                <div class="info-value ops-link">
                    LMU | {{ asset.vehicleLmuId }}
                </div>
            </div>

            <ng-container
                 *ngIf="asset.vehicleHardwareConfiguration &&
              asset.vehicleHardwareConfiguration.sensorInputs &&
              asset.vehicleHardwareConfiguration.sensorInputs.length > 0">
                <ng-container
                        *ngFor="let sensorInput of HardwareConfiguration.getValidInputs(asset.vehicleHardwareConfiguration.sensorInputs)">
                    <div class="content-row" *ngIf="sensorInput.type === SensorType.PLOW || sensorInput.type === SensorType.PLOW2W">
                        <div class="info-name">
                            <mat-icon class="mirrored">signal_cellular_1_bar</mat-icon>
                        </div>
                        <div class="info-value ops-link">
                            {{ !!sensorInput.label ? sensorInput.label : 'Plow' }} {{ sensorInput.bitNumber | plowStatus: asset.flags }}
                        </div>
                    </div>
                    <div class="content-row" *ngIf="sensorInput.type === SensorType.GRANULAR_SPREADER">
                        <div class="info-name">
                            <mat-icon>grain</mat-icon>
                        </div>
                        <div class="info-value ops-link">
                            {{ !!sensorInput.label ? sensorInput.label : 'Granular Spreader' }} {{ sensorInput.bitNumber | spreaderStatus: asset.flags }}
                            ({{ sensorInput.rate || 'N/A' }} {{'granular' | rateUnit:configuration.useMetricSystem}})
                        </div>
                    </div>
                    <div class="content-row" *ngIf="sensorInput.type === SensorType.DIGITAL_GRANULAR_SPREADER && !!asset.digitalGranularStatus">
                        <div class="info-name">
                            <mat-icon>grain</mat-icon>
                        </div>
                        <div class="info-value ops-link">
                            {{ !!sensorInput.label ? sensorInput.label : 'Digital Granular Spreader' }} {{ sensorInput.bitNumber | spreaderStatus: asset.flags }}
                            ({{ (asset.digitalGranularStatus.controllerCurrentRate | spreaderMass:configuration.useMetricSystem) || 'N/A' }})
                        </div>
                    </div>
                    <div class="content-row" *ngIf="sensorInput.type === SensorType.LIQUID_SPREADER">
                        <div class="info-name">
                            <mat-icon>water_drop</mat-icon>
                        </div>
                        <div class="info-value ops-link">
                            {{ !!sensorInput.label ? sensorInput.label : 'Liquid Spreader' }} {{ sensorInput.bitNumber | spreaderStatus: asset.flags }}
                            ({{ sensorInput.rate || 'N/A' }} {{ 'liquid' | rateUnit:configuration.useMetricSystem }})
                        </div>
                    </div>
                    <div class="content-row" *ngIf="sensorInput.type === SensorType.DIGITAL_LIQUID_SPREADER && !!asset.digitalLiquidStatus">
                        <div class="info-name">
                            <mat-icon>water_drop</mat-icon>
                        </div>
                        <div class="info-value ops-link">
                            {{ !!sensorInput.label ? sensorInput.label : 'Digital Liquid Spreader' }} {{ sensorInput.bitNumber | spreaderStatus: asset.flags }}
                            ({{ (asset.digitalLiquidStatus.controllerCurrentRate | spreaderLiquid:configuration.useMetricSystem) || 'N/A' }})
                        </div>
                    </div>
                    <div class="content-row" *ngIf="sensorInput.type === SensorType.MOWER">
                        <div class="info-name">
                            <mat-icon>grass</mat-icon>
                        </div>
                        <div class="info-value ops-link">
                            {{ !!sensorInput.label ? sensorInput.label : 'Mower' }} {{ sensorInput.bitNumber | mowerStatus: asset.flags }}
                        </div>
                    </div>
                    <div class="content-row" *ngIf="sensorInput.type === SensorType.SWEEPER">
                        <div class="info-name">
                            <mat-icon>cleaning_services</mat-icon>
                        </div>
                        <div class="info-value ops-link">
                            {{ !!sensorInput.label ? sensorInput.label : 'Sweeper' }} {{ sensorInput.bitNumber | sweeperStatus: asset.flags }}
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>

    <div class="narrow-panel-divider" *ngIf="asset.shiftStatus !== ShiftState.ENDED && !asset.hasNoTablet">
    </div>

    <div class="narrow-panel-section" *ngIf="asset.shiftStatus !== ShiftState.ENDED && !asset.hasNoTablet">
        <div class="content-title-2">
            Assignment Queue
        </div>

        <div class="content-paragraph">
            <app-route-assignment-queue
                    [vehicle]="asset"
                    [timeFromHoursFilter]="24"
            ></app-route-assignment-queue>
        </div>
    </div>

    <div class="narrow-panel-divider" *ngIf="asset.shiftStatus !== ShiftState.ENDED">
    </div>

    <div class="narrow-panel-section" *ngIf="asset.shiftStatus !== ShiftState.ENDED">
        <div class="content-paragraph">
            <div class="space-between-row">
                <span>Shift ID: {{ asset.shiftId }}</span>
                <button
                        mat-button
                        color="accent"
                        type="submit"
                        (click)="forceEndShift(asset.shiftId)"
                >
                    End Shift
                </button>
            </div>
        </div>
    </div>
</div>
