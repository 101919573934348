import {MapLayerConfiguration, MapLayerType} from '../../../../shared/models/map-layer.model';
import {MapLayerPreviewComponent} from './map-layer-preview/map-layer-preview.component';
import {ConfigurationService} from '../../../../configuration/configuration.service';
import {ToastService} from '../../../../shared/services/toast.service';
import {MatDialog} from '@angular/material/dialog';

export abstract class AdditionalMapLayerManager {

    protected abstract publicLayers: boolean;
    mapLayers: MapLayerConfiguration[];

    protected constructor(
        protected configurationService: ConfigurationService,
        protected toast: ToastService,
        protected dialog: MatDialog,
    ) {}

    addMapLayer() {
        const mapLayer = new MapLayerConfiguration();
        mapLayer.public = this.publicLayers;
        mapLayer.type = MapLayerType.RASTER;
        mapLayer.order = this.mapLayers.length > 0 ? (this.mapLayers[this.mapLayers.length - 1].order + 1) : 1;
        mapLayer.enabled = false;
        mapLayer.new = true;
        mapLayer.dirty = true;
        mapLayer.currentType = mapLayer.type;
        mapLayer.currentName = mapLayer.name;
        mapLayer.currentUrl = mapLayer.url;
        mapLayer.currentConfiguration = mapLayer.configuration;
        mapLayer.currentUsername = mapLayer.username;
        mapLayer.currentPassword = mapLayer.password;
        mapLayer.currentOpacity = 1.0;
        this.mapLayers.push(mapLayer);
    }

    /**
     * All non-new layers will be saved, it does not matter if they are dirty,
     * because only for layer passed in parameter will dirty values be applied.
     */
    saveLayer(
      mapLayer: MapLayerConfiguration,
      onSuccess: () => void = () => {},
    ) {
        const valid = MapLayerConfiguration.isValid(mapLayer);
        if (valid) {
            const originalType = mapLayer.type;
            const originalName = mapLayer.name;
            const originalUrl = mapLayer.url;
            const originalConfiguration = mapLayer.configuration;
            const originalNew = mapLayer.new;
            const originalUsername = mapLayer.username;
            const originalPassword = mapLayer.password;
            const originalOpacity = mapLayer.opacity;

            mapLayer.type = mapLayer.currentType;
            mapLayer.name = mapLayer.currentName;
            mapLayer.url = mapLayer.currentUrl;
            mapLayer.configuration = mapLayer.currentConfiguration;
            mapLayer.username = mapLayer.currentUsername;
            mapLayer.password = mapLayer.currentPassword;
            mapLayer.opacity = mapLayer.currentOpacity;
            mapLayer.enabled = true;

            const toBeSaved = this.mapLayers.filter(layer => layer === mapLayer || !layer.new);
            this.saveMapLayerConfigurations(
                toBeSaved,
                () => {
                    mapLayer.new = false;
                    mapLayer.dirty = false;
                    onSuccess();
                },
                () => {
                    mapLayer.type = originalType;
                    mapLayer.name = originalName;
                    mapLayer.url = originalUrl;
                    mapLayer.configuration = originalConfiguration;
                    mapLayer.username = originalUsername;
                    mapLayer.password = originalPassword;
                    mapLayer.opacity = originalOpacity;
                    if (originalNew) {
                        mapLayer.enabled = false;
                    }
            });
        } else {
            const msg = 'Invalid map layer configuration';
            this.toast.long(msg);
        }
    }

    cancelLayer(mapLayer: MapLayerConfiguration) {
        if (mapLayer.new) { throw Error('cannot cancel new layer'); }

        mapLayer.currentType = mapLayer.type;
        mapLayer.currentName = mapLayer.name;
        mapLayer.currentUrl = mapLayer.url;
        mapLayer.currentConfiguration = mapLayer.configuration;
        mapLayer.currentUsername = mapLayer.username;
        mapLayer.currentPassword = mapLayer.password;
        mapLayer.dirty = false;
        mapLayer.useCredentials = !!mapLayer.username && !!mapLayer.password;
        mapLayer.currentOpacity = mapLayer.opacity;
    }

    saveNonNewMapLayerConfigurations() {
        this.saveMapLayerConfigurations(this.nonNewLayersOnly());
    }

    saveMapLayerConfigurations(
      layers: MapLayerConfiguration[],
      onSuccess: () => void = () => {},
      onFailure: () => void = () => {},
    ) {
        this.configurationService.updateMapLayers(layers, this.publicLayers).then(response => {
            const msg = 'Map layer configurations saved';
            this.toast.short(msg);
            this.configurationService.refreshConfiguration();
            onSuccess();
        }).catch(error => {
            const msg = 'Error while saving map layer configurations';
            console.error(msg);
            this.toast.long(msg);
            onFailure();
        });
    }

    loadMapLayerConfigurations(
      after: (notEmpty: boolean) => void = () => {},
    ) {
        this.configurationService.getMapLayers(this.publicLayers).then(response => {
            this.mapLayers = response.data;
            this.mapLayers.forEach(value => {
                value.currentType = value.type;
                value.currentName = value.name;
                value.currentUrl = value.url;
                value.currentConfiguration = value.configuration;
                value.currentUsername = value.username;
                value.currentPassword = value.password;
                value.new = false;
                value.dirty = false;
                value.useCredentials = !!value.username && !!value.password;
                value.currentOpacity = value.opacity !== undefined && value.opacity !== null ? value.opacity : 1.0;
            });
            after(this.mapLayers.length > 0);
        }).catch(error => {
            const msg = 'Error while loading map layer configurations';
            console.error(msg);
            this.toast.long(msg);
        });
    }

    toggleMapLayers(enabled: boolean) {
        this.mapLayers
          .filter(mapLayer => !mapLayer.new)
          .forEach(mapLayer => {
              mapLayer.enabled = enabled;
          });
        this.saveMapLayerConfigurations(this.nonNewLayersOnly());
    }

    deleteLayer(index: number) {
        this.mapLayers.splice(index, 1);
        this.saveMapLayerConfigurations(this.nonNewLayersOnly());
    }

    moveLayerDown(index: number, after: () => void = () => {}) {
        if (this.mapLayers.length > index + 1) {
            const nextItemOrder = this.mapLayers[index + 1].order;
            this.mapLayers[index + 1].order = this.mapLayers[index].order;
            this.mapLayers[index].order = nextItemOrder;
            [this.mapLayers[index], this.mapLayers[index + 1]] = [this.mapLayers[index + 1], this.mapLayers[index]];

            this.saveMapLayerConfigurations(this.nonNewLayersOnly());
            after();
        }
    }

    openMapPreview() {
        const dialogRef = this.dialog.open(MapLayerPreviewComponent, {
            maxWidth: '100vw',
            maxHeight: '100vh',
            width: '80vw',
            height: '85vh',
            data: this.mapLayers,
        });
    }

    private nonNewLayersOnly() {
        return this.mapLayers.filter(layer => !layer.new);
    }
}
