<div class="flex-column gap-32">
	@if (!settings) {
		<mat-spinner mode="indeterminate" diameter="30" class="mx-a mt-15"></mat-spinner>
	}
	@else {
		<app-card preCardTitle="Page Settings">
			<div *appCardContent class="settings-header-container">
				<div class="settings-header-title">
					<p>My Public Portal</p>
					<div class="settings-header-subtitle">
						<div class="portal-url-section">
							<a class="ops-link" [href]="publicMapUrl" target="_new">{{ publicMapUrl }}</a>
							<button mat-icon-button
									color="accent"
									class="smaller"
									[matTooltip]="'Copy to clipboard'"
									[cdkCopyToClipboard]="publicMapUrl">
								<mat-icon>content_copy</mat-icon>
							</button>
						</div>
					</div>
				</div>
			</div>
		</app-card>

		<app-card preCardTitle="Banner Messages">
			<div *appCardContent>
				<app-toggle-expansion-panel
						[expanded]="!settings.hideMessageBar"
						(expandedChange)="toggleMessageBar()"
						panelTitle="Show Public Messages"
						panelDescription="Displayed at the top of the page on the public portal">

					<div class="field-row">
						<div class="field-row-title">
							Scrolling Speed
						</div>
						<div class="field-row-element">
							<mat-form-field appearance="outline" color="accent">
								<mat-select [(ngModel)]="settings.scrollingSpeed"
											(ngModelChange)="updatePublicPortalSettings()"
								>
									<mat-option [value]="0">No Scrolling</mat-option>
									<mat-option [value]="1">Slow</mat-option>
									<mat-option [value]="2">Medium</mat-option>
									<mat-option [value]="3">Fast</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>

					<div class="banner-section wide">
						<div class="flex-column indent">
							<ng-container *ngFor="let headline of headlines; let i = index">
								<app-headline [headline]="headline"
											  [index]="i"
											  (headlineDeleted)="deleteHeadline($event)"
											  (headlineUpdated)="updateHeadline($event.index, $event.headline)"
											  (headlineSelected)="selectedHeadlineChanged(i, $event)"
								></app-headline>
							</ng-container>
						</div>
					</div>

					<form>
						<div class="field-row pt-15">
							<div class="field-row-title">
								Add New Message
							</div>
							<div class="field-row-element flex-column">
								<mat-form-field appearance="outline" color="accent" class="w-100">
									<!--<mat-label>Message Text</mat-label>-->
									<input matInput
										   autocomplete="off"
										   type=text
										   placeholder="Message text"
										   name="text"
										   [(ngModel)]="newHeadline.text"
										   (ngModelChange)="currentEditing.changed('headline')"
									>
									<mat-hint>Messages appear in the blue bar below the page title.</mat-hint>
								</mat-form-field>
								<div>
									<button mat-stroked-button
											type="submit"
											[disabled]="!newHeadline.text.length"
											(click)="saveHeadline()">
										Add Message
									</button>
								</div>
							</div>
						</div>
					</form>
				</app-toggle-expansion-panel>
			</div>
		</app-card>

		<app-card preCardTitle="Left Panel Content">
			<div *appCardContent>
				<app-toggle-expansion-panel
						[expanded]="!settings.hideLeftPanel"
						(expandedChange)="toggleLeftPanel()"
						panelTitle="Show Left Panel"
						panelDescription="Displayed on the left of the page on the public portal">

					<div class="field-row">
						<div class="field-row-title">
							Title
						</div>
						<div class="field-row-element">
							<mat-form-field appearance="outline" color="accent" floatLabel="auto"
											class="w-100">
								<!--<mat-label>Title</mat-label>-->
								<input matInput
									   autocomplete="off"
									   type=text
									   placeholder="Your Page Title"
									   [(ngModel)]="currentPageTitle"
									   (ngModelChange)="currentEditing.changed('title')"
								>
								<mat-hint>Optional title in bold text at the top of the page. 40 characters max.</mat-hint>
							</mat-form-field>
							@if (currentEditing.isDirty('title')) {
								<div class="no-wrap">
									<button mat-icon-button
											color="accent"
											(click)="saveTitle()"
											[matTooltip]="'Update Title'"
									>
										<mat-icon>save</mat-icon>
									</button>
									<button mat-icon-button
											color="accent"
											(click)="cancelTitle()"
											[matTooltip]="'Cancel'"
									>
										<mat-icon>cancel</mat-icon>
									</button>
								</div>
							}
						</div>
					</div>

					<div class="field-row align-items-center">
						<div class="field-row-title">
							Logo
						</div>
						<div class="field-row-element align-items-center">
							@if (currentLogo?.length > 0) {
								<img [src]="getBase64Image()"
									 class="logo"
									 alt="Logo"
								>
							}
							<input #fileInput hidden="hidden" type="file" onclick="this.value=null"
								   (change)="fileInputChange($event)" accept="image/png,image/jpeg"/>
							@if (currentEditing.isDirty('logo')) {
								<div class="no-wrap">
									<button mat-icon-button
											color="accent"
											(click)="saveLogo()"
											[matTooltip]="'Update Logo Image'"
									>
										<mat-icon>save</mat-icon>
									</button>
									<button mat-icon-button
											color="accent"
											(click)="cancelLogo()"
											[matTooltip]="'Cancel'"
									>
										<mat-icon>cancel</mat-icon>
									</button>
								</div>
							} @else {
								<button mat-icon-button
										color="accent"
										(click)="fileInput.click()">
									<mat-icon>upload</mat-icon>
								</button>
							}
						</div>
					</div>

					<div class="field-row">
						<div class="field-row-title">
							Logo Link
						</div>
						<div class="field-row-element">
							<mat-form-field appearance="outline" color="accent"
											class="w-100">
								<!--<mat-label>Image URL</mat-label>-->
								<input matInput
									   autocomplete="off"
									   type=text
									   placeholder="www.yourtown.org/snowinfo"
									   [(ngModel)]="currentImageUrl"
									   (ngModelChange)="currentEditing.changed('image-url')"
								>
								<mat-hint>Users who click on your logo will be taken to this URL</mat-hint>
							</mat-form-field>
							@if (currentEditing.isDirty('image-url')) {
								<div class="no-wrap">
									<button mat-icon-button
											color="accent"
											(click)="saveImageUrl()"
											[matTooltip]="'Save'"
									>
										<mat-icon>save</mat-icon>
									</button>
									<button mat-icon-button
											color="accent"
											(click)="cancelImageUrl()"
											[matTooltip]="'Cancel'"
									>
										<mat-icon>cancel</mat-icon>
									</button>
								</div>
							}
						</div>
					</div>

					<div class="settings-header-container">
						<div class="settings-header-title">
							<p>Content Block</p>
							<div class="settings-header-subtitle">Create a new set of content for the left panel</div>
						</div>
						<div class="settings-header-actions">
							<button mat-stroked-button (click)="createContentBlock()">Add Content Block</button>
						</div>
					</div>

					@if (settings.contentBlocks.length) {
						<div class="pt-15">
						<mat-radio-group class="headline-radio-buttons">
							<ng-container *ngFor="let contentBlock of settings.contentBlocks; let i = index">
								<app-content-block [contentBlock]="contentBlock"
												   [index]="i"
												   (contentBlockDeleted)="deleteContentBlock($event)"
												   (contentBlockUpdated)="updateContentBlock($event)"
												   (contentBlockSelected)="selectContentBlock($event)"
												   class="w-100"
								></app-content-block>
							</ng-container>
						</mat-radio-group>
						</div>
					}

				</app-toggle-expansion-panel>
			</div>
		</app-card>

		<app-card preCardTitle="Map Settings">
			<div *appCardContent>
				<app-toggle-expansion-panel
						[(expanded)]="settings.showMapContent"
						(expandedChange)="updatePublicPortalSettings()"
						panelTitle="Show Map Content"
						panelDescription="Turn off to hide all activities from the public">

					<app-toggle-expansion-panel
							[(expanded)]="settings.useMapThemes"
							(expandedChange)="onVehicleHistoryChange()"
							panelTitle="Map Themes"
							panelDescription="">

						<div class="ml-25">
							<app-toggle-expansion-panel
									[(expanded)]="settings.allowGpsTracks"
									(expandedChange)="onVehicleHistoryChange()"
									panelTitle="GPS Tracks"
							>
							</app-toggle-expansion-panel>

							<app-toggle-expansion-panel
									[(expanded)]="settings.allowPassCountMap"
									(expandedChange)="onVehicleHistoryChange()"
									panelTitle="Pass Count Map"
							>
							</app-toggle-expansion-panel>

							<app-toggle-expansion-panel
									[(expanded)]="settings.allowTimeMap"
									(expandedChange)="onVehicleHistoryChange()"
									panelTitle="Time Since Last Pass Map"
							>
							</app-toggle-expansion-panel>

							<app-toggle-expansion-panel
									[(expanded)]="settings.allowRouteStatusMap"
									(expandedChange)="onVehicleHistoryChange()"
									panelTitle="Route Status Map"
							>
								@if (settings.routeStatusLayerConfig) {
									<div class="ml-25">
										<mat-form-field appearance="outline" color="accent" class="wide no-hint">
											<mat-label>Route Configuration</mat-label>
											<mat-select
													[(ngModel)]="settings.routeStatusLayerConfig.routeConfigurationId"
													(ngModelChange)="updatePublicPortalSettings()">
												<mat-option *ngFor="let routeConfig of routeConfigurations"
															[value]="routeConfig.id">{{ routeConfig.name }}
												</mat-option>
											</mat-select>
										</mat-form-field>

										<div class="mt-10">
											<span>Threshold when route is still serviced</span>
											<mat-slider min="1"
														max="24"
														step="1"
														showTickMarks
														discrete
														[displayWith]="formatServicedThresholdLabel(RouteStatusLayerConfig.THRESHOLD_VALUES)"
														color="accent"
														style="width: 95%"
											>
												<input matSliderThumb
													   [(ngModel)]="servicedThresholdIndex"
													   (change)="onServicedThresholdChange($event)"
												>
											</mat-slider>
										</div>
									</div>
								}
							</app-toggle-expansion-panel>
						</div>
					</app-toggle-expansion-panel>

					<app-toggle-expansion-panel
							[(expanded)]="settings.showVehicleMarkers"
							(expandedChange)="updatePublicPortalSettings()"
							panelTitle="Vehicles"
							panelDescription="">

						<ng-container *ngFor="let group of vehicleGroups">
							<app-toggle-expansion-panel
									[(expanded)]="group.selected"
									(expandedChange)="updateExcludedVehicleGroups()"
									[panelTitle]="group.name"
									class="indented"
							>
							</app-toggle-expansion-panel>
						</ng-container>
					</app-toggle-expansion-panel>

					<app-toggle-expansion-panel
							[(expanded)]="settings.showVehicleLabels"
							(expandedChange)="updatePublicPortalSettings()"
							panelTitle="Show Vehicle Labels"
							class=""
					>
					</app-toggle-expansion-panel>

					<app-toggle-expansion-panel
							[(expanded)]="showAdditionalMapLayers"
							(expandedChange)="toggleAdditionalMapLayers()"
							panelTitle="Additional Map Layers"
							panelDescription="">

						<div class="section-header">
							<div class="section-title">Layers</div>
							<div class="section-buttons">
								<button mat-icon-button
										color="accent"
										(click)="clickedAddLayer()"
										[matTooltip]="'Add layer'"
								>
									<mat-icon>add</mat-icon>
								</button>
								@if (mapLayers && mapLayers.length > 0) {
									<button mat-icon-button
											color="accent"
											(click)="openMapPreview()"
											[matTooltip]="'View on map'"
									>
										<mat-icon>map</mat-icon>
									</button>
								}
							</div>
						</div>
						<ng-container *ngFor="let mapLayer of mapLayers; let i = index">
							<app-map-layer-configuration [mapLayer]="mapLayer"
														 (mapLayerDeleted)="onMapLayerDeleted(i)"
														 (mapLayerMoved)="onMapLayerMoveDown(i)"
														 (mapLayerToggled)="onMapLayerToggled()"
														 (mapLayerSaved)="onMapLayerSaved(mapLayer)"
														 (mapLayerCancelled)="onMapLayerCancelled(mapLayer)"
							></app-map-layer-configuration>
						</ng-container>
					</app-toggle-expansion-panel>

				</app-toggle-expansion-panel>
			</div>
		</app-card>

		<app-card preCardTitle="Embed Code">
			<div *appCardContent>
				<div class="embed-section wide">
					<div class="section-title">Use this code to add our public portal to you existing public portal website
					</div>
					<pre class="iframe-block"><code>{{ getIframeCode() }}</code>
			  </pre>
				</div>
			</div>
		</app-card>

	}
</div>
