<div class="flex-column gap-32">

    @if (isLoading) {
        <app-card preCardTitle="Cartegraph management">
            <div *appCardContent class="settings-header-container">
                <mat-spinner mode="indeterminate" diameter="30" class="mx-a my-15"></mat-spinner>
            </div>
        </app-card>
    } @else {

        <app-manage-cartegraph-header page="SUBMISSIONS"></app-manage-cartegraph-header>

        <app-card preCardTitle="Submissions to Cartegraph">

            <div *appCardContent>

                <div class="mt-8 mb-8">
                    <app-composite-filter-bar (onSearch)="doSearch($event)" [showBar]="true">
                        <app-dropdown-composite [title]="'Statuses'"
                                                [id]="'status'"
                                                [values]="processingStatuses"
                                                [multiple]="true"></app-dropdown-composite>
                        <app-text-search-composite [title]="'Search ID'" [id]="'search'"></app-text-search-composite>
                    </app-composite-filter-bar>
                </div>

                @if (!isLoading && (!tasks || tasks.data?.length <= 0)) {
                    <div>There are no data available with status: {{ processingStatus }}</div>
                }

                <ng-container *ngTemplateOutlet="table"></ng-container>
                <ng-container *ngTemplateOutlet="paginator"></ng-container>

            </div>
        </app-card>


    }
</div>


<ng-template #paginator>
    <!-- ?? note: cannot be in the ngIf statement! -->
    <mat-paginator showFirstLastButtons
                   (page)="onPage($event)"
                   [pageSize]="pageSize"
                   [pageIndex]="pageIndex"
                   [length]="itemCount"
                   aria-label="Select page">
    </mat-paginator>

</ng-template>

<ng-template #table>
    @if (!isLoading && tasks && tasks.data?.length > 0) {


        <table mat-table
               class="tasks-table"
               matSort [matSortActive]="sortActive" [matSortDirection]="sortDirection"
               [dataSource]="tasks.data"
               (matSortChange)="sortChange($event)">
            <ng-container matColumnDef="id">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> ...</th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngTemplateOutlet="status;context:{element:element}"></ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="taskType">
                <th mat-header-cell *matHeaderCellDef> Type</th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngTemplateOutlet="taskType;context:{type:element.taskType}"></ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="created">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> Created</th>
                <td mat-cell *matCellDef="let element"> {{ element.created | date:'short' }}</td>
            </ng-container>

            <ng-container matColumnDef="shift">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> Shift</th>
                <td mat-cell *matCellDef="let element">
                    @if (!!element.shiftId) {
                        <a target="_blank" [routerLink]="shiftLink(element.shiftId)"
                           aria-label="Open shift detail">{{ element.shiftId }}</a>
                    }
                </td>
            </ng-container>

            <ng-container matColumnDef="cgIdField">
                <th mat-header-cell *matHeaderCellDef> Cartegraph</th>
                <td mat-cell *matCellDef="let element" class="cgfields">
                    @for (task of element.cgResult?.tasks; track task.id) {
                        <a target="_blank" [href]="cgDetailLink(task.cgOid)" aria-label="Open Cartegraph detail">
                            {{ task.cgId }}
                            @if (task.type == 'CDOT') {
                                <sup>*</sup>,
                            }
                        </a>
                    }
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>...</th>
                <td mat-cell *matCellDef="let element">
                    @if (element.processStatus === 'FAIL' && (element.cgResult === null || element.cgResult?.tasks?.length() == 0)) {
                        <button mat-stroked-button
                                color="accent"
                                style="width:100% !important;"
                                (click)="reprocessSyncTask(element)">
                            re-process
                        </button>
                    }
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    }
</ng-template>

<ng-template #status datatype="CartegraphSyncTask" let-element="element">
    <button mat-icon-button
            color="accent"
            class="mt-8"
            (click)="messageDetail(element)">
        @switch (element.processStatus) {
            @case ('WAITING') {
                <mat-icon class="waiting" [title]="element.processStatus" aria-hidden="false"
                          [matBadge]="element.retryCount">hourglass_bottom
                </mat-icon>
            }
            @case ('FAIL') {
                <mat-icon class="fail" [title]="element.processStatus" aria-hidden="false"
                          [matBadge]="element.retryCount">error
                </mat-icon>
            }
            @case ('SUCCESS') {
                <mat-icon class="success" [title]="element.processStatus">check_circle</mat-icon>
            }
            @case ('NONE') {
                <mat-icon class="none" [title]="element.processStatus">block</mat-icon>
            }
        }
    </button>
</ng-template>

<ng-template #taskType datatype="string" let-type="type">
    @switch (type) {
        @case ('SHIFT_END_POSTPROCESS') {
            Shift
        }
        @case ('DVIR_PRE_INSPECTION') {
            Pre
        }
        @case ('DVIR_POST_INSPECTION') {
            Post
        }
        @case ('OBSERVATION') {
            Obs
        }
    }
</ng-template>

