import {Component, OnInit} from '@angular/core';
import {CartegraphManagementService} from '../../../../../data/cartegraph/cartegraph-management.service';
import {CartegraphConfiguration} from '../../../../../shared/models/cartegraph.model';


@Component({
  selector: 'app-cartegraph-main-setup',
  templateUrl: './cartegraph-main-setup.component.html',
  styleUrls: ['../../../settings-fields.scss',
    '../../../settings-common.scss'
  ],

})
export class CartegraphMainSetupComponent implements OnInit {

  isLoading = true;
  uiError: string;

  configuration: CartegraphConfiguration;
  // indicates if already data pre-fetched, and we can use dropdowns with some data downloaded
  datasetStatus = false;

  constructor(
    private cartegraphManagementService: CartegraphManagementService,
  ) {
  }

  ngOnInit() {
    this.isLoading = true;
    this.uiError = null;

    Promise.all([
      this.cartegraphManagementService.getConfiguration(),
      this.cartegraphManagementService.getDatasetStatus()
    ]).then(response => {
      const [cfgResponse, datasetResponse] = response;

      // configuration response
      if (cfgResponse.error) {
        this.uiError = cfgResponse.error;
      } else {
        this.configuration = cfgResponse.data ? cfgResponse.data : new CartegraphConfiguration();
      }

      // dataset response
      if (datasetResponse.error) {
        this.uiError = cfgResponse.error;
      } else {
        this.datasetStatus = true;
      }

    }).catch((error) => {
      console.log(error);
      this.uiError = error;
      this.isLoading = false;
    }).finally(() => {
      this.isLoading = false;
    });

  }

  onAccountVerified() {
    this.cartegraphManagementService.getDatasetStatus().then(response => {
      if (response.error) {
        this.uiError = response.error;
      } else {
        this.datasetStatus = true;
      }
    });
  }
}


