<div class="flex-column gap-32">
    @if (isLoading) {
        <app-card preCardTitle="Cartegraph feature management">
            <div *appCardContent class="settings-header-container">
                <mat-spinner mode="indeterminate" diameter="30" class="mx-a my-15"></mat-spinner>
            </div>
        </app-card>
    } @else {

        <app-card preCardTitle="Cartegraph management" icon="arrow_back" (iconClicked)="navigateBack()">
            <div *appCardContent class="settings-header-container">
                <div class="settings-header-title">
                    <p>Cartegraph features management</p>
                    <div class="settings-header-subtitle">
                        Enable or disable features and adjust them.
                        Features enable or disable parts of the integration; import, export, processing.
                    </div>
                </div>
            </div>
        </app-card>

        @for (feature of features; track feature.feature) {
            <app-card [inCardTitle]="feature.note">
                <div *appCardContent class="settings-header-container">
                    {{ feature.details }}
                </div>
                <div *appCardContent>
                    <ng-container *ngTemplateOutlet="featureTpl;context:{feature: feature}"></ng-container>
                </div>
            </app-card>


        }
    }
</div>


<ng-template #featureTpl let-feature="feature" let-parent="parent">

    <div class="field-row">
        <div class="field-row-title" [title]="feature.details">
            {{ feature.note }}<br/>
        </div>

        <!-- todo, use [children] here to render children nested features, if possible ?-->
        <div class="field-row-element">
            <app-row-with-toggle
                    [(ngModel)]="feature.checked"
                    [disabled]="!!parent && !parent.checked"
                    (ngModelChange)="changeFeature(feature, $event)"></app-row-with-toggle>
        </div>
    </div>

    @if (feature.childFeatures?.length > 0) {
        @for (child of feature.childFeatures; track child.feature; let last = $last) {
            <div class="ml-42">
                <ng-container
                        *ngTemplateOutlet="featureTpl;context{feature: child, parent: feature}"></ng-container>
            </div>
            @if (last) {
                <div class="mb-10"></div>
            }
        }
    }

    <ng-container *ngTemplateOutlet="featureSetup; context: {feature}"></ng-container>


</ng-template>

<ng-template #featureSetup let-feature="feature">

    @if (feature.feature === CartegraphFeature.SEND_SHIFT && feature.checked) {
        <app-cartegraph-settings-defaults-shift
                [taskDefaults]="configuration?.settings?.taskDefaults.shift"
                (onDefaultsChange)="changedShiftDefaults($event)"
        ></app-cartegraph-settings-defaults-shift>
    }

    @if (feature.feature === CartegraphFeature.SEND_OBSERVATION && feature.checked) {
        <app-cartegraph-settings-defaults-observation
                [taskDefaults]="configuration?.settings?.taskDefaults.observation"
                (onDefaultsChange)="changedObservationDefaults($event)"
        ></app-cartegraph-settings-defaults-observation>

        <ng-container *ngTemplateOutlet="observationExcludes"></ng-container>
    }

</ng-template>


<ng-template #observationExcludes>
    <div>Exclude observations from sending into Cartegraph.</div>
    <app-cartegraph-settings-observations [configuration]="configuration"
                                          (changedObservations)="changedObservations($event)"></app-cartegraph-settings-observations>
</ng-template>

