import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CartegraphConfiguration, CartegraphObservationSettings} from '../../../../../shared/models/cartegraph.model';
import {ObservationManagementService} from '../../../../../data/observations/observation-management.service';
import {ObservationTypeGroup} from '../../../../../shared/models/observation-group';
import {firstValueFrom} from 'rxjs';


@Component({
  selector: 'app-cartegraph-settings-observations',
  templateUrl: './cartegraph-settings-observations.component.html',
  styleUrls: ['../../../settings-fields.scss',
    '../../../settings-common.scss']
})
export class CartegraphSettingsObservations implements OnInit {

  @Output()
  changedObservations = new EventEmitter<CartegraphObservationSettings>();

  @Input()
  configuration: CartegraphConfiguration;

  uiError: string;
  isLoading: boolean;

  observationTypeGroups: ObservationTypeGroupSelection[] = [];

  constructor(private observationManagementService: ObservationManagementService,
  ) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.initObservations();
    this.isLoading = false;
  }

  initObservations() {
    firstValueFrom(this.observationManagementService.getObservationTypeGroups())
      .then(result => {
        if (result.data != null && result.data.length > 0) {
          this.observationTypeGroups = result.data.map(group => {
            const excluded = this.configuration.settings?.observations?.excludedObservationGroups?.find(f => f == group.id) != undefined;
            // sort types by title
            if (group.observationTypes != null && group.observationTypes.length > 0) {
              group.observationTypes = group.observationTypes.sort((a, b) => a.title.localeCompare(b.title));
            }
            return new ObservationTypeGroupSelection(group, excluded);
          })
        }
      })
      .catch(error => {
        const msg = 'Error while loading data from server';
        console.error(`${msg} :: ${error}`);
      }).finally(() => {
        this.isLoading = false;
      }
    );
  }

  updateObservations() {
    const observations = this.configuration.settings.observations ?? new CartegraphObservationSettings();
    observations.excludedObservationGroups = this.observationTypeGroups
      .filter(f => f.excluded)
      .map(g => g.group.id);
    // let parent process/save this
    this.changedObservations.emit(observations);
  }

}

export class ObservationTypeGroupSelection {
  constructor(
    public group: ObservationTypeGroup,
    public excluded: boolean
  ) {
  }
}