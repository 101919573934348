<!--<div class="legend-activity-filter" *ngIf="!!appliedFilter">
    <div>
        <span class="activity-name">{{ appliedFilter.label }}</span>&nbsp;<span>activity filter applied</span>
    </div>
    <button mat-icon-button
            class="configure-button"
            (click)="clearFilter()"
    >
        <mat-icon>close</mat-icon>
    </button>
</div>-->

<div class="legend">
    <div class="legend-header">
        <span>Last Pass</span>
        <button mat-icon-button
                class="configure-button"
                (click)="openConfiguration()"
                *ngIf="isAdmin"
                matTooltip="Configure Time Map"
        >
            <mat-icon>settings</mat-icon>
        </button>
    </div>
    <div class="legend-subtitle">
        <span>Time since road segment last serviced</span>
    </div>
    <app-legend-class-view
            *ngIf="!!userLegendSettings"
            [layerType]="userLegendSettings"
            [editable]="false"
    >
    </app-legend-class-view>
</div>
