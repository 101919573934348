<app-card preCardTitle="Cartegraph management">
    <div *appCardContent class="settings-header-container">
        <div class="settings-header-title">
            <div class="settings-header-subtitle">Manage Cartegraph settings.</div>
        </div>
        <div class="settings-header-actions">
            @if (page !== PageType.SUBMISSIONS) {
                <button
                        (click)="navigateToSubmissions()"
                        mat-stroked-button
                        color="accent">
                    <span>Submissions</span>
                </button>
            }
            @if (page !== PageType.IMPORTS) {
                <button
                        (click)="navigateToImports()"
                        mat-stroked-button
                        color="accent">
                    <span>Imports</span>
                </button>
            }
            @if (page !== PageType.SETTINGS) {
                <button
                        (click)="navigateToSettings()"
                        mat-stroked-button
                        color="accent">
                    <span>Settings</span>
                </button>
            }
        </div>
    </div>
</app-card>

