import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import {VehiclesService} from '../../../../../data/vehicles/vehicles.service';
import {FeatureFlagEnum} from '../../../../../shared/models/configuration.model';
import {ConfigurationService} from '../../../../../configuration/configuration.service';
import {VehicleGroupModel} from '../model/VehicleGroupModel';
import {CreateVehicleGroupDialogInput} from '../model/CreateVehicleGroupDialogInput';
import {ToastService} from '../../../../../shared/services/toast.service';
import {DialogCreateVehicleCategoryComponent} from '../dialogs-vehicle-group/vehicle-group-dialog-components';
import {VehicleCategoryModel} from '../../../../../shared/models/vehicle';
import {Subscription} from 'rxjs';
import {Router, RouterLink} from '@angular/router';
import {
  CardContentDirective,
  CardContentRawDirective
} from '../../../../../shared/components/card/card-content.directive';
import {CardComponent} from '../../../../../shared/components/card/card.component';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {MatListItem, MatNavList} from '@angular/material/list';
import {MatIcon} from '@angular/material/icon';
import {MatDivider} from '@angular/material/divider';
import {MatButton} from '@angular/material/button';
import { RootRoute, SettingsRoute } from '../../../../../shared/models/angular-routing';

@Component({
  standalone: true,
  selector: 'app-vehicle-group-list',
  templateUrl: './vehicle-group-list.component.html',
  styleUrls: ['./vehicle-group-list.component.scss',
    '../../../../settings/settings-fields.scss',
    'vehicle-group-list.component.scss',
    '../../../settings-common.scss'
  ],
  imports: [
    CardComponent,
    CardContentDirective,
    MatProgressSpinner,
    MatNavList,
    MatIcon,
    MatDivider,
    MatListItem,
    MatButton,
    CardContentRawDirective,
    RouterLink,

  ]
})
export class VehicleGroupListComponent implements OnInit, OnDestroy {

  isLoading = true;

  isCartegraphEnabled = false;
  vehicleGroups: VehicleCategoryModel[] = [];

  private configurationSubscription: Subscription;

  RootRoute = RootRoute;
  SettingsRoute = SettingsRoute;

  constructor(public dialog: MatDialog,
              private readonly toastService: ToastService,
              private readonly vehicleService: VehiclesService,
              private readonly configurationService: ConfigurationService,
              private readonly router: Router,
  ) {}

  ngOnInit() {
    this.configurationSubscription = this.configurationService.sharedConfigurationModel.subscribe(model => {
      if (model) {
        this.isCartegraphEnabled = this.configurationService
          .hasFeatureFlag(model?.featureFlags, FeatureFlagEnum.CartegraphIntegration);
      }
      this.reload();
    });
  }

  ngOnDestroy() {
    this.configurationSubscription?.unsubscribe();
  }

  showAddNewVehicleGroupDialog() {
    const dialogRef = this.dialog.open(DialogCreateVehicleCategoryComponent, {
      width: '450px',
      data: {
        model: new VehicleGroupModel(),
        isImported: false
      } as CreateVehicleGroupDialogInput
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        const newCategory = dialogResult as VehicleCategoryModel;
        this.router.navigate([`/${RootRoute.SETTINGS}`, SettingsRoute.MANAGE_VEHICLES, 'group', newCategory.id])
          .then();
      }
    });
  }

  private reload() {
    this.isLoading = true;

    this.vehicleService.getVehicleCategories().then(categoriesResponse => {
      this.vehicleGroups = categoriesResponse.data;
    })
      .catch(error => {
        const msg = 'Error while loading data from server';
        this.toastService.long(msg);
        console.error(`${msg} :: ${error}`);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

}

