<div class="body-container">
  <div class="top-with-content">
    <app-navigation
    ></app-navigation>
    <div class="content-container" role="main">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-right-drawer>
  </app-right-drawer>
</div>
