import {CsvDataLoaderAction} from './csv-data-loader-action';

export class UserModel {
  email: string;
  givenName: string;
  familyName: string;
  password: string;
  roles: string[];
  phoneNumber: string;
}

export class UserModelView extends UserModel {
  roleLabels: string;
  mainRole: string;
  mainRoleLabel: string;
  isDriverOnly: boolean;
  canUpdateInfo: boolean;
  canUpdatePassword: boolean;
  canDelete: boolean;

  static preload(model: UserModelView, cartegraphEnabled: boolean): UserModelView {
    model.roleLabels = UserModelUtil.getResponsibilityLabels(model.roles);
    model.mainRole = UserModelUtil.getMainRole(model.roles);
    model.mainRoleLabel = UserModelUtil.getRoleLabel(model.mainRole);
    model.isDriverOnly = model.roles.length === 1 && model.roles.indexOf(UserManagement.ROLE_DRIVER) > -1;
    // currently enable actions for all except CG, #30497: Dashboard | Set-up | Users | Can't Change a Driver to a Supervisor/Admin
    // enable change password if user is supervisor or admin
    const canChangePassword = !cartegraphEnabled && (model.roles.length > 0 && (
      model.roles.indexOf(UserManagement.ROLE_PORTAL_ADMIN) > -1 ||
      model.roles.indexOf(UserManagement.ROLE_PORTAL_USER) > -1
    ));
    model.canUpdateInfo = true;
    model.canUpdatePassword = canChangePassword;
    model.canDelete = !cartegraphEnabled;
    return model;
  }
}

export class UserRequestModel extends UserModel {
  setTemporaryPassword?: boolean;

  static fromUserModel(model: UserModel, setTemporaryPassword: boolean): UserRequestModel {
    return {...model, setTemporaryPassword};
  }
}

export class UserModelUtil {

  static getMainRole(roles: string[]): string {
    if (roles.indexOf(UserManagement.ROLE_PORTAL_ADMIN) > -1) {
      return UserManagement.ROLE_PORTAL_ADMIN;
    } else if (roles.indexOf(UserManagement.ROLE_PORTAL_USER) > -1) {
      return UserManagement.ROLE_PORTAL_USER;
    } else if (roles.indexOf(UserManagement.ROLE_DRIVER) > -1) {
      return UserManagement.ROLE_DRIVER;
    }
    return '';
  }

  static getResponsibilityLabels(roles: string[]): string {
    return roles.map(role => {
      switch (role) {
        case UserManagement.ROLE_DRIVER:
          return UserManagement.ROLE_DRIVER_LABEL;
        case UserManagement.ROLE_PORTAL_USER:
          return UserManagement.ROLE_PORTAL_USER_LABEL;
        case UserManagement.ROLE_PORTAL_ADMIN:
          return UserManagement.ROLE_PORTAL_ADMIN_LABEL;
      }
      return '';
    }).sort().join(',');
  }

  static getRoleLabel(role: string) {
    switch (role) {
      case UserManagement.ROLE_DRIVER:
        return UserManagement.ROLE_DRIVER_LABEL;
      case UserManagement.ROLE_PORTAL_USER:
        return UserManagement.ROLE_PORTAL_USER_LABEL;
      case UserManagement.ROLE_PORTAL_ADMIN:
        return UserManagement.ROLE_PORTAL_ADMIN_LABEL;
      case UserManagement.ROLE_SYSTEM:
        return UserManagement.ROLE_SYSTEM_LABEL;
      default:
        return 'UNKNOWN';
    }
  }
}

export class UpdateUserPasswordModel {
  constructor(
    public email: string,
    public password: string
  ) {
  }
}

/*csv import user*/

export class CsvUserImportRecord {
  index: number;
  row: CsvImportedUser;
  dbData: UserImportDbData;
  errors?: string[];
  action: CsvDataLoaderAction;
  /*internal UI fields and data*/
  selected: boolean;
  rolesHash: string;
}

class UserImportDbData {
  user?: UserModel;
}

export class CsvImportedUser {
  role: string;
  givenName: string;
  familyName: string;
  email: string;
  phoneNumber: string;
}

export class UserType {
  name: string;
  label: string;
}

export class UserManagement {
  static readonly ROLE_DRIVER = 'Driver';
  static readonly ROLE_DRIVER_LABEL = 'Driver';
  static readonly ROLE_PORTAL_USER = 'Supervisor';
  static readonly ROLE_PORTAL_USER_LABEL = 'Supervisor';
  static readonly ROLE_PORTAL_ADMIN = 'Admin';
  static readonly ROLE_PORTAL_ADMIN_LABEL = 'Admin';
  static readonly ROLE_SYSTEM = 'System';
  static readonly ROLE_SYSTEM_LABEL = 'System';
}
