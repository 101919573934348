import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CartegraphShiftTaskDefault} from '../../../../../shared/models/cartegraph.model';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CartegraphManagementService} from '../../../../../data/cartegraph/cartegraph-management.service';
import {CartegraphSettingsDefaults} from './cartegraph-settings-defaults.component';


@Component({
  selector: 'app-cartegraph-settings-defaults-shift',
  templateUrl: './cartegraph-settings-defaults-shift.component.html',
  styleUrls: ['../../../settings-fields.scss',
    '../../../settings-common.scss'],
})
export class CartegraphSettingsDefaultsShift extends CartegraphSettingsDefaults implements OnInit {


  @Output()
  onDefaultsChange = new EventEmitter<CartegraphShiftTaskDefault>(false);

  @Input()
  taskDefaults: CartegraphShiftTaskDefault;

  uiError: string;
  form: UntypedFormGroup;

  constructor(
    protected cartegraphManagementService: CartegraphManagementService,
    private fb: UntypedFormBuilder,) {
    super(cartegraphManagementService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  canBeSaved() {
    return (this.form?.valid ?? true);
  }

  protected initForm() {
    const shiftDefaults = this.taskDefaults as CartegraphShiftTaskDefault

    // init form
    this.form = this.fb.group({
      priority: this.fb.control(shiftDefaults.priority, [Validators.required]),
      status: this.fb.control(shiftDefaults.status, [Validators.required]),
      activity: this.fb.control(shiftDefaults.activity),
      workOrder: this.fb.control(shiftDefaults.workOrder),
      workCompletedFor: this.fb.control(shiftDefaults.workCompletedFor),
      workCompletedForCDOT: this.fb.control(shiftDefaults.workCompletedForCDOT),
      sectorAreaStatus: this.fb.control(shiftDefaults.sectorAreaStatus),
      maxHoursFallback: this.fb.control(shiftDefaults.maxLabourWorkHoursFallback),
      department: this.fb.control(shiftDefaults.department),
    });

    // notify changes
    this.form.valueChanges.subscribe(() => {
      const {
        priority,
        status,
        activity,
        workCompletedFor,
        workCompletedForCDOT,
        sectorAreaStatus,
        maxHoursFallback,
        workOrder,
        department
      } = this.form.value

      Object.assign(shiftDefaults, {
        priority,
        status,
        activity,
        workCompletedFor,
        workCompletedForCDOT,
        sectorAreaStatus,
        workOrder,
        department,
        maxLabourWorkHoursFallback: maxHoursFallback,
      });

      // only notify if object is valid and can be saved
      if (this.canBeSaved()) {
        this.onDefaultsChange.emit(shiftDefaults);
      }

    });

  }

}
