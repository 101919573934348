import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CgImportTask} from '../../../../../shared/models/cartegraph.model';

@Component({
  template: `<h1 mat-dialog-title>Processing detail</h1>
  <mat-dialog-content class="dialog">
      <div>ID: {{ data.id }}</div>
      <div>Created: {{ data.created | date: 'medium' }}</div>
      <div>Finished: {{ data.finished | date: 'medium' }}</div>
      <div>Type: {{ data.taskStatus }}</div>
      <h2>Stacktrace</h2>
      {{ data.taskMessage }}
  </mat-dialog-content>
  <mat-dialog-actions>
      <button mat-button mat-dialog-close color="accent">Close</button>
  </mat-dialog-actions>`
})
export class CartegraphTasksImportDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<CartegraphTasksImportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CgImportTask,
  ) {
  }
}

@Component({
  template: `<h1 mat-dialog-title>Import configuration</h1>
  <mat-dialog-content class="dialog">
      <p>
          <mat-icon>cloud_download</mat-icon>
          Do you want fetch all relevant data from Cartegraph?
      </p>
      <app-row-with-toggle primaryText="Fetch all data" [(ngModel)]="fetchAllData"></app-row-with-toggle>
  </mat-dialog-content>
  <mat-dialog-actions>
      <button mat-stroked-button [mat-dialog-close]="{import: true, fetchAllData}" color="accent">Import</button>
      <button mat-stroked-button mat-dialog-close color="accent">Close</button>
  </mat-dialog-actions>`
})
export class CartegraphTasksImportConfigDialogComponent {
  fetchAllData = false;

  constructor(
    public dialogRef: MatDialogRef<CartegraphTasksImportConfigDialogComponent>,
  ) {
  }
}
