<div class="shift-lookup-container">

    <div class="report-menu">
        <div class="report-name-and-desc">
            <div class="report-name">
                Shift Lookup
            </div>
            <div class="report-description">
                Browse vehicles and drivers to review past shifts
            </div>
        </div>

        <div class="report-menu__actions">
            <ng-container *ngIf="isAdmin && selectedCategory === ShiftLookupCategory.VEHICLES">
                <button mat-icon-button
                        [routerLink]="['/' + RootRoute.SETTINGS, SettingsRoute.MANAGE_VEHICLES]"
                        [matTooltip]="'Manage Vehicles'"
                >
                    <mat-icon>directions_car</mat-icon>
                </button>
            </ng-container>
            <ng-container *ngIf="isAdmin && selectedCategory === ShiftLookupCategory.DRIVERS">
                <button mat-icon-button
                        [routerLink]="['/' + RootRoute.SETTINGS, SettingsRoute.MANAGE_USERS]"
                        [matTooltip]="'Manage Drivers'"
                >
                    <mat-icon>person</mat-icon>
                </button>
            </ng-container>

            <app-filter-button [appliedFiltersCount]="appliedFiltersCount"
                               [active]="showingFilterBar"
                               (toggled)="toggleFilter()"
            ></app-filter-button>

            <button
                    mat-icon-button
                    (click)="closeInsight()"
                    [matTooltip]="'Close'"
            >
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <app-filter-bar
      class="header-container__filters"
      [origin]="'shiftLookup'"
      [showingFilterBar]="showingFilterBar"
      [useDateFilter]="true"
      (dateFilterChanged)="dateFilterChanged($event)"
      (appliedFiltersChanged)="appliedFiltersCount = $event"
    >
    </app-filter-bar>

    <div class="content">
        <div class="selector-container">
            <div class="selector-container__type">
                <app-vertical-icon-button
                    [input]="vehicleFilterButton"
                ></app-vertical-icon-button>
                <app-vertical-icon-button
                        [input]="driverFilterButton"
                ></app-vertical-icon-button>
            </div>
            <div class="selector-container__list">
                <div class="selector-container__list-inner" tabindex="0">
                    <ng-container *ngFor="let item of items">
                        <app-action-menu-item
                            [input]="item">
                        </app-action-menu-item>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="selector-cards-divider">
        </div>
        <div class="shift-cards-container" (scroll)="onScroll($event)">
            <div *ngIf="!!selectedId" class="shift-cards-container-inner">
                <mat-spinner *ngIf="shiftsLoading"></mat-spinner>
                <ng-container *ngFor="let shift of shifts">
                    <app-shift-detail-card
                            [configuration]="configuration"
                            [shift]="shift"
                            [source]="selectedCategory"
                            (shiftDeleted)="handleShiftDeleted()"
                    ></app-shift-detail-card>
                </ng-container>
            </div>
            <ng-container *ngIf="!selectedId">

                <app-blank-page
                        class="centered__page__container"
                        headline="No Driver or Vehicle Selected"
                        text="Select a Vehicle or Driver from the list on the left to see all associated shifts."
                >
                </app-blank-page>
            </ng-container>
        </div>
        <!--<div class="stats-container">
            <app-vehicle-stats-card
                    *ngIf="selectedCategory === ShiftLookupCategory.VEHICLES && !!selectedItem"
                    [configuration]="configuration"
                    [vehicle]="selectedItem"
                    [dateFilter]="dateFilter"
            ></app-vehicle-stats-card>
            <app-driver-stats-card
                    *ngIf="selectedCategory === ShiftLookupCategory.DRIVERS && !!selectedItem"
                    [configuration]="configuration"
                    [driver]="selectedItem"
                    [dateFilter]="dateFilter"
            ></app-driver-stats-card>
        </div>-->
    </div>
</div>
