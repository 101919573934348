import {Component, OnDestroy, ViewChild} from '@angular/core';
import {CardComponent} from '../../../../../shared/components/card/card.component';
import {CardContentDirective} from '../../../../../shared/components/card/card-content.directive';
import {ActivatedRoute, Router} from '@angular/router';
import {InspectionFormsService} from '../../../../../data/inspection-forms/inspection-forms.service';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {MatButton} from '@angular/material/button';
import {FormsModule} from '@angular/forms';
import {ToastService} from '../../../../../shared/services/toast.service';
import {DvirForm} from '../model/dvir-form';
import {
  InspectionFormSectionsEditComponent
} from './inspection-form-sections-edit/inspection-form-sections-edit.component';
import {
  InspectionFormSectionsPreviewComponent
} from './inspection-form-sections-preview/inspection-form-sections-preview.component';
import {DvirSection} from '../model/dvir-section';
import {DvirQuestion} from '../model/dvir-question';
import { RootRoute, SettingsRoute } from 'src/app/shared/models/angular-routing';

@Component({
  selector: 'app-inspection-form-questions',
  standalone: true,
  imports: [
    CardComponent,
    CardContentDirective,
    MatProgressSpinner,
    MatButton,
    FormsModule,
    InspectionFormSectionsEditComponent,
    InspectionFormSectionsPreviewComponent,

  ],
  templateUrl: './inspection-form-questions.component.html',
  styleUrls: ['./inspection-form-questions.component.scss', '../../../../settings/settings-common.scss']
})
export class InspectionFormQuestionsComponent implements OnDestroy {

  subscriptions = []

  loading = false;
  inspectionFormId?: number;

  inspectionForm: DvirForm;
  sectionsToEdit: DvirSection[] = [];

  editing = false;
  saving = false;

  @ViewChild(InspectionFormSectionsEditComponent)
  sectionsEditComponent: InspectionFormSectionsEditComponent

  valid: boolean = true;

  RootRoute = RootRoute;
  SettingsRoute = SettingsRoute;

  constructor(private readonly router: Router,
              private readonly activatedRoute: ActivatedRoute,
              private readonly inspectionFormService: InspectionFormsService,
              private readonly toastService: ToastService) {
    this.subscriptions.push(this.activatedRoute.params.subscribe(params => {
      this.inspectionFormId = +params['inspectionFormId'];
      this.loadInspectionForm();
    }))
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  goBack() {
    this.router.navigate([`/${RootRoute.SETTINGS}`, SettingsRoute.INSPECTION_FORMS, this.inspectionFormId]).then();
  }

  saveInspectionForm() {
    this.saving = true;
    this.inspectionForm.sections = this.sectionsToEdit;
    this.inspectionFormService.saveForm(this.inspectionForm.toDto()).then(result => {
      this.editing = false
      this.toastService.short('Form questions have been saved')
      if (result.id !== this.inspectionFormId) {
        this.router.navigate([`/${RootRoute.SETTINGS}`, SettingsRoute.INSPECTION_FORMS, result.id, 'questions'])
          .then()
      } else {
        this.inspectionForm = DvirForm.fromDto(result);
      }
    }).finally(() => {
      this.saving = false;
    })
  }

  addSection() {
    this.sectionsEditComponent.addSection();
  }

  addGroup() {
    this.sectionsEditComponent.addGroup();
  }

  addQuestion() {
    this.sectionsEditComponent.addQuestion();
  }

  startQuestionsEdit() {
    this.sectionsToEdit = structuredClone(this.inspectionForm.sections);
    if (this.sectionsToEdit.length === 1 &&
      this.sectionsToEdit[0].groups.length === 1 &&
      this.sectionsToEdit[0].groups[0].questions.length === 0) {
      this.sectionsToEdit[0].groups[0].questions.push(new DvirQuestion(undefined, 1));
    }
    this.editing = true;
  }

  private loadInspectionForm() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.inspectionFormService.getForm(this.inspectionFormId).then(form => {
      this.inspectionForm = DvirForm.fromDto(form);
    }).finally(() => {
      this.loading = false;
    });
  }
}
