<form [formGroup]="formGroup">
@if(isLoading) {
	<mat-spinner mode="indeterminate" diameter="30" class="mx-a"></mat-spinner>
}
@else {
	<app-card>
		<div *appCardContentRaw>
			<app-card-editable-heading icon="arrow_back"
									   formControlName="name"
									   (iconClicked)="navigateBack()">
			</app-card-editable-heading>

			<h2 class="pl-16 py-16 mb-0">
				Vehicles
			</h2>
			<mat-nav-list>
				<a mat-list-item
				   [routerLink]="['vehicles']">
					<div matListItemTitle class="indent">
						Manage vehicles in this group
						<div class="text-subtitle">
							Add, Remove and Edit vehicles in this group
						</div>
					</div>
					<div matListItemMeta>
						<mat-icon>arrow_right</mat-icon>
					</div>
				</a>
			</mat-nav-list>

			<div class="m-16">
				<h2>Group Settings</h2>
				<div class="indent">
					<app-row-with-select primaryText="Stationary alert"
										 secondaryText="Trigger an alert when no motion detected after"
										 [list]="stationaryAlertList"
										 listLabel="label"
										 listId="id"
										 nullLabel="None"
										 formControlName="maxStationaryTime">
					</app-row-with-select>

					<mat-divider class="mt-8"></mat-divider>

					<app-row-with-toggle
							primaryText="Allow drivers to self assign routes"
							formControlName="allowRouteSelfAssign"
							[children]="allAllowedRouteConfigs"
							[checkedChildren]="vehicleGroup.allowedRouteConfigIds"
							(checkedChildrenChange)="formUpdate({allowedRouteConfigIds: $event})">
					</app-row-with-toggle>
					@if (formGroup.hasError('routesMissing')) {
						<mat-error class="indent mb-8">Select at least one route</mat-error>
					}

					<mat-divider></mat-divider>

					<app-row-with-toggle
							primaryText="Allow drivers create observations"
							formControlName="allowReportObservations"
							[children]="allObservationTypeGroups"
							[(checkedChildren)]="vehicleGroup.observationTypeGroupIds"
							(checkedChildrenChange)="formUpdate({observationTypeGroupIds: $event})">
					</app-row-with-toggle>
					@if (formGroup.hasError('observationTypeMissing')) {
						<mat-error class="indent mb-8">Select at least one observation type</mat-error>
					}

					<mat-divider></mat-divider>

					<app-row-with-toggle primaryText="Require trip inspections"
										 formControlName="useDvir">
					</app-row-with-toggle>

					<div [style.display]="formGroup.controls['useDvir'].value ? 'block' : 'none'" class="indent">
						<div>
							<app-row-with-select
									primaryText="Pre-trip inspection form"
									[list]="allInspectionForms"
									listLabel="name"
									nullLabel="None"
									listId="id"
									formControlName="preTripFormId">
							</app-row-with-select>
						</div>

						<div>
							<app-row-with-select
									primaryText="Post-trip inspection form"
									[list]="allInspectionForms"
									listLabel="name"
									nullLabel="None"
									listId="id"
									formControlName="postTripFormId">
							</app-row-with-select>
						</div>
						@if (formGroup.hasError('dvirMissing')) {
							<mat-error>Select one of the pre-trip or post-trip inspection form</mat-error>
						}
					</div>
				</div>
			</div>

			<div class="m-16">
				<h2>Mobile Application Default Settings</h2>
				<div class="indent">
					<app-row-with-toggle primaryText="&quot;Show Other Vehicles&quot; on by default"
										 formControlName="showVehicles">

					</app-row-with-toggle>
					<mat-divider></mat-divider>
					<app-row-with-toggle primaryText="&quot;Show Other Vehicle History&quot; on by default"
										 formControlName="showVehicleHistory">
					</app-row-with-toggle>
					<mat-divider></mat-divider>
					<app-row-with-toggle primaryText="&quot;Show Other Vehicle Observations&quot; on by default"
										 formControlName="showVehicleObservations">
					</app-row-with-toggle>
				</div>
			</div>
		</div>

		@if (!state.isImported) {
			<div *appCardContent class="mt-spacing">
				<h2>Delete Vehicle Group</h2>
				<div class="settings-header-container">
					<div class="settings-header-title indent">
						Delete this vehicle group. All vehicles within this group would be deleted.
					</div>
					<div class="settings-header-actions">
						<button mat-stroked-button
								(click)="deleteGroup()">
							Delete Vehicle Group
						</button>
					</div>
				</div>
			</div>
		}
	</app-card>
}
</form>
