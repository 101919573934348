<div class="flex-column gap-32">

    @if (isLoading) {
        <mat-spinner mode="indeterminate" diameter="30" class="mx-a my-15"></mat-spinner>
    } @else {

        <app-card preCardTitle="Cartegraph management" icon="arrow_back" (iconClicked)="navigateBack()">
            <div *appCardContent class="settings-header-container">
                <div class="settings-header-title">
                    <p>Manage Cartegraph import filters</p>
                    <div class="settings-header-subtitle">
                        Adjust driver, vehicle, material and other import filters.
                    </div>
                </div>
            </div>

            <div *appCardContent>
                <form [formGroup]="updateForm">
                    <ng-container *ngTemplateOutlet="filterDriver;context:{form: updateForm}"></ng-container>
                    <ng-container *ngTemplateOutlet="filterVehicle;context:{form: updateForm}"></ng-container>
                    <ng-container *ngTemplateOutlet="filterVehicleEquipment;context:{form: updateForm}"></ng-container>
                    <ng-container *ngTemplateOutlet="filterVehicleGroups;context:{form: updateForm}"></ng-container>
                    <ng-container *ngTemplateOutlet="filterWorkOrder;context:{form: updateForm}"></ng-container>
                    <ng-container *ngTemplateOutlet="filterActivity;context:{form: updateForm}"></ng-container>
                    <ng-container *ngTemplateOutlet="filterMaterial;context:{form: updateForm}"></ng-container>
                    <ng-container *ngTemplateOutlet="filterMaterialLocation;context:{form: updateForm}"></ng-container>
                    <ng-container *ngTemplateOutlet="filterMaterialMatching;context:{form: updateForm}"></ng-container>
                </form>
            </div>
        </app-card>
    }

</div>

<ng-template #filterDriver let-form="form">
    <!-- driver filter-->
    <div class="field-row" [formGroup]="form">
        <div class="field-row-title">
            Drivers import filter
        </div>
        <div class="field-row-element">
            <mat-form-field appearance="outline" color="accent" class="form-input">
                <input type="text" matInput
                       placeholder="Driver filter e.g. Department in ('Parks', 'Streets')"
                       [formControlName]="Controls.DRIVER_FILTER">
                @if (form.controls[Controls.DRIVER_FILTER].hasError('required')) {
                    <mat-error>
                        Field is required
                    </mat-error>
                }
            </mat-form-field>
            <button
                    mat-stroked-button
                    color="accent"
                    class="ml-10"
                    (click)="testLaborFilter()"
                    [disabled]="!(form.controls[Controls.DRIVER_FILTER].valid) || isTesting.driver || isLoading">
                @if (isTesting.driver) {
                    <mat-spinner diameter="20" color="accent" class="spinner"></mat-spinner>
                } @else {
                    <mat-icon>published_with_changes</mat-icon>
                }
                Test
            </button>
        </div>
    </div>

    @if (laborFilterResult?.length > 0) {
        <div class="field-row mb-25">
            <div class="form-test">
                <ul class="scrollable">
                    @for (labor of laborFilterResult; track labor.IDField) {
                        <li>{{ labor.IDField }}
                            , {{ labor.FullNameField }}
                            , {{ labor.EmailAddressField }}, {{ labor.DepartmentField }}
                        </li>
                    }
                </ul>
            </div>
        </div>
    }
</ng-template>


<ng-template #filterVehicle let-form="form">
    <!-- vehicle filter-->
    <div class="field-row" [formGroup]="form">
        <div class="field-row-title">
            Vehicle import filter
        </div>
        <div class="field-row-element">
            <mat-form-field appearance="outline" color="accent" class="form-input">
                <input type="text" matInput
                       placeholder="Vehicle filter e.g. Description in ('SUV', 'Pickup')"
                       [formControlName]="Controls.VEHICLE_FILTER">
                @if (form.controls[Controls.VEHICLE_FILTER].hasError('required')) {
                    <mat-error>
                        Field is required
                    </mat-error>
                }
            </mat-form-field>
            <button
                    mat-stroked-button
                    color="accent"
                    class="ml-10"
                    (click)="testVehicleFilter()"
                    [disabled]="!(form.controls[Controls.VEHICLE_FILTER].valid) || isTesting.vehicle || isLoading">
                @if (isTesting.vehicle) {
                    <mat-spinner diameter="20" color="accent" class="spinner"></mat-spinner>
                } @else {
                    <mat-icon>published_with_changes</mat-icon>
                }
                Test
            </button>
        </div>
    </div>

    @if (laborFilterResult?.length > 0) {
        <div class="field-row mb-25">
            <div class="form-test">
                <ul class="scrollable">
                    @for (vehicle of vehicleFilterResult; track vehicle.IDField) {
                        <li>{{ vehicle.IDField }}
                            , {{ vehicle.ManufacturerField }}, {{ vehicle.ModelField }}
                            , {{ vehicle.SerialVINNumberField }}, {{ vehicle.ClassificationField }}
                            , {{ vehicle.DepartmentField }}
                        </li>
                    }
                </ul>
            </div>
        </div>
    }

</ng-template>

<ng-template #filterWorkOrder let-form="form">
    <!-- work orders filter -->
    @if (hasWorkOrdersFeature()) {
        <div class="field-row" [formGroup]="form">
            <div class="field-row-title">
                Work Order Filter
            </div>
            <div class="field-row-element">
                <mat-form-field appearance="outline" color="accent" class="form-input">
                    <input type="text" matInput
                           placeholder="Driver filter e.g. Department in ('Parks', 'Streets')"
                           [formControlName]="Controls.WORK_ORDER_FILTER">
                    @if (form.controls[Controls.WORK_ORDER_FILTER].hasError('required')) {
                        <mat-error>
                            Field is required
                        </mat-error>
                    }
                </mat-form-field>
                <button
                        mat-stroked-button
                        color="accent"
                        class="ml-10"
                        (click)="testWorkOrderFilter()"
                        [disabled]="!(form.controls[Controls.WORK_ORDER_FILTER].valid) || isTesting.workorder || isLoading">
                    @if (isTesting.workorder) {
                        <mat-spinner diameter="20" color="accent" class="spinner"></mat-spinner>
                    } @else {
                        <mat-icon>published_with_changes</mat-icon>
                    }
                    Test
                </button>
            </div>
        </div>

        @if (workOrderFilterResult?.length > 0) {
            <div class="field-row mb-25">
                <div class="form-test">
                    <ul class="scrollable">
                        @for (workOrder of workOrderFilterResult; track workOrder['IDField']) {
                            <li>{{ workOrder['IDField'] }}
                                ({{ workOrder['DepartmentField'] }}, {{ workOrder['StatusField'] }})
                            </li>
                        }
                    </ul>
                </div>
            </div>
        }
    }
</ng-template>


<ng-template #filterVehicleEquipment let-form="form">
    <!-- vehicle equipment filter -->
    <div class="field-row" [formGroup]="form">
        <div class="field-row-title">
            Vehicle equipment import filter
        </div>
        <div class="field-row-element">
            <mat-form-field appearance="outline" color="accent" class="form-input">
                <input type="text" matInput
                       placeholder="Driver filter e.g. Department in ('Parks', 'Streets')"
                       [formControlName]="Controls.VEHICLE_EQUIPMENT_FILTER">
                @if (form.controls[Controls.VEHICLE_EQUIPMENT_FILTER].hasError('required')) {
                    <mat-error>
                        Field is required
                    </mat-error>
                }
            </mat-form-field>
            <button
                    mat-stroked-button
                    color="accent"
                    class="ml-10"
                    (click)="testVehicleEquipmentFilter()"
                    [disabled]="!(form.controls[Controls.VEHICLE_EQUIPMENT_FILTER].valid) || isTesting.equipment || isLoading">
                @if (isTesting.equipment) {
                    <mat-spinner diameter="20" color="accent" class="spinner"></mat-spinner>
                } @else {
                    <mat-icon>published_with_changes</mat-icon>
                }
                Test
            </button>
        </div>
    </div>

    @if (vehicleEquipmentFilterResult?.length > 0) {
        <div class="field-row mb-25">
            <div class="form-test">
                <ul class="scrollable">
                    @for (vehicle of vehicleEquipmentFilterResult; track vehicle.IDField) {
                        <li>{{ vehicle.IDField }}
                            , {{ vehicle.ManufacturerField }}, {{ vehicle.ModelField }}
                            , {{ vehicle.SerialVINNumberField }}, {{ vehicle.ClassificationField }}
                            , {{ vehicle.DepartmentField }}
                        </li>
                    }
                </ul>
            </div>
        </div>
    }
</ng-template>


<ng-template #filterVehicleGroups let-form="form">
    <!-- vehicle groups grouping -->
    <div class="field-row" [formGroup]="form">
        <div class="field-row-title">
            Vehicle grouping field
        </div>
        <div class="field-row-element">
            @if (equipmentClassFields && equipmentClassFields.length > 0) {
                <app-row-with-select [formControlName]="Controls.VEHICLE_GROUP_FIELD"
                                     [list]="equipmentClassFields"
                                     listId="name"
                                     listLabel="displayName"
                ></app-row-with-select>
            } @else {
                <input type="text" matInput
                       placeholder="Vehicle's group field e.g. 'DepartmentField'"
                       formControlName='vehicleGroupField'
                       name="vehicleGroupField">
            }
            @if (form.controls[Controls.VEHICLE_GROUP_FIELD].hasError('required')) {
                <mat-error>
                    Field is required
                </mat-error>
            }
        </div>
    </div>
</ng-template>


<ng-template #filterActivity let-form="form">
    <!-- driver filter-->
    <div class="field-row" [formGroup]="form">
        <div class="field-row-title">
            Activity import filter
        </div>
        <div class="field-row-element">
            <mat-form-field appearance="outline" color="accent" class="form-input">
                <input type="text" matInput
                       placeholder="Driver filter e.g. Department in ('Parks', 'Streets')"
                       [formControlName]="Controls.ACTIVITY_FILTER">
                @if (form.controls[Controls.ACTIVITY_FILTER].hasError('required')) {
                    <mat-error>
                        Field is required
                    </mat-error>
                }
            </mat-form-field>
            <button
                    mat-stroked-button
                    color="accent"
                    class="ml-10"
                    (click)="testActivityFilter()"
                    [disabled]="!(form.controls[Controls.ACTIVITY_FILTER].valid) || isTesting.activityFilter || isLoading">
                @if (isTesting.activityFilter) {
                    <mat-spinner diameter="20" color="accent" class="spinner"></mat-spinner>
                } @else {
                    <mat-icon>published_with_changes</mat-icon>
                }
                Test
            </button>
        </div>
    </div>

    @if (activityFilterResult?.length > 0) {
        <div class="field-row mb-25">
            <div class="form-test">
                <ul class="scrollable">
                    @for (item of activityFilterResult; track item['ActivityField']) {
                        <li>{{ item['ActivityField'] }}</li>
                    }
                </ul>
            </div>
        </div>
    }
</ng-template>


<ng-template #filterMaterial let-form="form">
    <div class="field-row" [formGroup]="form">
        <div class="field-row-title">
            Material import filter
        </div>
        <div class="field-row-element">
            <mat-form-field appearance="outline" color="accent" class="form-input">
                <input type="text" matInput
                       placeholder="Material Filter"
                       [formControlName]="Controls.MATERIAL_FILTER">
                @if (form.controls[Controls.MATERIAL_FILTER].hasError('required')) {
                    <mat-error>
                        Field is required
                    </mat-error>
                }
            </mat-form-field>
            <button
                    mat-stroked-button
                    color="accent"
                    class="ml-10"
                    (click)="testMaterialFilter()"
                    [disabled]="!(form.controls[Controls.MATERIAL_FILTER].valid) || isTesting.materialFilter || isLoading">
                @if (isTesting.materialFilter) {
                    <mat-spinner diameter="20" color="accent" class="spinner"></mat-spinner>
                } @else {
                    <mat-icon>published_with_changes</mat-icon>
                }
                Test
            </button>
        </div>
    </div>

    @if (materialFilterResult?.length > 0) {
        <div class="field-row mb-25">
            <div class="form-test">
                <ul class="scrollable">
                    @for (item of materialFilterResult; track item['IDField']) {
                        <li><span class="w-100px">{{ item['IDField'] }}</span> - {{ item['DescriptionField'] }}</li>
                    }
                </ul>
            </div>
        </div>
    }
</ng-template>


<ng-template #filterMaterialLocation let-form="form">
    @if (hasMaterialLocationFeature()) {
        <div class="field-row" [formGroup]="form">
            <div class="field-row-title">
                Material locations filter
            </div>
            <div class="field-row-element">
                <mat-form-field appearance="outline" color="accent" class="form-input">
                    <input type="text" matInput
                           placeholder="Material locations filter"
                           [formControlName]="Controls.MATERIAL_LOCATION_FILTER">
                    @if (form.controls[Controls.MATERIAL_LOCATION_FILTER].hasError('required')) {
                        <mat-error>
                            Field is required
                        </mat-error>
                    }
                </mat-form-field>
                <button
                        mat-stroked-button
                        color="accent"
                        class="ml-10"
                        (click)="testMaterialLocationFilter()"
                        [disabled]="!(form.controls[Controls.MATERIAL_LOCATION_FILTER].valid) || isTesting.materialLocationFilter || isLoading">
                    @if (isTesting.materialLocationFilter) {
                        <mat-spinner diameter="20" color="accent" class="spinner"></mat-spinner>
                    } @else {
                        <mat-icon>published_with_changes</mat-icon>
                    }
                    Test
                </button>
            </div>
        </div>

        @if (materialLocationFilterResult?.length > 0) {
            <div class="field-row mb-25">
                <div class="form-test">
                    <ul class="scrollable">
                        @for (item of materialLocationFilterResult; track item['IDField']) {
                            <li><span class="w-100px">{{ item['IDField'] }}</span></li>
                        }
                    </ul>
                </div>
            </div>
        }
    }
</ng-template>


<ng-template #filterMaterialMatching let-form="form">
    @if (hasMaterialMatchingFeature()) {
        <div class="field-row" [formGroup]="form">
            <div class="field-row-title">
                Material matching fields
            </div>
            <div class="field-row-element">
                <mat-form-field appearance="outline" color="accent" class="form-input">
                    <input type="text" matInput
                           placeholder="SnowRemovalOpsField,SprayingOpsField,HaulingOpsField,SweepingOpsField"
                           [formControlName]="Controls.MATERIAL_MATCHING_FIELDS">
                </mat-form-field>
            </div>
        </div>
    }
</ng-template>
