import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {
  CartegraphConfiguration,
  CartegraphImportTaskStatus,
  CgImportTask
} from '../../../../../shared/models/cartegraph.model';
import {CartegraphManagementService} from '../../../../../data/cartegraph/cartegraph-management.service';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSelectChange} from '@angular/material/select';
import {MatSort, SortDirection} from '@angular/material/sort';
import {
  CartegraphTasksImportConfigDialogComponent,
  CartegraphTasksImportDialogComponent
} from './cartegraph-tasks-import-dialogs.component';
import {MatDialog} from '@angular/material/dialog';
import {firstValueFrom} from 'rxjs';

@Component({
  selector: 'app-manage-cartegraph-tasks-import',
  templateUrl: './cartegraph-tasks-import.component.html',
  styleUrls: ['./cartegraph-tasks-import.component.scss',
    '../../../settings-fields.scss',
    '../../../settings-common.scss'],
})
export class CartegraphTasksImportComponent implements OnInit, AfterViewInit {

  configuration: CartegraphConfiguration;

  isLoading = false;
  uiError: string;

  CartegraphImportTaskStatus = CartegraphImportTaskStatus;

  displayedColumns: string[] = [
    'id', 'created', 'duration',];

  taskStatuses: { id: string, label: string }[] = [
    {id: CartegraphImportTaskStatus.IN_PROGRESS, label: 'In progress'},
    {id: CartegraphImportTaskStatus.SUCCESS, label: 'Success'},
    {id: CartegraphImportTaskStatus.FAIL, label: 'Failed'}
  ];

  taskStatus: { id: string, label: string }[] = this.taskStatuses; // default to success to see something
  tasks: MatTableDataSource<CgImportTask> = new MatTableDataSource<CgImportTask>([]);

  sortActive = 'created';
  sortDirection: SortDirection = 'desc';

  // used in *ngIf, requires to be loaded dynamically
  @ViewChild(MatSort, {static: false}) set sorter(sort: MatSort) {
    if (sort) {
      this.tasks.sort = sort;
    }
  }

  // used out of *ngIf can be injected
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  itemCount = 0;
  pageSize = 20;
  pageIndex = 0;

  constructor(
    private cartegraphManagementService: CartegraphManagementService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) {
    this.tasks.paginator = this.paginator;
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {
    this.getTasks();
  }

  showSnackBar(message: string) {
    this.snackBar.open(message, null, {duration: 2000});
  }

  getTasks(page: number = 0) {
    this.isLoading = true;
    this.uiError = null;
    const sortField = this.tasks?.sort?.active ? `${this.tasks?.sort?.active},${this.tasks?.sort?.direction}`
      : `${this.sortActive},${this.sortDirection}`;
    const statuses = this.taskStatus.map(i => i.id);

    Promise.all([
      this.cartegraphManagementService.getConfiguration(),
      firstValueFrom(this.cartegraphManagementService.getImportTaskList(page, this.pageSize, statuses, sortField))
    ]).then(response => {
      const [cfg, imports] = response;
      this.configuration = cfg.data ? cfg.data : new CartegraphConfiguration();
      this.tasks.data = imports.data.content;
      this.pageSize = imports.data.size;
      this.pageIndex = imports.data.number;
      this.itemCount = imports.data.totalElements;
    }).catch(error => {
      console.log(error);
      this.uiError = error;
    }).finally(() => {
      this.isLoading = false;
    });
  }

  onPage(pageEvent: PageEvent) {
    // decide to reset sorting here
    this.getTasks(pageEvent.pageIndex);
  }

  processingStatusChange(changeEvent: MatSelectChange) {
    this.taskStatus = changeEvent.value;
    this.getTasks();
  }

  refresh() {
    this.getTasks(this.pageIndex);
  }

  messageDetail(task: CgImportTask) {
    if (task.taskMessage?.trim().length > 0) {
      this.dialog.open(CartegraphTasksImportDialogComponent, {
        width: '800px',
        height: '800px',
        data: task,
      });
    }
  }

  importAllDataDialog() {
    const dialogRef = this.dialog.open(CartegraphTasksImportConfigDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (!!result && !!result.import) {
        this.importAllData(result.fetchAllData)
      }
    });

  }

  private importAllData(fetchAllData: boolean = false) {
    this.isLoading = true;
    this.cartegraphManagementService.importAllData(fetchAllData).then(response => {
      if (response.error) {
        this.uiError = response.error;
      } else {
        // on success, reload tasks, so there is the running one
        this.getTasks();
        this.showSnackBar('Import is in progress.');
      }
    }).catch(error => {
      console.log(error);
      this.uiError = error;
    }).finally(() => {
      this.isLoading = false;
    });
  }

}
