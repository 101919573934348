<div class="map-layer-edit-container ops-form">

    <mat-form-field appearance="outline" color="accent" class="wide no-hint">
        <mat-label>Name</mat-label>
        <input matInput
               autocomplete="off"
               [(ngModel)]="mapLayer.currentName"
               (ngModelChange)="changed()"
               #ngName="ngModel"
               required
               cdkFocusInitial>
        <mat-error *ngIf="ngName.invalid && ngName.errors.required">
            Name is required.
        </mat-error>
    </mat-form-field>

    <div *ngIf="mapLayer.type === MapLayerType.ROUTE">
        The layer is imported by Route Management.
    </div>

    <mat-form-field appearance="outline" color="accent" class="wide no-hint" *ngIf="mapLayer.type !== MapLayerType.ROUTE">
        <mat-label>Layer Type</mat-label>
        <mat-select [(ngModel)]="mapLayer.currentType" (ngModelChange)="changed()">
            <mat-option [value]="'RASTER'">raster</mat-option>
            <mat-option [value]="'VECTOR'">vector</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" color="accent" class="wide no-hint"
                    *ngIf="mapLayer.currentType === MapLayerType.RASTER">
        <mat-label>URL</mat-label>
        <input matInput
               autocomplete="off"
               [(ngModel)]="mapLayer.currentUrl"
               (ngModelChange)="changed()"
               #ngUrl="ngModel"
               required
        >
        <mat-error *ngIf="ngUrl.invalid && ngUrl.errors.required">
            URL is required.
        </mat-error>
        <mat-hint>
            Append '/tile/{{ '{z}/{y}/{x}' }}' to the end of your URL string for standard xyz pattern raster file loading
        </mat-hint>
    </mat-form-field>

    <mat-form-field appearance="outline" color="accent" class="wide no-height-mod json"
                    *ngIf="mapLayer.currentType === MapLayerType.VECTOR">
        <mat-label>JSON configuration</mat-label>
        <textarea matInput
                  autocomplete="off"
                  [(ngModel)]="mapLayer.currentConfiguration"
                  (ngModelChange)="changed()"
                  #ngConfig="ngModel"
                  class="json-text-area"
                  required
        >
                </textarea>
        <mat-error *ngIf="ngConfig.invalid && ngConfig.errors.required">
            JSON Configuration is required.
        </mat-error>
    </mat-form-field>

    @if (mapLayer.currentType === MapLayerType.RASTER) {
        <mat-form-field color="accent" class="wide no-hint">
          <mat-label>Opacity</mat-label>
          <input matInput
                 type="text"
                 [value]="((mapLayer.currentOpacity * 100) | number:'1.0-2') + '%' "
                 [disabled]="true"
          >
        </mat-form-field>

        <mat-slider min="0.0"
                    max="1.0"
                    step="0.01"
                    color="accent"
                    style="width: 95%"
        >
          <input matSliderThumb
                 [(ngModel)]="mapLayer.currentOpacity"
                 (change)="changed()"
          >
        </mat-slider>
    }

    <div class="layer-form" *ngIf="mapLayer.currentType === MapLayerType.RASTER">
        <mat-checkbox [(ngModel)]="mapLayer.useCredentials"
                      (change)="onCredentialsToggle()"
                      (ngModelChange)="changed()"
        >
            Use credentials for the server
        </mat-checkbox>
        <div class="credentials-wrapper" *ngIf="mapLayer.useCredentials">
            <mat-form-field appearance="outline" color="accent" class="credentials-input">
                <mat-label>Username</mat-label>
                <input matInput
                       [(ngModel)]="mapLayer.currentUsername"
                       (ngModelChange)="changed()"
                       autocomplete="off"/>
            </mat-form-field>
            <mat-form-field  appearance="outline" color="accent" class="credentials-input">
                <mat-label>Password</mat-label>
                <input matInput
                       [(ngModel)]="mapLayer.currentPassword"
                       (ngModelChange)="changed()"
                       autocomplete="off"
                       type="password"/>
            </mat-form-field>
            <button mat-icon-button
                    color="accent"
                    [matTooltip]="'Test connection'"
                    (click)="testCredentials()"
                    style="margin-bottom: 15px"
            >
                <mat-icon>refresh</mat-icon>
            </button>
        </div>
    </div>

</div>
