<h1 mat-dialog-title>
    Add Observation Type
</h1>

<mat-dialog-content class="dialog-content">
  <div>
    <mat-form-field appearance="outline" color="accent" class="mat-form-field">
      <mat-label>Title</mat-label>
      <input matInput
             autocomplete="off"
             [(ngModel)]="data.title"
             #ngTitle="ngModel"
             minlength="1"
             maxlength="255"
             required
             cdkfocusinitial>
    </mat-form-field>
  </div>

 <div>
  <mat-form-field appearance="outline" color="accent" class="mat-form-field">
    <mat-label>Alias</mat-label>
    <input matInput
           autocomplete="off"
           [(ngModel)]="data.abbreviation"
           #ngAbbreviation="ngModel"
           maxlength="10"
           >
  </mat-form-field>
 </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
	  Cancel
  </button>
  <button mat-flat-button
		  [mat-dialog-close]="data"
		  [disabled]="ngTitle.invalid || ngAbbreviation.invalid">
	  Add type
  </button>
</mat-dialog-actions>
