<mat-card-actions class="in-card-title">
	@if (icon) {
		<button mat-icon-button
				[disabled]="iconDisabled || editing"
				(click)="iconClicked.emit()">
			<mat-icon>{{icon}}</mat-icon>
		</button>
	}
	@if (editing) {
		<mat-form-field appearance="outline" subscriptSizing="dynamic" class="flex-grow-1">
			<input matInput
				   #name="matInput"
				   [autofocus]="true"
				   [value]="value"
				   [disabled]="valueChangeReported"
				   (keydown)="onKeyDown($event)"
				   (change)="editedValue = name.value">
		</mat-form-field>
	} @else {
		<h2 class="flex-grow-1"
			[ngClass]="{'cursor-pointer': !disabled}"
			[attr.matTooltip]="!disabled ? 'Click to edit' : null"
			(click)="startEdit()">
			{{ value }}
		</h2>
	}
	<div>
		@if (editing) {
			<button mat-icon-button
					matTooltip="Confirm changes"
					[disabled]="valueChangeReported"
					(click)="valueChangeConfirmed()">
				<mat-icon>check</mat-icon>
			</button>
			<button mat-icon-button
					matTooltip="Cancel changes"
					[disabled]="valueChangeReported"
					(click)="editing = false">
				<mat-icon>cancel</mat-icon>
			</button>
		} @else if (!disabled) {
			<button mat-icon-button
					matTooltip="Click to edit"
					(click)="editing = true">
				<mat-icon>edit</mat-icon>
			</button>
		}
	</div>
</mat-card-actions>
