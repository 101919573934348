<div class="narrow-panel-container">
    <div class="narrow-panel-header">
        <div class="content-title-1" *ngIf="!!path && path.length > 0">
            {{ path[path.length - 1] }}
        </div>
        <button
                class=""
                (click)="close()"
                mat-icon-button
                aria-label="close"
        >
            <mat-icon class="action-icon">close</mat-icon>
        </button>
    </div>

    <div class="narrow-panel-section">
        <ng-container *ngFor="let routeCategory of getRouteCategories()">
            <div class="content-title-2">
                {{ routeCategory }}
            </div>
        </ng-container>

        <div class="space-between">
          <div class="content-title-3" *ngIf="!!usedRouteConfiguration">
            {{ usedRouteConfiguration.name }}
          </div>

          <div class="content-title-3" *ngIf="routeLength !== null && routeLength !== undefined">
            {{ (routeLength / 1000 || 0) | distance:configuration.useMetricSystem:true }}
          </div>
        </div>
    </div>

    <div class="narrow-panel-divider">
    </div>

    <div class="narrow-panel-section">
        <div class="content-title-2">
            <button
                    mat-button
                    color="accent"
                    [matMenuTriggerFor]="timeFilterMenu"
            >
                <div class="time-filter-button">
                        <span *ngIf="timeFromHours === 24">
                            Last 24 hours
                        </span>
                    <span *ngIf="timeFromHours === 168">
                            Last Week
                        </span>
                    <mat-icon>
                        arrow_drop_down
                    </mat-icon>
                </div>
            </button>
            <mat-menu #timeFilterMenu="matMenu">
                <button
                        mat-menu-item
                        (click)="changeTimeFilter(24)"
                >
                    Last 24 hours
                </button>
                <button
                        mat-menu-item
                        (click)="changeTimeFilter(168)"
                >
                    Last Week
                </button>
            </mat-menu>
        </div>

        <div *ngIf="!routeMetrics" class="content-paragraph">
            <mat-spinner></mat-spinner>
        </div>

        <div class="content-paragraph" *ngIf="!!configuration && !!routeMetrics">
            <div class="content-row">
                <div class="info-metric">
                    <span>{{ assignmentCompleteCount }}</span>
                </div>
                <div class="info-value">
                    Completed assignment<span *ngIf="assignmentCompleteCount !== 1">s</span>
                </div>
            </div>

            <div class="content-row">
                <div class="info-metric">
                    <span>{{ assignmentInProgressCount }}</span>
                </div>
                <div class="info-value">
                    Active assignment<span *ngIf="assignmentInProgressCount !== 1">s</span>
                </div>
            </div>

            <div class="content-row">
                <div class="info-metric">
                    <span>{{ (routeMetrics.totalDistance / 1000 || 0) | distance:configuration.useMetricSystem:false }}</span>
                </div>
                <div class="info-value">
                    Total&nbsp;<span *ngIf="configuration.useMetricSystem">kilometers</span><span *ngIf="!configuration.useMetricSystem">miles</span> driven
                </div>
            </div>

            <div class="content-row">
                <div class="info-metric">
                    <span>{{ (routeMetrics.avgDistancePerAssignment / 1000 || 0) | distance:configuration.useMetricSystem:false }}</span>
                </div>
                <div class="info-value">
                    Avg <span *ngIf="configuration.useMetricSystem">kilometers</span><span *ngIf="!configuration.useMetricSystem">miles</span>
                    per assignment
                </div>
            </div>

            <div class="content-row">
                <div class="info-metric">
                    <span>{{ (routeMetrics.avgTimePerAssignment / 3600 || 0) | number:'1.0-2' }}</span>
                </div>
                <div class="info-value">
                    Avg time per assignment
                </div>
            </div>
        </div>
    </div>

    <div class="narrow-panel-divider">
    </div>

    <div class="narrow-panel-section">
        <div class="space-between">
          <div class="content-title-2">
            Assignment Queue
          </div>
          <button mat-icon-button
                  color="accent"
                  [routerLink]="[]"
                  [queryParams]="getRightPanelQueryParams(DrawerType.ROUTE)"
                  [matTooltip]="'Assign the route for a vehicle'"
          >
            <mat-icon>add_circle</mat-icon>
          </button>
        </div>

        <div class="content-paragraph">
            <app-route-assignment-queue
                [routeConfigId]="filterRouteConfigId"
                [routeId]="filterRouteIds[0]"
                [timeFromHoursFilter]="24"
            ></app-route-assignment-queue>
        </div>
    </div>

</div>
