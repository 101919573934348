<div class="filter-selector-wrapper" @filterSelectorFadeDown>

  <!-- main layers button -->
  <button
    (click)="toggleVisibility()"
    class="layers__button noselect mat-elevation-z2"
    [ngStyle]="baseMapTypeBackground(BaseMapType.WINTER)"
  >
    <mat-icon>layers</mat-icon>
    <span>Layers</span>
</button>


  <!-- layers list -->
  <div class="layers__box mat-elevation-z2" *ngIf="filterOpen">

    <div class="layers__buttons">

      <ng-container *ngFor="let baseMap of baseMaps">
        <div class="map__filter__wrapper">
          <div
                  [ngClass]="{ selected: baseMapLayerType === baseMap }"
                  class="map__filter__square noselect"
                  [ngStyle]="baseMapTypeBackground(baseMap)"
                  (click)="switchBaseMap(baseMap)"
          ></div>
          <div class="map__filter__text">
            {{ baseMap }}
          </div>
        </div>
      </ng-container>

    </div>

    <div class="layers__buttons" *ngIf="!!configuration && (hasFeatureFlag(FeatureFlagEnum.Weather) || hasFeatureFlag(FeatureFlagEnum.Traffic))">
      <div class="map__filter__wrapper" *ngIf="hasFeatureFlag(FeatureFlagEnum.Weather)">
        <div [ngClass]="{ selected: showRadar }"
             [ngStyle]="weatherLayerToggleBackground(WeatherLayerType.RADAR)"
             class="map__filter__square noselect"
             (click)="toggleRadar()"
        ></div>
        <div class="map__filter__text">
          Weather
        </div>
      </div>

      <div class="map__filter__wrapper" *ngIf="hasFeatureFlag(FeatureFlagEnum.Traffic)">
        <div [ngClass]="{ selected: showTraffic }"
             [ngStyle]="weatherLayerToggleBackground(WeatherLayerType.TRAFFIC)"
             class="map__filter__square noselect"
             (click)="toggleTrafficLayer()"
        ></div>
        <div class="map__filter__text">
          Traffic
        </div>
      </div>
    </div>

    <div class="layers__buttons no-border">
      <ng-container *ngFor="let customLayer of customMapLayers">
        <div class="map__filter__wrapper">
          <div class="map__filter__square noselect no__background"
               [ngClass]="{ selected: customLayer.checked }"
               (click)="toggleCustomMapLayer(customLayer, !customLayer.checked)"
          >
            <mat-icon>layers</mat-icon>
          </div>
          <div class="map__filter__text">
            {{ customLayer.name }}
          </div>
        </div>
      </ng-container>

      <div class="add__button__wrapper" *ngIf="!!isAdmin">
        <button mat-icon-button
                (click)="openConfiguration()"
        >
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>

  </div>
</div>
