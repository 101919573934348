import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-manage-cartegraph-header',
  templateUrl: './cartegraph-header.component.html',
  styleUrls: ['../../../settings-fields.scss',
    '../../../settings-common.scss'],
})
export class CartegraphHeaderComponent {

  @Input()
  page: string;

  PageType = PageType;

  constructor(private router: Router,) {
  }

  navigateToImports() {
    this.router.navigate([`settings/cartegraph-settings/imports`]).then();
  }

  navigateToSettings() {
    this.router.navigate([`settings/cartegraph-settings/settings`]).then();
  }

  navigateToSubmissions() {
    this.router.navigate([`settings/cartegraph-settings/submissions`]).then();
  }

}

export enum PageType {
  SETTINGS = 'SETTINGS',
  SUBMISSIONS = 'SUBMISSIONS',
  IMPORTS = 'IMPORTS',
}
