import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CartegraphConfiguration, ProcessingType} from 'src/app/shared/models/cartegraph.model';
import {CartegraphManagementService} from '../../../../../data/cartegraph/cartegraph-management.service';
import {JsonApiResponse} from '../../../../../shared/models/JsonApiResponse';
import {catchError, debounceTime, map, switchMap, takeWhile} from 'rxjs/operators';
import {firstValueFrom, of, Subscription, tap, timer} from 'rxjs';

@Component({
  selector: 'app-manage-cartegraph-account',
  templateUrl: './cartegraph-account.component.html',
  styleUrls: ['../../../settings-fields.scss',
    '../../../settings-common.scss'],
})
export class CartegraphAccountComponent implements OnInit, OnDestroy {

  isLoading = false;
  isSaving = false;
  isTesting = false;
  updateForm: UntypedFormGroup;
  connectionTested: JsonApiResponse<string>;

  // pre-fetching data
  isFetching = true;
  fetched = false;
  private fetchSubscriber: Subscription;

  uiError: string;


  @Input()
  configuration: CartegraphConfiguration;

  @Output()
  configurationChange = new EventEmitter<CartegraphConfiguration>();

  @Output()
  onAccountVerified: EventEmitter<void> = new EventEmitter();

  processingTypes: ProcessTypeSelection[] = [
    {
      type: ProcessingType.UNDEFINED,
      note: 'Undefined. Tasks won\'t goto OpenGov.'
    },
    {
      type: ProcessingType.ROSEMOUNTMN,
      note: 'Custom 1, e.g. Rosemount,MN'
    },
    {
      type: ProcessingType.WINDSORCO,
      note: 'Custom 2, e.g. Windsor,CO'
    }
  ];

  constructor(
    private cartegraphManagementService: CartegraphManagementService,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.initForm();
    this.initFormChanges();

    // check/start observing in case if there is or is not the fetch running
    this.startFetchCheck(true);

  }

  ngOnDestroy(): void {
    this.fetchSubscriber?.unsubscribe();
  }

  initForm() {
    this.updateForm = this.fb.group({
      baseUrl: this.configuration.baseUrl,
      username: this.configuration.username,
      password: '',
      processingType: this.configuration.settings.processing.processingType,
    }, {
      validators: []
    });
    this.updateForm.controls['password'].setValidators([
      Validators.required,
    ]);
  }

  initFormChanges() {
    this.updateForm.valueChanges.pipe(tap(() => {
      this.connectionTested = null;
    }), debounceTime(2000)).subscribe(() => {
        this.save();
      }
    );
  }

  canBeSaved(): boolean {
    const {username, baseUrl, processingType} = this.updateForm.value;
    return !!baseUrl && !!username && !!processingType && !this.isTesting && !this.isFetching;
  }

  save() {
    if (!this.canBeSaved()) {
      return;
    }

    const {baseUrl, username, password, processingType} = this.updateForm.value;
    const config = {
      baseUrl, username, processingType
    }
    // pass password only if set
    // allow to save without password to don't change it on server
    if (!!password) {
      config['password'] = password;
    }
    this.isSaving = true;
    this.cartegraphManagementService.updateConfiguration(config).then(response => {
      if (response.error) {
        this.uiError = response.error;
      } else {
        this.configurationChange.emit(response.data);
      }
    }).catch(error => {
      console.log(error);
      this.uiError = error;
    }).finally(() => {
      this.isSaving = false;
    });
  }

  verifyConnection() {
    this.isTesting = true;
    this.toggleControls(true);
    let {username, password, url} = this.updateForm.value;
    // use data from input or if missing from config itself
    url = url ?? this.configuration.baseUrl;
    username = username ?? this.configuration.username;
    password = password ?? this.configuration.password;
    this.cartegraphManagementService.testConnection(url, username, password).then(result => {
      this.connectionTested = result;
      // when result.data connection is OK, if result.error has anything connection failed
      if (!!this.connectionTested?.data) {
        this.fetchAllData();
      }
    }).catch(error => {
      this.connectionTested = error;
    }).finally(() => {
      this.isTesting = false;
      if (!!this.connectionTested?.error) {
        this.toggleControls(false);
      }
    });
  }

  private toggleControls(disabled: boolean = false) {
    if (disabled) {
      this.updateForm.disable();
    } else {
      this.updateForm.enable();
    }
  }

  // ------------------------------------------------
  // Fetch data from Cartegraph functions
  // ------------------------------------------------

  /**
   * starts timer to check if fetching is still running on server
   * and starts ti fetch the data
   * the timer checks each 5 secs if the fetching is done
   */
  fetchAllData() {
    // start observing fetch progress
    this.startFetchCheck(false);

    firstValueFrom(this.cartegraphManagementService.fetchAllData())
      .then(response => {
        if (response.error) {
          this.uiError = `Fetch data info: ${response.error}`;
        }
        this.toggleControls(false);
      }).catch(error => {
      console.log(error);
      this.uiError = error;

    });
  }

  private startFetchCheck(init = false) {
    const initialDelay = init ? 500 : 4000;
    this.isFetching = true;
    this.fetched = false;
    this.uiError = null;

    this.fetchSubscriber = timer(initialDelay, 5000).pipe(
      switchMap(() =>
        this.cartegraphManagementService.fetchIsRunning().pipe(
          map((value) => value.data),
          catchError(() => {
            return of(true);
          }))
      ),
      takeWhile(response => response === true, true)
    ).subscribe((response) => {
      // not running on server
      if (response === false) {
        this.isFetching = false;
        if (!init) {
          this.fetched = true;
        }
        this.onAccountVerified.emit();
      }
    });
  }

}


export class ProcessTypeSelection {
  type: ProcessingType;
  note: string;
}
