import {NgModule} from '@angular/core';
import {ManageCartegraphComponent} from './manage-cartegraph.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../../../../shared/material/material.module';
import {SharedModule} from '../../../../shared/shared.module';
import {MatTreeModule} from '@angular/material/tree';
import {CartegraphAccountComponent} from './cartegraph-account/cartegraph-account.component';
import {CartegraphFetchComponent} from './cartegraph-fetch/cartegraph-fetch.component';
import {
  CartegraphTasksComponent,
  CartegraphTasksDetailDialogComponent
} from './cartegraph-tasks/cartegraph-tasks.component';
import {CartegraphTasksImportComponent} from './cartegraph-tasks-import/cartegraph-tasks-import.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {CartegraphSettingsFeatures} from './cartegraph-features/cartegraph-settings-features.component';
import {
  CartegraphSettingsImportFilters
} from './cartegraph-import-filters/cartegraph-settings-import-filters.component';
import {CgTaskStatusUpdateDialog} from './cartegraph-tasks/cartegraph-tasks-status.copmponent';
import {MatDialogModule} from '@angular/material/dialog';
import {CartegraphSettingsObservations} from './cartegraph-observations/cartegraph-settings-observations.component';
import {RowWithToggleComponent} from '../row-with-toggle/row-with-toggle.component';
import {CardComponent} from '../../../../shared/components/card/card.component';
import {RouterModule} from '@angular/router';
import {CardContentDirective, CardContentRawDirective} from '../../../../shared/components/card/card-content.directive';
import {RowWithButtonComponent} from '../row-with-button/row-with-button.component';
import {ConfirmDeletionDialogComponent} from '../confirm-deletion-dialog/confirm-deletion-dialog.component';
import {UiBlockingLayerComponent} from '../ui-blocking-layer/ui-blocking-layer.component';
import {RowWithSelectComponent} from '../row-with-select/row-with-select.component';
import {ExpansionPanelComponent} from '../../../../shared/components/expansion-panel/expansion-panel.component';
import {
  CardEditableHeadingComponent
} from '../../../../shared/components/card-editable-heading/card-editable-heading.component';
import {CartegraphMainSetupComponent} from './cartegraph-main-setup/cartegraph-main-setup.component';
import {CartegraphManagementRoutingModule} from './cartegraph-management-routing.module';
import {MatBadge} from '@angular/material/badge';
import {
  CartegraphTasksImportConfigDialogComponent,
  CartegraphTasksImportDialogComponent
} from './cartegraph-tasks-import/cartegraph-tasks-import-dialogs.component';
import {CartegraphNotificationsComponent} from './cartegraph-notifications/cartegraph-settings-notifications.component';
import {
  CartegraphSettingsDefaultsObservation
} from './cartegraph-defaults/cartegraph-settings-defaults-observation.component';
import {CartegraphSettingsDefaultsShift} from './cartegraph-defaults/cartegraph-settings-defaults-shift.component';
import {CartegraphHeaderComponent} from './cartegraph-header/cartegraph-header.component';

@NgModule({
  declarations: [
    ManageCartegraphComponent,
    CartegraphHeaderComponent,
    CartegraphMainSetupComponent,
    CartegraphAccountComponent,
    CartegraphFetchComponent,
    CartegraphTasksComponent,
    CartegraphTasksImportComponent,
    CartegraphTasksDetailDialogComponent,
    CartegraphTasksImportDialogComponent,
    CartegraphTasksImportConfigDialogComponent,
    CartegraphSettingsFeatures,
    CartegraphSettingsImportFilters,
    CartegraphSettingsDefaultsObservation,
    CartegraphSettingsDefaultsShift,
    CartegraphSettingsObservations,
    CgTaskStatusUpdateDialog,
    CartegraphNotificationsComponent
  ],
  exports: [
    ManageCartegraphComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedModule,
    RouterModule,
    MatDialogModule,
    MatTreeModule,
    MatGridListModule,
    RowWithToggleComponent,
    CardComponent,
    CardContentDirective,
    ConfirmDeletionDialogComponent,
    UiBlockingLayerComponent,
    RowWithButtonComponent,
    RowWithSelectComponent,
    RowWithToggleComponent,
    CardContentRawDirective,
    ExpansionPanelComponent,
    CardEditableHeadingComponent,
    CartegraphManagementRoutingModule,
    MatBadge
  ]
})
export class CartegraphManagementModule {
}
