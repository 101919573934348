<div class="flex-column gap-32">
    @if (isLoading) {
        <app-card preCardTitle="Cartegraph management">
            <div *appCardContent class="settings-header-container">
                <mat-spinner mode="indeterminate" diameter="30" class="mx-a my-15"></mat-spinner>
            </div>
        </app-card>
    } @else {

        <app-manage-cartegraph-header page="IMPORTS"></app-manage-cartegraph-header>

        <app-card preCardTitle="Imports from Cartegraph">

            <div *appCardContent>

                <div *ngIf="!isLoading && uiError && uiError.length > 0" class="content mb-25">
                    <span class="ui-error">{{ uiError }}</span>
                </div>

                <div class="filter">
                    <mat-form-field appearance="outline" color="accent" subscriptSizing="dynamic">
                        <mat-label>Status</mat-label>
                        <mat-select multiple (selectionChange)="processingStatusChange($event)"
                                    [value]="taskStatus">
                            <mat-option *ngFor="let status of taskStatuses"
                                        [value]="status">{{ status.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="ml-25">
                        <button mat-stroked-button
                                color="accent"
                                (click)="importAllDataDialog()">Import
                            <mat-icon>cloud_download</mat-icon>
                        </button>
                        &nbsp;
                        <button mat-stroked-button
                                color="accent"
                                (click)="refresh()">Refresh
                            <mat-icon>refresh</mat-icon>
                        </button>
                    </div>
                </div>

                <div *ngIf="!isLoading && (!tasks || tasks.data?.length<=0)">There are no data available with
                    status: {{ taskStatus }}
                </div>


                <ng-container *ngTemplateOutlet="table"></ng-container>
                <ng-container *ngTemplateOutlet="paginator"></ng-container>

            </div>

        </app-card>

    }
</div>


<ng-template #paginator>
    <!-- ?? note: cannot be in the ngIf statement! -->
    <mat-paginator showFirstLastButtons
                   (page)="onPage($event)"
                   [pageSize]="pageSize"
                   [pageIndex]="pageIndex"
                   [length]="itemCount"
                   aria-label="Select page">
    </mat-paginator>

</ng-template>

<ng-template #table>
    <table mat-table *ngIf="!isLoading && tasks && tasks.data?.length>0"
           class="tasks-table mt-15"
           [dataSource]="tasks.data">
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> ...</th>
            <td mat-cell *matCellDef="let element">
                <ng-container *ngTemplateOutlet="status;context:{element:element}"></ng-container>
            </td>
        </ng-container>

        <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef> Created</th>
            <td mat-cell *matCellDef="let element"> {{ element.created | date:'medium' }}</td>
        </ng-container>

        <ng-container matColumnDef="duration">
            <th mat-header-cell *matHeaderCellDef> Duration</th>
            <td mat-cell *matCellDef="let element"> {{ '-' | duration2: element.created:element.finished }}</td>
        </ng-container>

        <ng-container matColumnDef="taskStatus">
            <th mat-header-cell *matHeaderCellDef> Status</th>
            <td mat-cell *matCellDef="let element"> {{ element.taskStatus }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

</ng-template>

<ng-template #status datatype="CgImportTask" let-element="element">
    <button mat-icon-button
            color="accent"
            (click)="messageDetail(element)">

        @switch (element.taskStatus) {
            @case (CartegraphImportTaskStatus.IN_PROGRESS) {
                <mat-icon class="waiting spin">hourglass_bottom</mat-icon>
            }
            @case (CartegraphImportTaskStatus.FAIL) {
                <mat-icon class="fail">error</mat-icon>
            }
            @case (CartegraphImportTaskStatus.SUCCESS) {
                <mat-icon class="success">check_circle</mat-icon>
            }
        }
    </button>
</ng-template>



