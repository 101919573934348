import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CartegraphSyncTask} from '../../../../../shared/models/cartegraph.model';

@Component({
  template: `
      <mat-card class="m-25 p-25" appearance="outlined">
          <mat-card-header>
              <mat-card-title>Update task</mat-card-title>
              <mat-card-subtitle><small>id:{{ this.data.id }}, shift:{{ this.data.shiftId }},
                  type:{{ this.data.taskType }}</small></mat-card-subtitle>
          </mat-card-header>
          <mat-card-content class="mt-25">
              <div>
                  <mat-form-field appearance="outline" color="accent">
                      <mat-label>Status</mat-label>
                      <mat-select [(ngModel)]="this.data.processStatus">
                          <mat-option *ngFor="let status of this.statuses"
                                      [value]="status">{{ status }}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
              <div>
                  <mat-form-field appearance="outline" color="accent">
                      <mat-label>Note</mat-label>
                      <input matInput [(ngModel)]="this.data.processMessage">
                  </mat-form-field>
              </div>
              <div>Note: use if you know what it does</div>
          </mat-card-content>
          <mat-card-footer>
              <mat-card-actions align="end">
                  <button mat-button color="accent" (click)="this.dialogRef.close(this.data);">Update</button>
                  <button mat-button color="accent" (click)="this.dialogRef.close();">Close</button>
              </mat-card-actions>
          </mat-card-footer>
      </mat-card>
  `,
})
export class CgTaskStatusUpdateDialog {
  constructor(
    public dialogRef: MatDialogRef<CgTaskStatusUpdateDialog>,
    @Inject(MAT_DIALOG_DATA) public data: CartegraphSyncTask,
  ) {
    this.data.processMessage = undefined;
  }

  statuses = ['SUCCESS', 'FAIL', 'NONE'];

}
